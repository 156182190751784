/*
Template Name: Porus - Bakery Store HTML 5 Template
Theme URI: https://templates.g5plus.net/porus/
Author: G5 Theme
Author URI: https://themeforest.net/user/g5theme
Description: Bakery Store HTML 5 Template With Bootstrap 4 Framework
Version: 1.0.5
Tags: bakery, bakery store, store, food, bootstrap 4, html template
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Initial
# Core Css
# Header
# Footer
# Page title
# Widgets
# Elements
# Pages
# Helper
# Vendors
# Dark
# Responsive
--------------------------------------------------------------*/
:root {
  --blue: #01b3ed;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #ff0000;
  --orange: #ffba00;
  --yellow: #ffba00;
  --green: #74b100;
  --teal: #67981a;
  --cyan: #0583c5;
  --white: #fff;
  --gray: #999999;
  --gray-dark: #343a40;

  --primary: #ff5a5f;
  --secondary: #666666;
  --success: #67981a;
  --info: #3f93f3;
  --warning: #ffba00;
  --danger: #ff0000;
  --light: #f0f0f0;
  --dark: #000;
  --white: #fff;
  --gray: #999999;
  --darker-light: #cccccc;
  --dark-red: #cc0000;
  --lighter-dark: #262626;
  --green: #74b100;
  --gray-01: #e8edef;
  --gray-02: #f5f5f5;
  --gray-03: #eeeeee;
  --gray-04: #eef1f2;
  --gray-05: #ecf0f1;
  --gray-06: #f0f2f3;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Work Sans", sans-serif;
  --font-family-monospace: "Work Sans", sans-serif;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.leftChap{
  width: 100%;
  margin: 0 auto;
}
.rightChap{
  width: 100%;
  margin: 0 auto;
}

.chapterDiv {
  display: flex;
  width: 80%;
  margin: 0 auto;
  gap: 15px;
  margin-bottom: 40px;
}

.logoImg{
  height: 100%;
  object-fit: contain;
  width: 30%;
}

.navLink{
  color: #000;
  font-size: 20px;
  font-weight: 550;
  text-transform: none;
  text-decoration: none;
}
.navLink:hover, .navChange:hover{
  color: #153e80;
  text-decoration: underline;
  text-underline-position: under;
}
.navChange{
  color: #153e80;
  font-size: 20px;
  font-weight: 550;
  text-transform: none;
  text-decoration: underline;
  text-underline-position: under;
}

.navSubLink{
  color: #a3a3a3;
  font-size: 20px;
  font-weight: 550;
  text-transform: none;
  text-decoration: none;
}
.navSubLink:hover{
  color: #153e80;
  /* text-decoration: none; */
}

.moreDt{
  transition: transform 0.5s ease;
  transform: rotate(0deg);
}
.moreDetails {
  width: 190px;
  position: absolute;
  padding: 0 40px;
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: left;
  background-color: #fff;
  visibility: hidden;
  top: 100%; left: -60%;
  border-radius: 10px;
}
.main:hover .moreDt{
  transform: rotate(180deg);
}
.main:hover .moreDetails{
  visibility: visible;
  z-index: 10;
}

.beA {
  display: flex;
  width: 80%;
  margin: 0px auto;
  align-items: center;
}

.blogCss {
  width: 100%; border: 1px solid lightgray; box-shadow: none; 
  padding: 10px 10px; border-radius: 10px; 
}

.services {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  /* margin: 0 auto; */
}

.mainFooter{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.leftFooter{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.eventTimeLoca{
  width: 80%;
  display: flex;
  gap: 20px;
}

.eventData{
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}
.eventDataLeft {
  width: 100%;
  flex: 60%;
}
.eventDataRight {
  width: 100%;
  flex: 40%;
}

.rightChapHead{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
}

.rightFooter{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.eventDiv{
  width: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  margin: 8px auto 0px;
}

.serviceImg {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
  padding: 0 6px;
}

.eventClass{
  width: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  padding: 7px;
}

.eventAtHome{
  width: 100%;
  margin-top: 15px;
}

.arwRight {
  right: 8.3%;
}
.arwLeft {
  left: 8.3%;
}

.detailImgContainer{
  height: 850px;
  width: 100%;
  margin: 0 auto;
}

.eventCard {
  width: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  margin-top: 8px;
  padding: 3px;
}

.slick-slider .serImg {
  width: 100%; height: 100%;
  object-fit: contain;
}
.allImg {
  width: 100%; height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.eventImg {
  width: 100%;
  height: 210px;
  display: flex;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.evImg {
  width: 100%; height: 210px;
  object-fit: contain;
  /* max-width: 540px; max-height: 220px; */
  margin: 0 auto;
}

.responsive {
  width: 80%;
  margin: 0 auto;
}

.footerLink{
  color: #707070;
  text-decoration: none;
  cursor: pointer;
}
.footerLink:hover{
  color: #636363;
  text-decoration: none;
}

.activateBtn{
  display: flex;
  justify-content: space-between;
}

.blogContainer{
  width: 100%;
  display: grid;
  grid-template-columns: 32.5% 32.5% 32.5%;
  gap: 15px;
  margin-top: 15px;
}

.BannerData{
  position: absolute;
  top: 33%;
  left: 38%;
  text-align: center;
}

.eventDetail{
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  gap: 15px;
}
.eventDetailImg{
  height: 380px;
  width: 100%;
  margin: 0 auto;
}
.hideCloud{
  width: 380px;
}

.hideBanner{
  display: block;
}

.showBanner{
  display: none;
}

.welcome{
  width: 80%;
  margin: 60px auto 0;
}
.journeyBoxes{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
.introducingImg{
  height: 310px; width:50%;
}
.homeBanner{
    margin: 80px auto;
}
.cloudSubT{
  width: 35%; margin: 0 auto; text-align: center;
}
.cancelBtn{
  position: absolute; top: 2%; right: 3%; opacity: 50%;
  cursor: pointer; width: 30px; height: 30px;
  border-radius: 50%; padding: 3px 8px; display: flex;
  align-items: center; justify-content: center; 
  border: 1px solid #e6e6e6
}
.cancelBtn:hover{
  background-color: #e6e6e6; opacity: 100%;
}

.memBtn{
  background-color: #153e80;
  color: #fff;
  font-weight:700;
  font-size: 12px;
  border: 1px solid #153e80;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 7px 6px;
  margin-top: -7px;
  cursor: pointer;
}
.otpDiv{
  background-color: #153e80;
  color: #fff;
  font-weight:700;
  font-size: 12px;
  border: 1px solid #153e80;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 7px 24px;
  margin-top: -7px;
  cursor: pointer;
}
.memBtnVer{
  background-color: #153e80;
  color: #fff;
  font-weight:700;
  font-size: 12px;
  border: 1px solid #153e80;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 9px 10px;
  /* margin-top: -9px; */
  cursor: pointer;
}

.memBtn:hover{
  color: #fff;
  background-color: #0f2b5a;
}
.memBtnVer:hover{
  color: #fff;
  background-color: #0f2b5a;
}

.emailText{
  text-transform: lowercase; cursor: pointer; color: #707070; text-decoration: none;
}
.emailText:hover{
  color: #707070; text-decoration: underline;
}


@media (max-width: 1930px) {
  .serviceImg{
    height: 245px;
  }
  .eventDetailImg{
    height: 380px;
  }
  .showBanner{
    display: none;
  }
}
@media (max-width: 1630px) {
  .serviceImg{
    height: 193px;
  }
}
@media (max-width: 1300px) {
  .responsive{
    width: 80%;
  }
  .beA{
    width: 80%;
  }
  .chapterDiv {
    width: 80%;
  }
  .eventDetailImg{
    height: 320px;
  }
}
@media (max-width: 1025px) {
  .responsive{
    width: 95%;
  }
  .navHide{
    display: none;
  }
  .beA{
    width: 95%;
  }
  .welcome{
    width: 100%;
  }
  .introducingImg{
    height: 290px;
  }
  .BannerData{
    left: 30%;
  }
  .chapterDiv{
    width: 97%;
    display: block;
  }
  .serviceImg{
    height: 160px;
  }
  .allImg{
    object-fit: contain;
  }
  .detailImgContainer{
    height: 380px;
    width: 100%;
  }
  .eventTimeLoca{
    width: 100%
  }
  .cloudSubT{
    width: 50%; margin: 0 auto; text-align: center;
  }
  .leftChap{
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .rightChap{
    width: 80%;
    margin: 0 auto 30px;
  }

  .arwRight{
    right: 0%;
  }
  .arwLeft{
    left: 0%;
  }
}
@media (max-width: 770px){
  .responsive{
    width: 90%;
  }
  .services{
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin: 0 auto;
  }
  .blogCss{
    width: 96%;
    margin: 0 auto;
  }
  .homeBanner{
    margin: 120px auto 0px;
  }
  .beA{
    width: 90%;
  }
  .journeyBoxes{
    display: block;
    width: 45%;
    margin: 0 auto;
  }
  .journeyCol{
    padding: 12px 0;
  }
  .introducingImg{
    width: 60%;
    height: 290px;
    margin: 0 auto;
  }
  .BannerData{
    left: 19%;
  }
  .chapterDiv{
    width: 90%;
    display: block;
  }
  .blogContainer{
    grid-template-columns: 50% 50%;
    gap: 15px;
  }
  .serviceImg{
    height: 170px;
  }
  .allImg{
    object-fit: cover;
  }
  .detailImgContainer{
    height: 310px;
  }
  .eventData{
    display: block;
  }
  .eventDataRight{
    width: 65%;
    margin: 30px auto;
  }
  .eventTimeLoca{
    width: 100%;
    display: flex;
  }
  .eventDetail {
    display: block;
  }
  .eventClass{
    width: 96%;
    margin: 0 auto;
  }
  .eventDiv{
    width: 85%;
  }

  .hideBanner{
    display: none;
  }
  .showBanner{
    display: block;
  }
  .cloudSubT{
    width: 60%; margin: 0 auto; text-align: center;
  }
  .leftChap{
    width: 96%;
    margin: 0 auto 30px;
  }
  .rightChap{
    width: 96%;
    margin: 0 auto 30px;
  }
  .hideCloud{
    width: 290px;
  }

  .mainFooter {
    grid-template-columns: repeat(1, 1fr);
  }
  .leftFooter{
    align-items: center;
  }

  .arwRight{
    right: 2.5%;
  }
  .arwLeft{
    left: 2.5%;
  }
}
@media (max-width: 430px){
  .responsive{
    width: 93%;
    display: block;
  }
  .services{
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin: 0 auto;
  }
  .blogCss{
    width: 100%;
    margin: 0 0 20px;
  }
  .beA{
    width: 93%;
    display: block;
  }
  .journeyBoxes{
    display: block;
    width: 75%;
    margin: 0 auto;
  }
  .introducingImg{
    width: 100%;
    height: 250px;
  }
  .BannerData{
    left:1%;
  }
  .logoImg{
    width: 50%;
  }
  .blogContainer{
    display: block;
  }
  .serviceImg{
    height: 182px;
  }
  .detailImgContainer{
    height: 210px;
  }
  .activateBtn{
    display: block;
  }
  .eventDiv{
    width: 100%;
  }
  .eventData{
    display: block;
  }
  .eventDataRight{
    width: 100%;
  }
  .eventTimeLoca{
    width: 100%;
    display: block;
  }
  .eventDetailImg{
    height: 250px;
  }
  .rightChapHead{
    display: block;
  }
  .hideCloud{
    width: 170px;
  }
  .arwRight{
    right: 0%;
  }
  .arwLeft{
    left: 0%;
  }
}

.headLink{
  color: #fff; text-decoration: none; 
  cursor: pointer;
}
.headLink:hover{
  color: #fff; text-decoration: underline;
}
.detailsLink{
  color: #000; text-decoration: none;
  font-size: 18px; letter-spacing: 0.6px;
  cursor: pointer; opacity: 70%;
}
.detailsLink:hover{
  text-decoration: underline;
  text-underline-position: under;
  color: #000; opacity: 70%;
}

.slick-slider .slick-dots li {
  width: 14px;
  height: 14px;
  margin: 0 0px;
}

.w-full{ width: 100%;} .w-8{width: 80%;} .w-9{width:90%} .w-6{width:60%}
.max-h-380{max-height: 380px;}
.h-full{height: 100%;} .h-half{height: 50%} 
.ha{ height: auto;} .h-8{height: 80%;}
.object-contain{ object-fit: contain;}
.object-cover{ object-fit: cover;}
.opacity-7{opacity: 70%;} .opacity-5{opacity: 50%;}

.ma-0{ margin: 0px auto;} .m2xy{margin: 50px auto 100px}
.ma-3{ margin: 3px auto;}
.ma-5{ margin: 50px auto;} .ma-6{ margin: 60px auto;} 
.ma-9{ margin: 90px auto;} .ma-8{ margin: 80px auto;}
.ma-38{ margin: 30px auto 80px;} .ma-7{ margin: 70px auto;}
.mxy-02{margin: 20px 0;} .mxy-10{margin: 0 10px}
.ma2y0{margin: 20px auto 0;} .ma3y6{margin: 30px auto 60px}
.ma-289{ margin: 28px auto 90px;}
.mb--3{ margin-bottom: -30px;} .mb--1{ margin-bottom: -10px;}
.mb--25{ margin-bottom: -25px;}
.mb-110{ margin-bottom: 110px; } .mb-100{ margin-bottom: 100px; }
.mb-5{ margin-bottom: 50px; } .mb-1{ margin-bottom: 10px; }
.mb-08{ margin-bottom: 8px; }
.mt-100{ margin-top: 100px;} .mt-5{ margin-top: 50px;} 
.mt-6{ margin-top: 60px;} .mt-8{ margin-top: 80px;}
.mt-2{ margin-top: 20px;} .mt-24{ margin-top: 24px;}
.mt-15{ margin-top: 15px;} .mt-01{ margin-top: 10px;} 
.mt-12{ margin-top: 12px;}
.mt--05{margin-top: -5px;} .mt--1{margin-top: -10px;}
.mt--6{margin-top: -60px}
.mt-07{margin-top: 7px} .mt-08{margin-top: 8px}
.my-10{margin: 100px 0;} .my-25{margin:25px 0;}
.mleft-1{margin-left: 10px}

.text-center{text-align: center;}
.text-larger{font-size: larger;}
.text-medium{font-size: medium;} .semibold{font-weight: 600;}
.text-36{font-size: 36px} .text-34{font-size: 34px} 
.text-32{font-size: 32px} .text-20{font-size: 20px} 
.text-25{font-size: 25px} .text-16{font-size: 16px}
.text-19{font-size: 19px}  
.text-17{font-size: 17px} .text-12{font-size: 12px}
.decoration-none{ text-decoration: none;}

.pointer{ cursor: pointer;}
.cursor-not{cursor: not-allowed}
.radius-full{ border-radius: 50%;} .radius-12{border-radius: 12px;}
.radius-08{border-radius: 8px;} .radius-1{border-radius: 10px;}
.border{ border: 1px solid #d1d1d1}
.border3{ border: 3px solid #d1d1d1}

.brdr{ 
  border: 1px solid #d1d1d1; 
  box-shadow: 3px 3px 5px rgba(0 0 0 0.2);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}
.brdr4{ 
  border: 4px solid #d1d1d1; 
  box-shadow: 3px 3px 5px 5px rgba(0 0 0 0.2);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.bg-153e80{background-color: #153e80;} .bg-f1f5f7{background: #f1f5f7;}
.bg-fff{background: #fff} .bg-000{background: #000} 
.text-fff{color: #fff} .text-153e80{color: #153e80} 
.text-000{color: #000}

.relative{position: relative;}
.absolute{position: absolute;}
.z-0{z-index: 0;} .z-10{z-index: 10;}
.t-0{top: 0%;} .t-5{top: 50%;} .t-53{top: 53%} .t-12{ top: 12%;}
.b-0{bottom: 0%;}
.r-0{right: 0%;} .r--2{right: -2.1%}
.l-0{left: 0%;} .l--2{left: -2.1%}

.grid{display: grid;} .flex{display: flex;}
.flex-col{flex-direction: column;}
.block{display: block;} .hidden{display: none;}
.grid-3{ display: grid; grid-template-columns: repeat(3, 1fr);}
.items-center{ align-items: center;} 
.gap-1{gap: 10px;} .gap-13{gap:13px} .gap-03{gap: 3px}
.justify-center{ justify-content: center;} .justify-between{justify-content: space-between;}

.rotate180{ rotate: 180deg;}


figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #000 !important;
  /* color: #666666; */
  text-align: left;
  background-color: #fff;
  letter-spacing: -0.015em;
  padding-right: 0px !important
}

.btnAll {
  background-color: #153e80;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 19px;
  border-radius: 7px;
  padding: 5px 20px;
}
.btnAll:hover {
  background-color: #0f2b5a;
  color: #fff;
}
.disableBtn {
  background-color: #153e80;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 19px;
  border-radius: 7px;
  padding: 5px 20px;
  cursor: not-allowed;
}
.disableBtn:hover{color: #fff}

.title:hover {
  color: #153e80,
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #000;
  /* text-decoration: underline; */
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: "Work Sans", sans-serif;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  height: auto;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #999999;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #000;
}

h1,
.h1 {
  font-size: 3rem;
}

h2,
.h2 {
  font-size: 2.25rem;
}

h3,
.h3 {
  font-size: 1.875rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1.125rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #999999;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #999999;
}

code {
  font-size: 87.5%;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 2px;
  -webkit-box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  -webkit-box-shadow: none;
  box-shadow: none;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.css-19lg7uq-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #0d47a3 !important;
  color:white;
  border: 0;
}
.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #666666;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #666666;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffd1d2;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #ffa9ac;
}

.table-hover .table-primary:hover {
  background-color: #ffb8b9;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ffb8b9;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d4d4d4;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #afafaf;
}

.table-hover .table-secondary:hover {
  background-color: #c7c7c7;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c7c7c7;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d4e2bf;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b0c988;
}

.table-hover .table-success:hover {
  background-color: #c8daad;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #c8daad;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c9e1fc;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #9bc7f9;
}

.table-hover .table-info:hover {
  background-color: #b1d4fb;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b1d4fb;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffecb8;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdb7a;
}

.table-hover .table-warning:hover {
  background-color: #ffe59f;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe59f;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffb8b8;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ff7a7a;
}

.table-hover .table-danger:hover {
  background-color: #ff9f9f;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ff9f9f;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfbfb;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f7f7f7;
}

.table-hover .table-light:hover {
  background-color: #eeeeee;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #eeeeee;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #b8b8b8;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-dark:hover {
  background-color: #ababab;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #ababab;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}
.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #e2e2e2;
}

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #cacaca;
}

.table-hover .table-gray:hover {
  background-color: #d5d5d5;
}
.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
  background-color: #d5d5d5;
}

.table-darker-light,
.table-darker-light > th,
.table-darker-light > td {
  background-color: #f1f1f1;
}

.table-darker-light th,
.table-darker-light td,
.table-darker-light thead th,
.table-darker-light tbody + tbody {
  border-color: #e4e4e4;
}

.table-hover .table-darker-light:hover {
  background-color: #e4e4e4;
}
.table-hover .table-darker-light:hover > td,
.table-hover .table-darker-light:hover > th {
  background-color: #e4e4e4;
}

.table-dark-red,
.table-dark-red > th,
.table-dark-red > td {
  background-color: #f1b8b8;
}

.table-dark-red th,
.table-dark-red td,
.table-dark-red thead th,
.table-dark-red tbody + tbody {
  border-color: #e47a7a;
}

.table-hover .table-dark-red:hover {
  background-color: #eda3a3;
}
.table-hover .table-dark-red:hover > td,
.table-hover .table-dark-red:hover > th {
  background-color: #eda3a3;
}

.table-lighter-dark,
.table-lighter-dark > th,
.table-lighter-dark > td {
  background-color: #c2c2c2;
}

.table-lighter-dark th,
.table-lighter-dark td,
.table-lighter-dark thead th,
.table-lighter-dark tbody + tbody {
  border-color: #8e8e8e;
}

.table-hover .table-lighter-dark:hover {
  background-color: #b5b5b5;
}
.table-hover .table-lighter-dark:hover > td,
.table-hover .table-lighter-dark:hover > th {
  background-color: #b5b5b5;
}

.table-green,
.table-green > th,
.table-green > td {
  background-color: #d8e9b8;
}

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #b7d67a;
}

.table-hover .table-green:hover {
  background-color: #cde3a5;
}
.table-hover .table-green:hover > td,
.table-hover .table-green:hover > th {
  background-color: #cde3a5;
}

.table-gray-01,
.table-gray-01 > th,
.table-gray-01 > td {
  background-color: #f9fafb;
}

.table-gray-01 th,
.table-gray-01 td,
.table-gray-01 thead th,
.table-gray-01 tbody + tbody {
  border-color: #f3f6f7;
}

.table-hover .table-gray-01:hover {
  background-color: #eaedf1;
}
.table-hover .table-gray-01:hover > td,
.table-hover .table-gray-01:hover > th {
  background-color: #eaedf1;
}

.table-gray-02,
.table-gray-02 > th,
.table-gray-02 > td {
  background-color: #fcfcfc;
}

.table-gray-02 th,
.table-gray-02 td,
.table-gray-02 thead th,
.table-gray-02 tbody + tbody {
  border-color: #fafafa;
}

.table-hover .table-gray-02:hover {
  background-color: #efefef;
}
.table-hover .table-gray-02:hover > td,
.table-hover .table-gray-02:hover > th {
  background-color: #efefef;
}

.table-gray-03,
.table-gray-03 > th,
.table-gray-03 > td {
  background-color: #fafafa;
}

.table-gray-03 th,
.table-gray-03 td,
.table-gray-03 thead th,
.table-gray-03 tbody + tbody {
  border-color: #f6f6f6;
}

.table-hover .table-gray-03:hover {
  background-color: #ededed;
}
.table-hover .table-gray-03:hover > td,
.table-hover .table-gray-03:hover > th {
  background-color: #ededed;
}

.table-gray-04,
.table-gray-04 > th,
.table-gray-04 > td {
  background-color: #fafbfb;
}

.table-gray-04 th,
.table-gray-04 td,
.table-gray-04 thead th,
.table-gray-04 tbody + tbody {
  border-color: #f6f8f8;
}

.table-hover .table-gray-04:hover {
  background-color: #ecf0f0;
}
.table-hover .table-gray-04:hover > td,
.table-hover .table-gray-04:hover > th {
  background-color: #ecf0f0;
}

.table-gray-05,
.table-gray-05 > th,
.table-gray-05 > td {
  background-color: #fafbfb;
}

.table-gray-05 th,
.table-gray-05 td,
.table-gray-05 thead th,
.table-gray-05 tbody + tbody {
  border-color: #f5f7f8;
}

.table-hover .table-gray-05:hover {
  background-color: #ecf0f0;
}
.table-hover .table-gray-05:hover > td,
.table-hover .table-gray-05:hover > th {
  background-color: #ecf0f0;
}

.table-gray-06,
.table-gray-06 > th,
.table-gray-06 > td {
  background-color: #fbfbfc;
}

.table-gray-06 th,
.table-gray-06 td,
.table-gray-06 thead th,
.table-gray-06 tbody + tbody {
  border-color: #f7f8f9;
}

.table-hover .table-gray-06:hover {
  background-color: #ececf1;
}
.table-hover .table-gray-06:hover > td,
.table-hover .table-gray-06:hover > th {
  background-color: #ececf1;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #000;
  background-color: #fff;
  border-color: #dee2e6;
  outline: 0;
  -webkit-box-shadow: 0, none;
  box-shadow: 0, none;
}
.form-control::-webkit-input-placeholder {
  color: #999999;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #999999;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #999999;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #999999;
  opacity: 1;
}
.form-control::placeholder {
  color: #999999;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.7;
}

.col-form-label-lg {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  font-size: 1.125rem;
  line-height: 2;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.7;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.7;
  color: #666666;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.7em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.7;
  border-radius: 2px;
}

.form-control-lg {
  height: calc(1.7em + 1.5rem + 2px);
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  line-height: 2;
  border-radius: 3px;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #999999;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #67981a;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.7;
  color: #fff;
  background-color: rgba(103, 152, 26, 0.9);
  border-radius: 3px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #67981a;
  padding-right: calc(1.7em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2367981a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.1875rem) center;
  background-size: calc(0.85em + 0.375rem) calc(0.85em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #67981a;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.7em + 0.75rem);
  background-position: top calc(0.425em + 0.1875rem) right
    calc(0.425em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #67981a;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2367981a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.85em + 0.375rem)
      calc(0.85em + 0.375rem);
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #67981a;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #67981a;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #67981a;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #67981a;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #85c421;
  background: #85c421 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#97cc43),
      to(#85c421)
    ) repeat-x;
  background: #85c421 linear-gradient(180deg, #97cc43, #85c421) repeat-x;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #67981a;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #67981a;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #67981a;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff0000;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.7;
  color: #fff;
  background-color: rgba(255, 0, 0, 0.9);
  border-radius: 3px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #ff0000;
  padding-right: calc(1.7em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff0000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff0000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.1875rem) center;
  background-size: calc(0.85em + 0.375rem) calc(0.85em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #ff0000;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.7em + 0.75rem);
  background-position: top calc(0.425em + 0.1875rem) right
    calc(0.425em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ff0000;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff0000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff0000' stroke='none'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.85em + 0.375rem)
      calc(0.85em + 0.375rem);
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #ff0000;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #ff0000;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff0000;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ff0000;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff3333;
  background: #ff3333 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff5252),
      to(#ff3333)
    ) repeat-x;
  background: #ff3333 linear-gradient(180deg, #ff5252, #ff3333) repeat-x;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #ff0000;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff0000;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ff0000;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 600;
  color: #666666;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.7;
  border-radius: 3px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.btn:hover {
  color: #666666;
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background: #ff5a5f -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff7377),
      to(#ff5a5f)
    ) repeat-x;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
  border-color: #ff5a5f;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-primary:hover {
  color: #fff;
  background: #ff343a -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff5257),
      to(#ff343a)
    ) repeat-x;
  background: #ff343a linear-gradient(180deg, #ff5257, #ff343a) repeat-x;
  border-color: #ff272e;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background: #ff343a -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff5257),
      to(#ff343a)
    ) repeat-x;
  background: #ff343a linear-gradient(180deg, #ff5257, #ff343a) repeat-x;
  border-color: #ff272e;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(255, 115, 119, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(255, 115, 119, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ff5a5f;
  border-color: #ff5a5f;
  background-image: none;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff272e;
  background-image: none;
  border-color: #ff1a21;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 115, 119, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 115, 119, 0.5);
}

.btn-secondary {
  color: #fff;
  background: #666666 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#7d7d7d),
      to(#666666)
    ) repeat-x;
  background: #666666 linear-gradient(180deg, #7d7d7d, #666666) repeat-x;
  border-color: #666666;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-secondary:hover {
  color: #fff;
  background: #535353 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#6d6d6d),
      to(#535353)
    ) repeat-x;
  background: #535353 linear-gradient(180deg, #6d6d6d, #535353) repeat-x;
  border-color: #4d4d4d;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background: #535353 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#6d6d6d),
      to(#535353)
    ) repeat-x;
  background: #535353 linear-gradient(180deg, #6d6d6d, #535353) repeat-x;
  border-color: #4d4d4d;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(125, 125, 125, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(125, 125, 125, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
  background-image: none;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4d4d4d;
  background-image: none;
  border-color: #464646;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(125, 125, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(125, 125, 125, 0.5);
}

.btn-success {
  color: #fff;
  background: #67981a -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#7ea73c),
      to(#67981a)
    ) repeat-x;
  background: #67981a linear-gradient(180deg, #7ea73c, #67981a) repeat-x;
  border-color: #67981a;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-success:hover {
  color: #fff;
  background: #517714 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#6b8c38),
      to(#517714)
    ) repeat-x;
  background: #517714 linear-gradient(180deg, #6b8c38, #517714) repeat-x;
  border-color: #496c13;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background: #517714 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#6b8c38),
      to(#517714)
    ) repeat-x;
  background: #517714 linear-gradient(180deg, #6b8c38, #517714) repeat-x;
  border-color: #496c13;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(126, 167, 60, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(126, 167, 60, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #67981a;
  border-color: #67981a;
  background-image: none;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #496c13;
  background-image: none;
  border-color: #426211;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(126, 167, 60, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(126, 167, 60, 0.5);
}

.btn-info {
  color: #fff;
  background: #3f93f3 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#5ca3f5),
      to(#3f93f3)
    ) repeat-x;
  background: #3f93f3 linear-gradient(180deg, #5ca3f5, #3f93f3) repeat-x;
  border-color: #3f93f3;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-info:hover {
  color: #fff;
  background: #1b7ff1 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#3d92f3),
      to(#1b7ff1)
    ) repeat-x;
  background: #1b7ff1 linear-gradient(180deg, #3d92f3, #1b7ff1) repeat-x;
  border-color: #0f78f0;
}
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background: #1b7ff1 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#3d92f3),
      to(#1b7ff1)
    ) repeat-x;
  background: #1b7ff1 linear-gradient(180deg, #3d92f3, #1b7ff1) repeat-x;
  border-color: #0f78f0;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(92, 163, 245, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(92, 163, 245, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #3f93f3;
  border-color: #3f93f3;
  background-image: none;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0f78f0;
  background-image: none;
  border-color: #0e72e4;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(92, 163, 245, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(92, 163, 245, 0.5);
}

.btn-warning {
  color: #212529;
  background: #ffba00 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ffc426),
      to(#ffba00)
    ) repeat-x;
  background: #ffba00 linear-gradient(180deg, #ffc426, #ffba00) repeat-x;
  border-color: #ffba00;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-warning:hover {
  color: #212529;
  background: #d99e00 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#dead26),
      to(#d99e00)
    ) repeat-x;
  background: #d99e00 linear-gradient(180deg, #dead26, #d99e00) repeat-x;
  border-color: #cc9500;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background: #d99e00 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#dead26),
      to(#d99e00)
    ) repeat-x;
  background: #d99e00 linear-gradient(180deg, #dead26, #d99e00) repeat-x;
  border-color: #cc9500;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(222, 164, 6, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(222, 164, 6, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffba00;
  border-color: #ffba00;
  background-image: none;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc9500;
  background-image: none;
  border-color: #bf8c00;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 164, 6, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 164, 6, 0.5);
}

.btn-danger {
  color: #fff;
  background: #ff0000 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff2626),
      to(#ff0000)
    ) repeat-x;
  background: #ff0000 linear-gradient(180deg, #ff2626, #ff0000) repeat-x;
  border-color: #ff0000;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-danger:hover {
  color: #fff;
  background: #d90000 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#de2626),
      to(#d90000)
    ) repeat-x;
  background: #d90000 linear-gradient(180deg, #de2626, #d90000) repeat-x;
  border-color: #cc0000;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background: #d90000 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#de2626),
      to(#d90000)
    ) repeat-x;
  background: #d90000 linear-gradient(180deg, #de2626, #d90000) repeat-x;
  border-color: #cc0000;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
  background-image: none;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #cc0000;
  background-image: none;
  border-color: #bf0000;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
}

.btn-light {
  color: #212529;
  background: #f0f0f0 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f2f2f2),
      to(#f0f0f0)
    ) repeat-x;
  background: #f0f0f0 linear-gradient(180deg, #f2f2f2, #f0f0f0) repeat-x;
  border-color: #f0f0f0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-light:hover {
  color: #212529;
  background: #dddddd -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#e2e2e2),
      to(#dddddd)
    ) repeat-x;
  background: #dddddd linear-gradient(180deg, #e2e2e2, #dddddd) repeat-x;
  border-color: #d7d7d7;
}
.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background: #dddddd -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#e2e2e2),
      to(#dddddd)
    ) repeat-x;
  background: #dddddd linear-gradient(180deg, #e2e2e2, #dddddd) repeat-x;
  border-color: #d7d7d7;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(209, 210, 210, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(209, 210, 210, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  background-image: none;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d7d7d7;
  background-image: none;
  border-color: #d0d0d0;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(209, 210, 210, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(209, 210, 210, 0.5);
}

.btn-dark {
  color: #fff;
  background: #000 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#262626),
      to(#000)
    ) repeat-x;
  background: #000 linear-gradient(180deg, #262626, #000) repeat-x;
  border-color: #000;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-dark:hover {
  color: #fff;
  background: black -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#262626),
      to(black)
    ) repeat-x;
  background: black linear-gradient(180deg, #262626, black) repeat-x;
  border-color: black;
}
.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background: black -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#262626),
      to(black)
    ) repeat-x;
  background: black linear-gradient(180deg, #262626, black) repeat-x;
  border-color: black;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
  background-image: none;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: black;
  background-image: none;
  border-color: black;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-white {
  color: #212529;
  background: #fff -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(white),
      to(#fff)
    ) repeat-x;
  background: #fff linear-gradient(180deg, white, #fff) repeat-x;
  border-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-white:hover {
  color: #212529;
  background: #ececec -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#efefef),
      to(#ececec)
    ) repeat-x;
  background: #ececec linear-gradient(180deg, #efefef, #ececec) repeat-x;
  border-color: #e6e6e6;
}
.btn-white:focus,
.btn-white.focus {
  color: #212529;
  background: #ececec -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#efefef),
      to(#ececec)
    ) repeat-x;
  background: #ececec linear-gradient(180deg, #efefef, #ececec) repeat-x;
  border-color: #e6e6e6;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-white.disabled,
.btn-white:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  background-image: none;
}
.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #dfdfdf;
}
.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-gray {
  color: #212529;
  background: #999999 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#a8a8a8),
      to(#999999)
    ) repeat-x;
  background: #999999 linear-gradient(180deg, #a8a8a8, #999999) repeat-x;
  border-color: #999999;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-gray:hover {
  color: #fff;
  background: #868686 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#989898),
      to(#868686)
    ) repeat-x;
  background: #868686 linear-gradient(180deg, #989898, #868686) repeat-x;
  border-color: gray;
}
.btn-gray:focus,
.btn-gray.focus {
  color: #fff;
  background: #868686 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#989898),
      to(#868686)
    ) repeat-x;
  background: #868686 linear-gradient(180deg, #989898, #868686) repeat-x;
  border-color: gray;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(135, 136, 136, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(135, 136, 136, 0.5);
}
.btn-gray.disabled,
.btn-gray:disabled {
  color: #212529;
  background-color: #999999;
  border-color: #999999;
  background-image: none;
}
.btn-gray:not(:disabled):not(.disabled):active,
.btn-gray:not(:disabled):not(.disabled).active,
.show > .btn-gray.dropdown-toggle {
  color: #fff;
  background-color: gray;
  background-image: none;
  border-color: #797979;
}
.btn-gray:not(:disabled):not(.disabled):active:focus,
.btn-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(135, 136, 136, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(135, 136, 136, 0.5);
}

.btn-darker-light {
  color: #212529;
  background: #cccccc -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#d4d4d4),
      to(#cccccc)
    ) repeat-x;
  background: #cccccc linear-gradient(180deg, #d4d4d4, #cccccc) repeat-x;
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-darker-light:hover {
  color: #212529;
  background: #b9b9b9 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#c3c3c3),
      to(#b9b9b9)
    ) repeat-x;
  background: #b9b9b9 linear-gradient(180deg, #c3c3c3, #b9b9b9) repeat-x;
  border-color: #b3b3b3;
}
.btn-darker-light:focus,
.btn-darker-light.focus {
  color: #212529;
  background: #b9b9b9 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#c3c3c3),
      to(#b9b9b9)
    ) repeat-x;
  background: #b9b9b9 linear-gradient(180deg, #c3c3c3, #b9b9b9) repeat-x;
  border-color: #b3b3b3;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(178, 179, 180, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(178, 179, 180, 0.5);
}
.btn-darker-light.disabled,
.btn-darker-light:disabled {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
  background-image: none;
}
.btn-darker-light:not(:disabled):not(.disabled):active,
.btn-darker-light:not(:disabled):not(.disabled).active,
.show > .btn-darker-light.dropdown-toggle {
  color: #212529;
  background-color: #b3b3b3;
  background-image: none;
  border-color: #acacac;
}
.btn-darker-light:not(:disabled):not(.disabled):active:focus,
.btn-darker-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-darker-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5);
}

.btn-dark-red {
  color: #fff;
  background: #cc0000 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#d42626),
      to(#cc0000)
    ) repeat-x;
  background: #cc0000 linear-gradient(180deg, #d42626, #cc0000) repeat-x;
  border-color: #cc0000;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-dark-red:hover {
  color: #fff;
  background: #a60000 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#b32626),
      to(#a60000)
    ) repeat-x;
  background: #a60000 linear-gradient(180deg, #b32626, #a60000) repeat-x;
  border-color: #990000;
}
.btn-dark-red:focus,
.btn-dark-red.focus {
  color: #fff;
  background: #a60000 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#b32626),
      to(#a60000)
    ) repeat-x;
  background: #a60000 linear-gradient(180deg, #b32626, #a60000) repeat-x;
  border-color: #990000;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(212, 38, 38, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(212, 38, 38, 0.5);
}
.btn-dark-red.disabled,
.btn-dark-red:disabled {
  color: #fff;
  background-color: #cc0000;
  border-color: #cc0000;
  background-image: none;
}
.btn-dark-red:not(:disabled):not(.disabled):active,
.btn-dark-red:not(:disabled):not(.disabled).active,
.show > .btn-dark-red.dropdown-toggle {
  color: #fff;
  background-color: #990000;
  background-image: none;
  border-color: #8c0000;
}
.btn-dark-red:not(:disabled):not(.disabled):active:focus,
.btn-dark-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark-red.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(212, 38, 38, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(212, 38, 38, 0.5);
}

.btn-lighter-dark {
  color: #fff;
  background: #262626 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#474747),
      to(#262626)
    ) repeat-x;
  background: #262626 linear-gradient(180deg, #474747, #262626) repeat-x;
  border-color: #262626;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-lighter-dark:hover {
  color: #fff;
  background: #131313 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#363636),
      to(#131313)
    ) repeat-x;
  background: #131313 linear-gradient(180deg, #363636, #131313) repeat-x;
  border-color: #0d0d0d;
}
.btn-lighter-dark:focus,
.btn-lighter-dark.focus {
  color: #fff;
  background: #131313 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#363636),
      to(#131313)
    ) repeat-x;
  background: #131313 linear-gradient(180deg, #363636, #131313) repeat-x;
  border-color: #0d0d0d;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(71, 71, 71, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(71, 71, 71, 0.5);
}
.btn-lighter-dark.disabled,
.btn-lighter-dark:disabled {
  color: #fff;
  background-color: #262626;
  border-color: #262626;
  background-image: none;
}
.btn-lighter-dark:not(:disabled):not(.disabled):active,
.btn-lighter-dark:not(:disabled):not(.disabled).active,
.show > .btn-lighter-dark.dropdown-toggle {
  color: #fff;
  background-color: #0d0d0d;
  background-image: none;
  border-color: #060606;
}
.btn-lighter-dark:not(:disabled):not(.disabled):active:focus,
.btn-lighter-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-lighter-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.5);
}

.btn-green {
  color: #fff;
  background: #74b100 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#89bd26),
      to(#74b100)
    ) repeat-x;
  background: #74b100 linear-gradient(180deg, #89bd26, #74b100) repeat-x;
  border-color: #74b100;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-green:hover {
  color: #fff;
  background: #5b8b00 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#749c26),
      to(#5b8b00)
    ) repeat-x;
  background: #5b8b00 linear-gradient(180deg, #749c26, #5b8b00) repeat-x;
  border-color: #537e00;
}
.btn-green:focus,
.btn-green.focus {
  color: #fff;
  background: #5b8b00 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#749c26),
      to(#5b8b00)
    ) repeat-x;
  background: #5b8b00 linear-gradient(180deg, #749c26, #5b8b00) repeat-x;
  border-color: #537e00;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(137, 189, 38, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(137, 189, 38, 0.5);
}
.btn-green.disabled,
.btn-green:disabled {
  color: #fff;
  background-color: #74b100;
  border-color: #74b100;
  background-image: none;
}
.btn-green:not(:disabled):not(.disabled):active,
.btn-green:not(:disabled):not(.disabled).active,
.show > .btn-green.dropdown-toggle {
  color: #fff;
  background-color: #537e00;
  background-image: none;
  border-color: #4a7100;
}
.btn-green:not(:disabled):not(.disabled):active:focus,
.btn-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-green.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(137, 189, 38, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(137, 189, 38, 0.5);
}

.btn-gray-01 {
  color: #212529;
  background: #e8edef -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ebf0f1),
      to(#e8edef)
    ) repeat-x;
  background: #e8edef linear-gradient(180deg, #ebf0f1, #e8edef) repeat-x;
  border-color: #e8edef;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-gray-01:hover {
  color: #212529;
  background: #d1dbdf -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#d8e1e4),
      to(#d1dbdf)
    ) repeat-x;
  background: #d1dbdf linear-gradient(180deg, #d8e1e4, #d1dbdf) repeat-x;
  border-color: #cad5da;
}
.btn-gray-01:focus,
.btn-gray-01.focus {
  color: #212529;
  background: #d1dbdf -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#d8e1e4),
      to(#d1dbdf)
    ) repeat-x;
  background: #d1dbdf linear-gradient(180deg, #d8e1e4, #d1dbdf) repeat-x;
  border-color: #cad5da;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(202, 207, 209, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(202, 207, 209, 0.5);
}
.btn-gray-01.disabled,
.btn-gray-01:disabled {
  color: #212529;
  background-color: #e8edef;
  border-color: #e8edef;
  background-image: none;
}
.btn-gray-01:not(:disabled):not(.disabled):active,
.btn-gray-01:not(:disabled):not(.disabled).active,
.show > .btn-gray-01.dropdown-toggle {
  color: #212529;
  background-color: #cad5da;
  background-image: none;
  border-color: #c2d0d5;
}
.btn-gray-01:not(:disabled):not(.disabled):active:focus,
.btn-gray-01:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-01.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(202, 207, 209, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(202, 207, 209, 0.5);
}

.btn-gray-02 {
  color: #212529;
  background: #f5f5f5 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f7f7f7),
      to(#f5f5f5)
    ) repeat-x;
  background: #f5f5f5 linear-gradient(180deg, #f7f7f7, #f5f5f5) repeat-x;
  border-color: #f5f5f5;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-gray-02:hover {
  color: #212529;
  background: #e2e2e2 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#e6e6e6),
      to(#e2e2e2)
    ) repeat-x;
  background: #e2e2e2 linear-gradient(180deg, #e6e6e6, #e2e2e2) repeat-x;
  border-color: gainsboro;
}
.btn-gray-02:focus,
.btn-gray-02.focus {
  color: #212529;
  background: #e2e2e2 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#e6e6e6),
      to(#e2e2e2)
    ) repeat-x;
  background: #e2e2e2 linear-gradient(180deg, #e6e6e6, #e2e2e2) repeat-x;
  border-color: gainsboro;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}
.btn-gray-02.disabled,
.btn-gray-02:disabled {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  background-image: none;
}
.btn-gray-02:not(:disabled):not(.disabled):active,
.btn-gray-02:not(:disabled):not(.disabled).active,
.show > .btn-gray-02.dropdown-toggle {
  color: #212529;
  background-color: gainsboro;
  background-image: none;
  border-color: #d5d5d5;
}
.btn-gray-02:not(:disabled):not(.disabled):active:focus,
.btn-gray-02:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-02.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.btn-gray-03 {
  color: #212529;
  background: #eeeeee -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f1f1f1),
      to(#eeeeee)
    ) repeat-x;
  background: #eeeeee linear-gradient(180deg, #f1f1f1, #eeeeee) repeat-x;
  border-color: #eeeeee;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-gray-03:hover {
  color: #212529;
  background: #dbdbdb -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#e0e0e0),
      to(#dbdbdb)
    ) repeat-x;
  background: #dbdbdb linear-gradient(180deg, #e0e0e0, #dbdbdb) repeat-x;
  border-color: #d5d5d5;
}
.btn-gray-03:focus,
.btn-gray-03.focus {
  color: #212529;
  background: #dbdbdb -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#e0e0e0),
      to(#dbdbdb)
    ) repeat-x;
  background: #dbdbdb linear-gradient(180deg, #e0e0e0, #dbdbdb) repeat-x;
  border-color: #d5d5d5;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(207, 208, 208, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(207, 208, 208, 0.5);
}
.btn-gray-03.disabled,
.btn-gray-03:disabled {
  color: #212529;
  background-color: #eeeeee;
  border-color: #eeeeee;
  background-image: none;
}
.btn-gray-03:not(:disabled):not(.disabled):active,
.btn-gray-03:not(:disabled):not(.disabled).active,
.show > .btn-gray-03.dropdown-toggle {
  color: #212529;
  background-color: #d5d5d5;
  background-image: none;
  border-color: #cecece;
}
.btn-gray-03:not(:disabled):not(.disabled):active:focus,
.btn-gray-03:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-03.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 208, 208, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(207, 208, 208, 0.5);
}

.btn-gray-04 {
  color: #212529;
  background: #eef1f2 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f1f3f4),
      to(#eef1f2)
    ) repeat-x;
  background: #eef1f2 linear-gradient(180deg, #f1f3f4, #eef1f2) repeat-x;
  border-color: #eef1f2;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-gray-04:hover {
  color: #212529;
  background: #d8dfe1 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#dee4e6),
      to(#d8dfe1)
    ) repeat-x;
  background: #d8dfe1 linear-gradient(180deg, #dee4e6, #d8dfe1) repeat-x;
  border-color: #d1d9dc;
}
.btn-gray-04:focus,
.btn-gray-04.focus {
  color: #212529;
  background: #d8dfe1 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#dee4e6),
      to(#d8dfe1)
    ) repeat-x;
  background: #d8dfe1 linear-gradient(180deg, #dee4e6, #d8dfe1) repeat-x;
  border-color: #d1d9dc;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(207, 210, 212, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(207, 210, 212, 0.5);
}
.btn-gray-04.disabled,
.btn-gray-04:disabled {
  color: #212529;
  background-color: #eef1f2;
  border-color: #eef1f2;
  background-image: none;
}
.btn-gray-04:not(:disabled):not(.disabled):active,
.btn-gray-04:not(:disabled):not(.disabled).active,
.show > .btn-gray-04.dropdown-toggle {
  color: #212529;
  background-color: #d1d9dc;
  background-image: none;
  border-color: #cad3d6;
}
.btn-gray-04:not(:disabled):not(.disabled):active:focus,
.btn-gray-04:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-04.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 210, 212, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(207, 210, 212, 0.5);
}

.btn-gray-05 {
  color: #212529;
  background: #ecf0f1 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#eff2f3),
      to(#ecf0f1)
    ) repeat-x;
  background: #ecf0f1 linear-gradient(180deg, #eff2f3, #ecf0f1) repeat-x;
  border-color: #ecf0f1;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-gray-05:hover {
  color: #212529;
  background: #d6dfe1 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#dce3e5),
      to(#d6dfe1)
    ) repeat-x;
  background: #d6dfe1 linear-gradient(180deg, #dce3e5, #d6dfe1) repeat-x;
  border-color: #cfd9db;
}
.btn-gray-05:focus,
.btn-gray-05.focus {
  color: #212529;
  background: #d6dfe1 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#dce3e5),
      to(#d6dfe1)
    ) repeat-x;
  background: #d6dfe1 linear-gradient(180deg, #dce3e5, #d6dfe1) repeat-x;
  border-color: #cfd9db;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(206, 210, 211, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(206, 210, 211, 0.5);
}
.btn-gray-05.disabled,
.btn-gray-05:disabled {
  color: #212529;
  background-color: #ecf0f1;
  border-color: #ecf0f1;
  background-image: none;
}
.btn-gray-05:not(:disabled):not(.disabled):active,
.btn-gray-05:not(:disabled):not(.disabled).active,
.show > .btn-gray-05.dropdown-toggle {
  color: #212529;
  background-color: #cfd9db;
  background-image: none;
  border-color: #c7d3d6;
}
.btn-gray-05:not(:disabled):not(.disabled):active:focus,
.btn-gray-05:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-05.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 210, 211, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(206, 210, 211, 0.5);
}

.btn-gray-06 {
  color: #212529;
  background: #f0f2f3 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f2f4f5),
      to(#f0f2f3)
    ) repeat-x;
  background: #f0f2f3 linear-gradient(180deg, #f2f4f5, #f0f2f3) repeat-x;
  border-color: #f0f2f3;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-gray-06:hover {
  color: #212529;
  background: #dbe0e2 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#e0e4e6),
      to(#dbe0e2)
    ) repeat-x;
  background: #dbe0e2 linear-gradient(180deg, #e0e4e6, #dbe0e2) repeat-x;
  border-color: #d4d9dc;
}
.btn-gray-06:focus,
.btn-gray-06.focus {
  color: #212529;
  background: #dbe0e2 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#e0e4e6),
      to(#dbe0e2)
    ) repeat-x;
  background: #dbe0e2 linear-gradient(180deg, #e0e4e6, #dbe0e2) repeat-x;
  border-color: #d4d9dc;
  -webkit-box-shadow: none, 0 0 0 0.2rem rgba(209, 211, 213, 0.5);
  box-shadow: none, 0 0 0 0.2rem rgba(209, 211, 213, 0.5);
}
.btn-gray-06.disabled,
.btn-gray-06:disabled {
  color: #212529;
  background-color: #f0f2f3;
  border-color: #f0f2f3;
  background-image: none;
}
.btn-gray-06:not(:disabled):not(.disabled):active,
.btn-gray-06:not(:disabled):not(.disabled).active,
.show > .btn-gray-06.dropdown-toggle {
  color: #212529;
  background-color: #d4d9dc;
  background-image: none;
  border-color: #cdd3d7;
}
.btn-gray-06:not(:disabled):not(.disabled):active:focus,
.btn-gray-06:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-06.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(209, 211, 213, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(209, 211, 213, 0.5);
}

.btn-outline-primary {
  color: #ff5a5f;
  border-color: #ff5a5f;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #ff5a5f;
  border-color: #ff5a5f;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 90, 95, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 95, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #ff5a5f;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff5a5f;
  border-color: #ff5a5f;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 90, 95, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 95, 0.5);
}

.btn-outline-secondary {
  color: #666666;
  border-color: #666666;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #666666;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}

.btn-outline-success {
  color: #67981a;
  border-color: #67981a;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #67981a;
  border-color: #67981a;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #67981a;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #67981a;
  border-color: #67981a;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.5);
}

.btn-outline-info {
  color: #3f93f3;
  border-color: #3f93f3;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #3f93f3;
  border-color: #3f93f3;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 147, 243, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(63, 147, 243, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #3f93f3;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #3f93f3;
  border-color: #3f93f3;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 147, 243, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(63, 147, 243, 0.5);
}

.btn-outline-warning {
  color: #ffba00;
  border-color: #ffba00;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffba00;
  border-color: #ffba00;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 186, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 186, 0, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffba00;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffba00;
  border-color: #ffba00;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 186, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 186, 0, 0.5);
}

.btn-outline-danger {
  color: #ff0000;
  border-color: #ff0000;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #ff0000;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
}

.btn-outline-light {
  color: #f0f0f0;
  border-color: #f0f0f0;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f0f0f0;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5);
}

.btn-outline-dark {
  color: #000;
  border-color: #000;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #000;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:focus,
.btn-outline-white.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white.disabled,
.btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-gray {
  color: #999999;
  border-color: #999999;
}
.btn-outline-gray:hover {
  color: #212529;
  background-color: #999999;
  border-color: #999999;
}
.btn-outline-gray:focus,
.btn-outline-gray.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}
.btn-outline-gray.disabled,
.btn-outline-gray:disabled {
  color: #999999;
  background-color: transparent;
}
.btn-outline-gray:not(:disabled):not(.disabled):active,
.btn-outline-gray:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray.dropdown-toggle {
  color: #212529;
  background-color: #999999;
  border-color: #999999;
}
.btn-outline-gray:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-outline-darker-light {
  color: #cccccc;
  border-color: #cccccc;
}
.btn-outline-darker-light:hover {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}
.btn-outline-darker-light:focus,
.btn-outline-darker-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
}
.btn-outline-darker-light.disabled,
.btn-outline-darker-light:disabled {
  color: #cccccc;
  background-color: transparent;
}
.btn-outline-darker-light:not(:disabled):not(.disabled):active,
.btn-outline-darker-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-darker-light.dropdown-toggle {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}
.btn-outline-darker-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-darker-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-darker-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
}

.btn-outline-dark-red {
  color: #cc0000;
  border-color: #cc0000;
}
.btn-outline-dark-red:hover {
  color: #fff;
  background-color: #cc0000;
  border-color: #cc0000;
}
.btn-outline-dark-red:focus,
.btn-outline-dark-red.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5);
}
.btn-outline-dark-red.disabled,
.btn-outline-dark-red:disabled {
  color: #cc0000;
  background-color: transparent;
}
.btn-outline-dark-red:not(:disabled):not(.disabled):active,
.btn-outline-dark-red:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-red.dropdown-toggle {
  color: #fff;
  background-color: #cc0000;
  border-color: #cc0000;
}
.btn-outline-dark-red:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark-red.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5);
}

.btn-outline-lighter-dark {
  color: #262626;
  border-color: #262626;
}
.btn-outline-lighter-dark:hover {
  color: #fff;
  background-color: #262626;
  border-color: #262626;
}
.btn-outline-lighter-dark:focus,
.btn-outline-lighter-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-outline-lighter-dark.disabled,
.btn-outline-lighter-dark:disabled {
  color: #262626;
  background-color: transparent;
}
.btn-outline-lighter-dark:not(:disabled):not(.disabled):active,
.btn-outline-lighter-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-lighter-dark.dropdown-toggle {
  color: #fff;
  background-color: #262626;
  border-color: #262626;
}
.btn-outline-lighter-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-lighter-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-lighter-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-outline-green {
  color: #74b100;
  border-color: #74b100;
}
.btn-outline-green:hover {
  color: #fff;
  background-color: #74b100;
  border-color: #74b100;
}
.btn-outline-green:focus,
.btn-outline-green.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 177, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(116, 177, 0, 0.5);
}
.btn-outline-green.disabled,
.btn-outline-green:disabled {
  color: #74b100;
  background-color: transparent;
}
.btn-outline-green:not(:disabled):not(.disabled):active,
.btn-outline-green:not(:disabled):not(.disabled).active,
.show > .btn-outline-green.dropdown-toggle {
  color: #fff;
  background-color: #74b100;
  border-color: #74b100;
}
.btn-outline-green:not(:disabled):not(.disabled):active:focus,
.btn-outline-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-green.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 177, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(116, 177, 0, 0.5);
}

.btn-outline-gray-01 {
  color: #e8edef;
  border-color: #e8edef;
}
.btn-outline-gray-01:hover {
  color: #212529;
  background-color: #e8edef;
  border-color: #e8edef;
}
.btn-outline-gray-01:focus,
.btn-outline-gray-01.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 237, 239, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(232, 237, 239, 0.5);
}
.btn-outline-gray-01.disabled,
.btn-outline-gray-01:disabled {
  color: #e8edef;
  background-color: transparent;
}
.btn-outline-gray-01:not(:disabled):not(.disabled):active,
.btn-outline-gray-01:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-01.dropdown-toggle {
  color: #212529;
  background-color: #e8edef;
  border-color: #e8edef;
}
.btn-outline-gray-01:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray-01:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-01.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 237, 239, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(232, 237, 239, 0.5);
}

.btn-outline-gray-02 {
  color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-gray-02:hover {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-gray-02:focus,
.btn-outline-gray-02.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}
.btn-outline-gray-02.disabled,
.btn-outline-gray-02:disabled {
  color: #f5f5f5;
  background-color: transparent;
}
.btn-outline-gray-02:not(:disabled):not(.disabled):active,
.btn-outline-gray-02:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-02.dropdown-toggle {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-gray-02:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray-02:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-02.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}

.btn-outline-gray-03 {
  color: #eeeeee;
  border-color: #eeeeee;
}
.btn-outline-gray-03:hover {
  color: #212529;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.btn-outline-gray-03:focus,
.btn-outline-gray-03.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}
.btn-outline-gray-03.disabled,
.btn-outline-gray-03:disabled {
  color: #eeeeee;
  background-color: transparent;
}
.btn-outline-gray-03:not(:disabled):not(.disabled):active,
.btn-outline-gray-03:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-03.dropdown-toggle {
  color: #212529;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.btn-outline-gray-03:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray-03:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-03.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}

.btn-outline-gray-04 {
  color: #eef1f2;
  border-color: #eef1f2;
}
.btn-outline-gray-04:hover {
  color: #212529;
  background-color: #eef1f2;
  border-color: #eef1f2;
}
.btn-outline-gray-04:focus,
.btn-outline-gray-04.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 241, 242, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(238, 241, 242, 0.5);
}
.btn-outline-gray-04.disabled,
.btn-outline-gray-04:disabled {
  color: #eef1f2;
  background-color: transparent;
}
.btn-outline-gray-04:not(:disabled):not(.disabled):active,
.btn-outline-gray-04:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-04.dropdown-toggle {
  color: #212529;
  background-color: #eef1f2;
  border-color: #eef1f2;
}
.btn-outline-gray-04:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray-04:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-04.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 241, 242, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(238, 241, 242, 0.5);
}

.btn-outline-gray-05 {
  color: #ecf0f1;
  border-color: #ecf0f1;
}
.btn-outline-gray-05:hover {
  color: #212529;
  background-color: #ecf0f1;
  border-color: #ecf0f1;
}
.btn-outline-gray-05:focus,
.btn-outline-gray-05.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(236, 240, 241, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(236, 240, 241, 0.5);
}
.btn-outline-gray-05.disabled,
.btn-outline-gray-05:disabled {
  color: #ecf0f1;
  background-color: transparent;
}
.btn-outline-gray-05:not(:disabled):not(.disabled):active,
.btn-outline-gray-05:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-05.dropdown-toggle {
  color: #212529;
  background-color: #ecf0f1;
  border-color: #ecf0f1;
}
.btn-outline-gray-05:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray-05:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-05.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(236, 240, 241, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(236, 240, 241, 0.5);
}

.btn-outline-gray-06 {
  color: #f0f2f3;
  border-color: #f0f2f3;
}
.btn-outline-gray-06:hover {
  color: #212529;
  background-color: #f0f2f3;
  border-color: #f0f2f3;
}
.btn-outline-gray-06:focus,
.btn-outline-gray-06.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 242, 243, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(240, 242, 243, 0.5);
}
.btn-outline-gray-06.disabled,
.btn-outline-gray-06:disabled {
  color: #f0f2f3;
  background-color: transparent;
}
.btn-outline-gray-06:not(:disabled):not(.disabled):active,
.btn-outline-gray-06:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-06.dropdown-toggle {
  color: #212529;
  background-color: #f0f2f3;
  border-color: #f0f2f3;
}
.btn-outline-gray-06:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray-06:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-06.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 242, 243, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(240, 242, 243, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0099cc;
  text-decoration: none;
}
.btn-link:hover {
  color: #ff5a5f;
  text-decoration: underline;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #999999;
  pointer-events: none;
}

.btn-hero {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 83px;
  padding-left: 30px;
  padding-right: 30px;
  border-color: #dee2e6;
  font-size: 18px;
  width: 100%;
  line-height: 1.3;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  color: #000;
}
.btn-hero:hover {
  background: #ff5a5f;
  color: #fff;
  border-color: #ff5a5f;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 2;
  border-radius: 3px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.7;
  border-radius: 3px;
}

.btn-mini {
  padding: 0.25rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.7;
  border-radius: 3px;
}

.btn-dashed {
  border-style: dashed;
}

.btn-light {
  color: #999999;
}

.btn-icon-left i {
  margin-right: 10px;
}

.btn-icon-right i {
  margin-left: 10px;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.btn-round {
  border-radius: 30px;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #666666;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background: #f8f9fa -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f9fafb),
      to(#f8f9fa)
    ) repeat-x;
  background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background: #ff5a5f -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff7377),
      to(#ff5a5f)
    ) repeat-x;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #999999;
  pointer-events: none;
  background-color: transparent;
  background-image: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #999999;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-group.show .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.7em + 1.5rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  line-height: 2;
  border-radius: 3px;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.7em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.7;
  border-radius: 2px;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.7rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.35rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ff5a5f;
  background: #ff5a5f -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff7377),
      to(#ff5a5f)
    ) repeat-x;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0, none;
  box-shadow: 0, none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dee2e6;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: white;
  border-color: white;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #999999;
}
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.35rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #cccccc solid 1px;
  -webkit-box-shadow: 0;
  box-shadow: 0;
}
.custom-control-label::after {
  position: absolute;
  top: 0.35rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 3px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #ff5a5f;
  background: #ff5a5f -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff7377),
      to(#ff5a5f)
    ) repeat-x;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(255, 90, 95, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(255, 90, 95, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(255, 90, 95, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.35rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #cccccc;
  border-radius: 0.5rem;
  -webkit-transition: transform 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(255, 90, 95, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #dee2e6;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(255, 90, 95, 0.25);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(255, 90, 95, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #999999;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.7em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.7em + 1.5rem + 2px);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  font-size: 1.125rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.7em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.7em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #dee2e6;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.7;
  color: #495057;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  -webkit-box-shadow: 0;
  box-shadow: 0;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.7em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.7;
  color: #495057;
  content: "Browse";
  background: #e9ecef -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#eceff1),
      to(#e9ecef)
    ) repeat-x;
  background: #e9ecef linear-gradient(180deg, #eceff1, #e9ecef) repeat-x;
  border-left: inherit;
  border-radius: 0 3px 3px 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #fff, none;
  box-shadow: 0 0 0 1px #fff, none;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background: #ff5a5f -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff7377),
      to(#ff5a5f)
    ) repeat-x;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
  border: 0;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
.custom-range::-webkit-slider-thumb:active {
  background: white -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(white),
      to(white)
    ) repeat-x;
  background: white linear-gradient(180deg, white, white) repeat-x;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  -webkit-box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
.custom-range::-moz-range-thumb:active {
  background: white linear-gradient(180deg, white, white) repeat-x;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
.custom-range::-ms-thumb:active {
  background: white linear-gradient(180deg, white, white) repeat-x;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #cccccc;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #cccccc;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #cccccc;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #999999;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #999999;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 3px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000;
  background-color: #ff5a5f;
}

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.nav-borderless {
  border-color: transparent;
}

.nav-classic {
  border-bottom: 1px solid #dee2e6;
}
.nav-classic .nav-link {
  color: #666666;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  padding: 1rem 1.5rem;
  margin-bottom: -0.125rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.nav-classic .nav-link.active {
  border-color: #ff5a5f;
  color: #ff5a5f;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.39375rem;
  padding-bottom: 0.39375rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #000;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #000;
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  /* border-radius: 3px; */
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 3px;
  border: 3px solid rgba(0, 0, 0, 0.125);
  border-top-right-radius: 3px;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 2px 2px 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 2px 2px;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.card-collapse {
  padding: 0;
  background: #fff;
}
.card-collapse:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.card-collapse:hover {
  background: #f8f9fa;
}
.card-collapse .btn {
  font-size: 1rem;
}
.card-collapse .btn:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 3px;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #999999;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #999999;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 3px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0099cc;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #ff5a5f;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff5a5f;
  border-color: #ff5a5f;
}

.page-item.disabled .page-link {
  color: #999999;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 2;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.7;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
a.badge:hover,
a.badge:focus {
  text-decoration: none;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -8px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #ff5a5f;
}
a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #ff272e;
}
a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 90, 95, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 95, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #666666;
}
a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #4d4d4d;
}
a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #67981a;
}
a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #496c13;
}
a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(103, 152, 26, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #3f93f3;
}
a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #0f78f0;
}
a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 147, 243, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(63, 147, 243, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffba00;
}
a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #cc9500;
}
a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 186, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 186, 0, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #ff0000;
}
a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #cc0000;
}
a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f0f0f0;
}
a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #d7d7d7;
}
a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(240, 240, 240, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #000;
}
a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: black;
}
a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-white {
  color: #212529;
  background-color: #fff;
}
a.badge-white:hover,
a.badge-white:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-white:focus,
a.badge-white.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-gray {
  color: #212529;
  background-color: #999999;
}
a.badge-gray:hover,
a.badge-gray:focus {
  color: #212529;
  background-color: gray;
}
a.badge-gray:focus,
a.badge-gray.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.badge-darker-light {
  color: #212529;
  background-color: #cccccc;
}
a.badge-darker-light:hover,
a.badge-darker-light:focus {
  color: #212529;
  background-color: #b3b3b3;
}
a.badge-darker-light:focus,
a.badge-darker-light.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
}

.badge-dark-red {
  color: #fff;
  background-color: #cc0000;
}
a.badge-dark-red:hover,
a.badge-dark-red:focus {
  color: #fff;
  background-color: #990000;
}
a.badge-dark-red:focus,
a.badge-dark-red.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5);
}

.badge-lighter-dark {
  color: #fff;
  background-color: #262626;
}
a.badge-lighter-dark:hover,
a.badge-lighter-dark:focus {
  color: #fff;
  background-color: #0d0d0d;
}
a.badge-lighter-dark:focus,
a.badge-lighter-dark.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.badge-green {
  color: #fff;
  background-color: #74b100;
}
a.badge-green:hover,
a.badge-green:focus {
  color: #fff;
  background-color: #537e00;
}
a.badge-green:focus,
a.badge-green.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 177, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(116, 177, 0, 0.5);
}

.badge-gray-01 {
  color: #212529;
  background-color: #e8edef;
}
a.badge-gray-01:hover,
a.badge-gray-01:focus {
  color: #212529;
  background-color: #cad5da;
}
a.badge-gray-01:focus,
a.badge-gray-01.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 237, 239, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(232, 237, 239, 0.5);
}

.badge-gray-02 {
  color: #212529;
  background-color: #f5f5f5;
}
a.badge-gray-02:hover,
a.badge-gray-02:focus {
  color: #212529;
  background-color: gainsboro;
}
a.badge-gray-02:focus,
a.badge-gray-02.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}

.badge-gray-03 {
  color: #212529;
  background-color: #eeeeee;
}
a.badge-gray-03:hover,
a.badge-gray-03:focus {
  color: #212529;
  background-color: #d5d5d5;
}
a.badge-gray-03:focus,
a.badge-gray-03.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}

.badge-gray-04 {
  color: #212529;
  background-color: #eef1f2;
}
a.badge-gray-04:hover,
a.badge-gray-04:focus {
  color: #212529;
  background-color: #d1d9dc;
}
a.badge-gray-04:focus,
a.badge-gray-04.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 241, 242, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(238, 241, 242, 0.5);
}

.badge-gray-05 {
  color: #212529;
  background-color: #ecf0f1;
}
a.badge-gray-05:hover,
a.badge-gray-05:focus {
  color: #212529;
  background-color: #cfd9db;
}
a.badge-gray-05:focus,
a.badge-gray-05.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(236, 240, 241, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(236, 240, 241, 0.5);
}

.badge-gray-06 {
  color: #212529;
  background-color: #f0f2f3;
}
a.badge-gray-06:hover,
a.badge-gray-06:focus {
  color: #212529;
  background-color: #d4d9dc;
}
a.badge-gray-06:focus,
a.badge-gray-06.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 242, 243, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(240, 242, 243, 0.5);
}

.badge-success {
  background: #a3d74e;
}

.badge-primary {
  background: #ff5a5f;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 3px;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 3px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #852f31;
  background: #ffdedf -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ffe3e4),
      to(#ffdedf)
    ) repeat-x;
  background: #ffdedf linear-gradient(180deg, #ffe3e4, #ffdedf) repeat-x;
  border-color: #ffd1d2;
}
.alert-primary hr {
  border-top-color: #ffb8b9;
}
.alert-primary .alert-link {
  color: #5f2223;
}

.alert-secondary {
  color: #353535;
  background: #e0e0e0 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#e5e5e5),
      to(#e0e0e0)
    ) repeat-x;
  background: #e0e0e0 linear-gradient(180deg, #e5e5e5, #e0e0e0) repeat-x;
  border-color: #d4d4d4;
}
.alert-secondary hr {
  border-top-color: #c7c7c7;
}
.alert-secondary .alert-link {
  color: #1c1c1c;
}

.alert-success {
  color: #364f0e;
  background: #e1ead1 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#e6edd8),
      to(#e1ead1)
    ) repeat-x;
  background: #e1ead1 linear-gradient(180deg, #e6edd8, #e1ead1) repeat-x;
  border-color: #d4e2bf;
}
.alert-success hr {
  border-top-color: #c8daad;
}
.alert-success .alert-link {
  color: #182406;
}

.alert-info {
  color: #214c7e;
  background: #d9e9fd -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#dfecfd),
      to(#d9e9fd)
    ) repeat-x;
  background: #d9e9fd linear-gradient(180deg, #dfecfd, #d9e9fd) repeat-x;
  border-color: #c9e1fc;
}
.alert-info hr {
  border-top-color: #b1d4fb;
}
.alert-info .alert-link {
  color: #163456;
}

.alert-warning {
  color: #856100;
  background: #fff1cc -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fff3d4),
      to(#fff1cc)
    ) repeat-x;
  background: #fff1cc linear-gradient(180deg, #fff3d4, #fff1cc) repeat-x;
  border-color: #ffecb8;
}
.alert-warning hr {
  border-top-color: #ffe59f;
}
.alert-warning .alert-link {
  color: #523c00;
}

.alert-danger {
  color: #850000;
  background: #ffcccc -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ffd4d4),
      to(#ffcccc)
    ) repeat-x;
  background: #ffcccc linear-gradient(180deg, #ffd4d4, #ffcccc) repeat-x;
  border-color: #ffb8b8;
}
.alert-danger hr {
  border-top-color: #ff9f9f;
}
.alert-danger .alert-link {
  color: #520000;
}

.alert-light {
  color: #7d7d7d;
  background: #fcfcfc -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fcfcfc),
      to(#fcfcfc)
    ) repeat-x;
  background: #fcfcfc linear-gradient(180deg, #fcfcfc, #fcfcfc) repeat-x;
  border-color: #fbfbfb;
}
.alert-light hr {
  border-top-color: #eeeeee;
}
.alert-light .alert-link {
  color: #646464;
}

.alert-dark {
  color: black;
  background: #cccccc -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#d4d4d4),
      to(#cccccc)
    ) repeat-x;
  background: #cccccc linear-gradient(180deg, #d4d4d4, #cccccc) repeat-x;
  border-color: #b8b8b8;
}
.alert-dark hr {
  border-top-color: #ababab;
}
.alert-dark .alert-link {
  color: black;
}

.alert-white {
  color: #858585;
  background: white -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(white),
      to(white)
    ) repeat-x;
  background: white linear-gradient(180deg, white, white) repeat-x;
  border-color: white;
}
.alert-white hr {
  border-top-color: #f2f2f2;
}
.alert-white .alert-link {
  color: #6c6c6c;
}

.alert-gray {
  color: #505050;
  background: #ebebeb -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#eeeeee),
      to(#ebebeb)
    ) repeat-x;
  background: #ebebeb linear-gradient(180deg, #eeeeee, #ebebeb) repeat-x;
  border-color: #e2e2e2;
}
.alert-gray hr {
  border-top-color: #d5d5d5;
}
.alert-gray .alert-link {
  color: #373737;
}

.alert-darker-light {
  color: #6a6a6a;
  background: whitesmoke -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f7f7f7),
      to(whitesmoke)
    ) repeat-x;
  background: whitesmoke linear-gradient(180deg, #f7f7f7, whitesmoke) repeat-x;
  border-color: #f1f1f1;
}
.alert-darker-light hr {
  border-top-color: #e4e4e4;
}
.alert-darker-light .alert-link {
  color: #515151;
}

.alert-dark-red {
  color: #6a0000;
  background: #f5cccc -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f7d4d4),
      to(#f5cccc)
    ) repeat-x;
  background: #f5cccc linear-gradient(180deg, #f7d4d4, #f5cccc) repeat-x;
  border-color: #f1b8b8;
}
.alert-dark-red hr {
  border-top-color: #eda3a3;
}
.alert-dark-red .alert-link {
  color: #370000;
}

.alert-lighter-dark {
  color: #141414;
  background: #d4d4d4 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#dadada),
      to(#d4d4d4)
    ) repeat-x;
  background: #d4d4d4 linear-gradient(180deg, #dadada, #d4d4d4) repeat-x;
  border-color: #c2c2c2;
}
.alert-lighter-dark hr {
  border-top-color: #b5b5b5;
}
.alert-lighter-dark .alert-link {
  color: black;
}

.alert-green {
  color: #3c5c00;
  background: #e3efcc -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#e7f1d4),
      to(#e3efcc)
    ) repeat-x;
  background: #e3efcc linear-gradient(180deg, #e7f1d4, #e3efcc) repeat-x;
  border-color: #d8e9b8;
}
.alert-green hr {
  border-top-color: #cde3a5;
}
.alert-green .alert-link {
  color: #1b2900;
}

.alert-gray-01 {
  color: #797b7c;
  background: #fafbfc -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fbfcfc),
      to(#fafbfc)
    ) repeat-x;
  background: #fafbfc linear-gradient(180deg, #fbfcfc, #fafbfc) repeat-x;
  border-color: #f9fafb;
}
.alert-gray-01 hr {
  border-top-color: #eaedf1;
}
.alert-gray-01 .alert-link {
  color: #606162;
}

.alert-gray-02 {
  color: #7f7f7f;
  background: #fdfdfd -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fdfdfd),
      to(#fdfdfd)
    ) repeat-x;
  background: #fdfdfd linear-gradient(180deg, #fdfdfd, #fdfdfd) repeat-x;
  border-color: #fcfcfc;
}
.alert-gray-02 hr {
  border-top-color: #efefef;
}
.alert-gray-02 .alert-link {
  color: #666666;
}

.alert-gray-03 {
  color: #7c7c7c;
  background: #fcfcfc -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fcfcfc),
      to(#fcfcfc)
    ) repeat-x;
  background: #fcfcfc linear-gradient(180deg, #fcfcfc, #fcfcfc) repeat-x;
  border-color: #fafafa;
}
.alert-gray-03 hr {
  border-top-color: #ededed;
}
.alert-gray-03 .alert-link {
  color: #636363;
}

.alert-gray-04 {
  color: #7c7d7e;
  background: #fcfcfc -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fcfcfc),
      to(#fcfcfc)
    ) repeat-x;
  background: #fcfcfc linear-gradient(180deg, #fcfcfc, #fcfcfc) repeat-x;
  border-color: #fafbfb;
}
.alert-gray-04 hr {
  border-top-color: #ecf0f0;
}
.alert-gray-04 .alert-link {
  color: #636464;
}

.alert-gray-05 {
  color: #7b7d7d;
  background: #fbfcfc -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fcfcfc),
      to(#fbfcfc)
    ) repeat-x;
  background: #fbfcfc linear-gradient(180deg, #fcfcfc, #fbfcfc) repeat-x;
  border-color: #fafbfb;
}
.alert-gray-05 hr {
  border-top-color: #ecf0f0;
}
.alert-gray-05 .alert-link {
  color: #626363;
}

.alert-gray-06 {
  color: #7d7e7e;
  background: #fcfcfd -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fcfcfd),
      to(#fcfcfd)
    ) repeat-x;
  background: #fcfcfd linear-gradient(180deg, #fcfcfd, #fcfcfd) repeat-x;
  border-color: #fbfbfc;
}
.alert-gray-06 hr {
  border-top-color: #ececf1;
}
.alert-gray-06 .alert-link {
  color: #646464;
}

.alert-warning {
  color: #ca661a;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ff5a5f;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #666666;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.list-group-item:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #999999;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #ff5a5f;
  border-color: #ff5a5f;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0;
}
.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-borderless .list-group-item {
  border: 0;
}

.list-group-item-primary {
  color: #852f31;
  background-color: #ffd1d2;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #852f31;
  background-color: #ffb8b9;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #852f31;
  border-color: #852f31;
}

.list-group-item-secondary {
  color: #353535;
  background-color: #d4d4d4;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #353535;
  background-color: #c7c7c7;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #353535;
  border-color: #353535;
}

.list-group-item-success {
  color: #364f0e;
  background-color: #d4e2bf;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #364f0e;
  background-color: #c8daad;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #364f0e;
  border-color: #364f0e;
}

.list-group-item-info {
  color: #214c7e;
  background-color: #c9e1fc;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #214c7e;
  background-color: #b1d4fb;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #214c7e;
  border-color: #214c7e;
}

.list-group-item-warning {
  color: #856100;
  background-color: #ffecb8;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856100;
  background-color: #ffe59f;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856100;
  border-color: #856100;
}

.list-group-item-danger {
  color: #850000;
  background-color: #ffb8b8;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #850000;
  background-color: #ff9f9f;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #850000;
  border-color: #850000;
}

.list-group-item-light {
  color: #7d7d7d;
  background-color: #fbfbfb;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #7d7d7d;
  background-color: #eeeeee;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7d7d7d;
  border-color: #7d7d7d;
}

.list-group-item-dark {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-gray {
  color: #505050;
  background-color: #e2e2e2;
}
.list-group-item-gray.list-group-item-action:hover,
.list-group-item-gray.list-group-item-action:focus {
  color: #505050;
  background-color: #d5d5d5;
}
.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.list-group-item-darker-light {
  color: #6a6a6a;
  background-color: #f1f1f1;
}
.list-group-item-darker-light.list-group-item-action:hover,
.list-group-item-darker-light.list-group-item-action:focus {
  color: #6a6a6a;
  background-color: #e4e4e4;
}
.list-group-item-darker-light.list-group-item-action.active {
  color: #fff;
  background-color: #6a6a6a;
  border-color: #6a6a6a;
}

.list-group-item-dark-red {
  color: #6a0000;
  background-color: #f1b8b8;
}
.list-group-item-dark-red.list-group-item-action:hover,
.list-group-item-dark-red.list-group-item-action:focus {
  color: #6a0000;
  background-color: #eda3a3;
}
.list-group-item-dark-red.list-group-item-action.active {
  color: #fff;
  background-color: #6a0000;
  border-color: #6a0000;
}

.list-group-item-lighter-dark {
  color: #141414;
  background-color: #c2c2c2;
}
.list-group-item-lighter-dark.list-group-item-action:hover,
.list-group-item-lighter-dark.list-group-item-action:focus {
  color: #141414;
  background-color: #b5b5b5;
}
.list-group-item-lighter-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141414;
  border-color: #141414;
}

.list-group-item-green {
  color: #3c5c00;
  background-color: #d8e9b8;
}
.list-group-item-green.list-group-item-action:hover,
.list-group-item-green.list-group-item-action:focus {
  color: #3c5c00;
  background-color: #cde3a5;
}
.list-group-item-green.list-group-item-action.active {
  color: #fff;
  background-color: #3c5c00;
  border-color: #3c5c00;
}

.list-group-item-gray-01 {
  color: #797b7c;
  background-color: #f9fafb;
}
.list-group-item-gray-01.list-group-item-action:hover,
.list-group-item-gray-01.list-group-item-action:focus {
  color: #797b7c;
  background-color: #eaedf1;
}
.list-group-item-gray-01.list-group-item-action.active {
  color: #fff;
  background-color: #797b7c;
  border-color: #797b7c;
}

.list-group-item-gray-02 {
  color: #7f7f7f;
  background-color: #fcfcfc;
}
.list-group-item-gray-02.list-group-item-action:hover,
.list-group-item-gray-02.list-group-item-action:focus {
  color: #7f7f7f;
  background-color: #efefef;
}
.list-group-item-gray-02.list-group-item-action.active {
  color: #fff;
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}

.list-group-item-gray-03 {
  color: #7c7c7c;
  background-color: #fafafa;
}
.list-group-item-gray-03.list-group-item-action:hover,
.list-group-item-gray-03.list-group-item-action:focus {
  color: #7c7c7c;
  background-color: #ededed;
}
.list-group-item-gray-03.list-group-item-action.active {
  color: #fff;
  background-color: #7c7c7c;
  border-color: #7c7c7c;
}

.list-group-item-gray-04 {
  color: #7c7d7e;
  background-color: #fafbfb;
}
.list-group-item-gray-04.list-group-item-action:hover,
.list-group-item-gray-04.list-group-item-action:focus {
  color: #7c7d7e;
  background-color: #ecf0f0;
}
.list-group-item-gray-04.list-group-item-action.active {
  color: #fff;
  background-color: #7c7d7e;
  border-color: #7c7d7e;
}

.list-group-item-gray-05 {
  color: #7b7d7d;
  background-color: #fafbfb;
}
.list-group-item-gray-05.list-group-item-action:hover,
.list-group-item-gray-05.list-group-item-action:focus {
  color: #7b7d7d;
  background-color: #ecf0f0;
}
.list-group-item-gray-05.list-group-item-action.active {
  color: #fff;
  background-color: #7b7d7d;
  border-color: #7b7d7d;
}

.list-group-item-gray-06 {
  color: #7d7e7e;
  background-color: #fbfbfc;
}
.list-group-item-gray-06.list-group-item-action:hover,
.list-group-item-gray-06.list-group-item-action:focus {
  color: #7d7e7e;
  background-color: #ececf1;
}
.list-group-item-gray-06.list-group-item-action.active {
  color: #fff;
  background-color: #7d7e7e;
  border-color: #7d7e7e;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #999999;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  -webkit-transition: transform 0.3s ease-out;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  -webkit-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.7;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-content {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 3px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  -webkit-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 3px;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 3px 0;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 3px 0;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #000;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #666666;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  content: "";
  display: block;
  clear: bottom;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: transform 0.6s ease-in-out;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.25)),
    to(rgba(0, 0, 0, 0.001))
  );
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0.001)
  );
}

.carousel-control-next {
  right: 0;
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.25)),
    to(rgba(0, 0, 0, 0.001))
  );
  background-image: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0.001)
  );
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #ff5a5f !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ff272e !important;
}

.bg-secondary {
  background-color: #666666 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #4d4d4d !important;
}

.bg-success {
  background-color: #67981a !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #496c13 !important;
}

.bg-info {
  background-color: #3f93f3 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0f78f0 !important;
}

.bg-warning {
  background-color: #ffba00 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc9500 !important;
}

.bg-danger {
  background-color: #ff0000 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #cc0000 !important;
}

.bg-light {
  background-color: #f0f0f0 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d7d7d7 !important;
}

.bg-dark {
  background-color: #000 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-gray {
  background-color: #999999 !important;
}

a.bg-gray:hover,
a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: gray !important;
}

.bg-darker-light {
  background-color: #cccccc !important;
}

a.bg-darker-light:hover,
a.bg-darker-light:focus,
button.bg-darker-light:hover,
button.bg-darker-light:focus {
  background-color: #b3b3b3 !important;
}

.bg-dark-red {
  background-color: #cc0000 !important;
}

a.bg-dark-red:hover,
a.bg-dark-red:focus,
button.bg-dark-red:hover,
button.bg-dark-red:focus {
  background-color: #990000 !important;
}

.bg-lighter-dark {
  background-color: #262626 !important;
}

a.bg-lighter-dark:hover,
a.bg-lighter-dark:focus,
button.bg-lighter-dark:hover,
button.bg-lighter-dark:focus {
  background-color: #0d0d0d !important;
}

.bg-green {
  background-color: #74b100 !important;
}

a.bg-green:hover,
a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #537e00 !important;
}

.bg-gray-01 {
  background-color: #e8edef !important;
}

a.bg-gray-01:hover,
a.bg-gray-01:focus,
button.bg-gray-01:hover,
button.bg-gray-01:focus {
  background-color: #cad5da !important;
}

.bg-gray-02 {
  background-color: #f5f5f5 !important;
}

a.bg-gray-02:hover,
a.bg-gray-02:focus,
button.bg-gray-02:hover,
button.bg-gray-02:focus {
  background-color: gainsboro !important;
}

.bg-gray-03 {
  background-color: #eeeeee !important;
}

a.bg-gray-03:hover,
a.bg-gray-03:focus,
button.bg-gray-03:hover,
button.bg-gray-03:focus {
  background-color: #d5d5d5 !important;
}

.bg-gray-04 {
  background-color: #eef1f2 !important;
}

a.bg-gray-04:hover,
a.bg-gray-04:focus,
button.bg-gray-04:hover,
button.bg-gray-04:focus {
  background-color: #d1d9dc !important;
}

.bg-gray-05 {
  background-color: #ecf0f1 !important;
}

a.bg-gray-05:hover,
a.bg-gray-05:focus,
button.bg-gray-05:hover,
button.bg-gray-05:focus {
  background-color: #cfd9db !important;
}

.bg-gray-06 {
  background-color: #f0f2f3 !important;
}

a.bg-gray-06:hover,
a.bg-gray-06:focus,
button.bg-gray-06:hover,
button.bg-gray-06:focus {
  background-color: #d4d9dc !important;
}

.bg-gradient-primary {
  background: #ff5a5f -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff7377),
      to(#ff5a5f)
    ) repeat-x !important;
  background: #ff5a5f linear-gradient(180deg, #ff7377, #ff5a5f) repeat-x !important;
}

.bg-gradient-secondary {
  background: #666666 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#7d7d7d),
      to(#666666)
    ) repeat-x !important;
  background: #666666 linear-gradient(180deg, #7d7d7d, #666666) repeat-x !important;
}

.bg-gradient-success {
  background: #67981a -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#7ea73c),
      to(#67981a)
    ) repeat-x !important;
  background: #67981a linear-gradient(180deg, #7ea73c, #67981a) repeat-x !important;
}

.bg-gradient-info {
  background: #3f93f3 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#5ca3f5),
      to(#3f93f3)
    ) repeat-x !important;
  background: #3f93f3 linear-gradient(180deg, #5ca3f5, #3f93f3) repeat-x !important;
}

.bg-gradient-warning {
  background: #ffba00 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ffc426),
      to(#ffba00)
    ) repeat-x !important;
  background: #ffba00 linear-gradient(180deg, #ffc426, #ffba00) repeat-x !important;
}

.bg-gradient-danger {
  background: #ff0000 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff2626),
      to(#ff0000)
    ) repeat-x !important;
  background: #ff0000 linear-gradient(180deg, #ff2626, #ff0000) repeat-x !important;
}

.bg-gradient-light {
  background: #f0f0f0 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f2f2f2),
      to(#f0f0f0)
    ) repeat-x !important;
  background: #f0f0f0 linear-gradient(180deg, #f2f2f2, #f0f0f0) repeat-x !important;
}

.bg-gradient-dark {
  background: #000 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#262626),
      to(#000)
    ) repeat-x !important;
  background: #000 linear-gradient(180deg, #262626, #000) repeat-x !important;
}

.bg-gradient-white {
  background: #fff -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(white),
      to(#fff)
    ) repeat-x !important;
  background: #fff linear-gradient(180deg, white, #fff) repeat-x !important;
}

.bg-gradient-gray {
  background: #999999 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#a8a8a8),
      to(#999999)
    ) repeat-x !important;
  background: #999999 linear-gradient(180deg, #a8a8a8, #999999) repeat-x !important;
}

.bg-gradient-darker-light {
  background: #cccccc -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#d4d4d4),
      to(#cccccc)
    ) repeat-x !important;
  background: #cccccc linear-gradient(180deg, #d4d4d4, #cccccc) repeat-x !important;
}

.bg-gradient-dark-red {
  background: #cc0000 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#d42626),
      to(#cc0000)
    ) repeat-x !important;
  background: #cc0000 linear-gradient(180deg, #d42626, #cc0000) repeat-x !important;
}

.bg-gradient-lighter-dark {
  background: #262626 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#474747),
      to(#262626)
    ) repeat-x !important;
  background: #262626 linear-gradient(180deg, #474747, #262626) repeat-x !important;
}

.bg-gradient-green {
  background: #74b100 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#89bd26),
      to(#74b100)
    ) repeat-x !important;
  background: #74b100 linear-gradient(180deg, #89bd26, #74b100) repeat-x !important;
}

.bg-gradient-gray-01 {
  background: #e8edef -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ebf0f1),
      to(#e8edef)
    ) repeat-x !important;
  background: #e8edef linear-gradient(180deg, #ebf0f1, #e8edef) repeat-x !important;
}

.bg-gradient-gray-02 {
  background: #f5f5f5 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f7f7f7),
      to(#f5f5f5)
    ) repeat-x !important;
  background: #f5f5f5 linear-gradient(180deg, #f7f7f7, #f5f5f5) repeat-x !important;
}

.bg-gradient-gray-03 {
  background: #eeeeee -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f1f1f1),
      to(#eeeeee)
    ) repeat-x !important;
  background: #eeeeee linear-gradient(180deg, #f1f1f1, #eeeeee) repeat-x !important;
}

.bg-gradient-gray-04 {
  background: #eef1f2 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f1f3f4),
      to(#eef1f2)
    ) repeat-x !important;
  background: #eef1f2 linear-gradient(180deg, #f1f3f4, #eef1f2) repeat-x !important;
}

.bg-gradient-gray-05 {
  background: #ecf0f1 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#eff2f3),
      to(#ecf0f1)
    ) repeat-x !important;
  background: #ecf0f1 linear-gradient(180deg, #eff2f3, #ecf0f1) repeat-x !important;
}

.bg-gradient-gray-06 {
  background: #f0f2f3 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f2f4f5),
      to(#f0f2f3)
    ) repeat-x !important;
  background: #f0f2f3 linear-gradient(180deg, #f2f4f5, #f0f2f3) repeat-x !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ff5a5f !important;
}

.border-secondary {
  border-color: #666666 !important;
}

.border-success {
  border-color: #67981a !important;
}

.border-info {
  border-color: #3f93f3 !important;
}

.border-warning {
  border-color: #ffba00 !important;
}

.border-danger {
  border-color: #ff0000 !important;
}

.border-light {
  border-color: #f0f0f0 !important;
}

.border-dark {
  border-color: #000 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-gray {
  border-color: #999999 !important;
}

.border-darker-light {
  border-color: #cccccc !important;
}

.border-dark-red {
  border-color: #cc0000 !important;
}

.border-lighter-dark {
  border-color: #262626 !important;
}

.border-green {
  border-color: #74b100 !important;
}

.border-gray-01 {
  border-color: #e8edef !important;
}

.border-gray-02 {
  border-color: #f5f5f5 !important;
}

.border-gray-03 {
  border-color: #eeeeee !important;
}

.border-gray-04 {
  border-color: #eef1f2 !important;
}

.border-gray-05 {
  border-color: #ecf0f1 !important;
}

.border-gray-06 {
  border-color: #f0f2f3 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 2px !important;
}

.rounded {
  border-radius: 3px !important;
}

.rounded-top {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.rounded-right {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-left {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-lg {
  border-radius: 3px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix {
  content: "";
  display: block;
  clear: bottom;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.3125rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.3125rem !important;
}

.mb-1i,
.my-1 {
  margin-bottom: 0.3125rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.3125rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.625rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.625rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.625rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.625rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.25rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.25rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.25rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.25rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 1.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 1.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 1.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 1.5rem !important;
}

.m-6 {
  margin: 1.875rem !important;
}

.mt-6,
.my-6 {
  margin-top: 1.875rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 1.875rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 1.875rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 1.875rem !important;
}

.m-7 {
  margin: 2.5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 2.5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 2.5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 2.5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 2.5rem !important;
}

.m-8 {
  margin: 3.125rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.125rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.125rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.125rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.125rem !important;
}

.m-9 {
  margin: 4.375rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4.375rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4.375rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4.375rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4.375rem !important;
}

.m-10 {
  margin: 5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 5rem !important;
}

.m-11 {
  margin: 5.625rem !important;
}

.mt-11,
.my-11 {
  margin-top: 5.625rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 5.625rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 5.625rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 5.625rem !important;
}

.m-12 {
  margin: 6.25rem !important;
}

.mt-12,
.my-12 {
  margin-top: 6.25rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 6.25rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 6.25rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 6.25rem !important;
}

.m-13 {
  margin: 7.5rem !important;
}

.mt-13,
.my-13 {
  margin-top: 7.5rem !important;
}

.mr-13,
.mx-13 {
  margin-right: 7.5rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 7.5rem !important;
}

.ml-13,
.mx-13 {
  margin-left: 7.5rem !important;
}

.m-14 {
  margin: 9.375rem !important;
}

.mt-14,
.my-14 {
  margin-top: 9.375rem !important;
}

.mr-14,
.mx-14 {
  margin-right: 9.375rem !important;
}

.mb-14,
.my-14 {
  margin-bottom: 9.375rem !important;
}

.ml-14,
.mx-14 {
  margin-left: 9.375rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.3125rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.3125rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.3125rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.3125rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.625rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.625rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.625rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.625rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.25rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.25rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.25rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.25rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 1.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 1.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 1.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 1.5rem !important;
}

.p-6 {
  padding: 1.875rem !important;
}

.pt-6,
.py-6 {
  padding-top: 1.875rem !important;
}

.pr-6,
.px-6 {
  padding-right: 1.875rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 1.875rem !important;
}

.pl-6,
.px-6 {
  padding-left: 1.875rem !important;
}

.p-7 {
  padding: 2.5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 2.5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 2.5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 2.5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 2.5rem !important;
}

.p-8 {
  padding: 3.125rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.125rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3.125rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.125rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3.125rem !important;
}

.p-9 {
  padding: 4.375rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4.375rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4.375rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4.375rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4.375rem !important;
}

.p-10 {
  padding: 5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 5rem !important;
}

.p-11 {
  padding: 5.625rem !important;
}

.pt-11,
.py-11 {
  padding-top: 5.625rem !important;
}

.pr-11,
.px-11 {
  padding-right: 5.625rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 5.625rem !important;
}

.pl-11,
.px-11 {
  padding-left: 5.625rem !important;
}

.p-12 {
  padding: 6.25rem !important;
}

.pt-12,
.py-12 {
  padding-top: 6.25rem !important;
}

.pr-12,
.px-12 {
  padding-right: 6.25rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 6.25rem !important;
}

.pl-12,
.px-12 {
  padding-left: 6.25rem !important;
}

.p-13 {
  padding: 7.5rem !important;
}

.pt-13,
.py-13 {
  padding-top: 7.5rem !important;
}

.pr-13,
.px-13 {
  padding-right: 7.5rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 7.5rem !important;
}

.pl-13,
.px-13 {
  padding-left: 7.5rem !important;
}

.p-14 {
  padding: 9.375rem !important;
}

.pt-14,
.py-14 {
  padding-top: 9.375rem !important;
}

.pr-14,
.px-14 {
  padding-right: 9.375rem !important;
}

.pb-14,
.py-14 {
  padding-bottom: 9.375rem !important;
}

.pl-14,
.px-14 {
  padding-left: 9.375rem !important;
}

.m-n1 {
  margin: -0.3125rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.3125rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.3125rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.3125rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.3125rem !important;
}

.m-n2 {
  margin: -0.625rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.625rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.625rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.625rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.625rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.25rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.25rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.25rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.25rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.25rem !important;
}

.m-n5 {
  margin: -1.5rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -1.5rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -1.5rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -1.5rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -1.5rem !important;
}

.m-n6 {
  margin: -1.875rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -1.875rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -1.875rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -1.875rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -1.875rem !important;
}

.m-n7 {
  margin: -2.5rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -2.5rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -2.5rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -2.5rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -2.5rem !important;
}

.m-n8 {
  margin: -3.125rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -3.125rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -3.125rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -3.125rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -3.125rem !important;
}

.m-n9 {
  margin: -4.375rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -4.375rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -4.375rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -4.375rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -4.375rem !important;
}

.m-n10 {
  margin: -5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -5rem !important;
}

.m-n11 {
  margin: -5.625rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -5.625rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -5.625rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -5.625rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -5.625rem !important;
}

.m-n12 {
  margin: -6.25rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -6.25rem !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -6.25rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -6.25rem !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -6.25rem !important;
}

.m-n13 {
  margin: -7.5rem !important;
}

.mt-n13,
.my-n13 {
  margin-top: -7.5rem !important;
}

.mr-n13,
.mx-n13 {
  margin-right: -7.5rem !important;
}

.mb-n13,
.my-n13 {
  margin-bottom: -7.5rem !important;
}

.ml-n13,
.mx-n13 {
  margin-left: -7.5rem !important;
}

.m-n14 {
  margin: -9.375rem !important;
}

.mt-n14,
.my-n14 {
  margin-top: -9.375rem !important;
}

.mr-n14,
.mx-n14 {
  margin-right: -9.375rem !important;
}

.mb-n14,
.my-n14 {
  margin-bottom: -9.375rem !important;
}

.ml-n14,
.mx-n14 {
  margin-left: -9.375rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.3125rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.3125rem !important;
  }
  .m-sm-2 {
    margin: 0.625rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.625rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.625rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.625rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.25rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.25rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.25rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.25rem !important;
  }
  .m-sm-5 {
    margin: 1.5rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1.5rem !important;
  }
  .m-sm-6 {
    margin: 1.875rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 1.875rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 1.875rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 1.875rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 1.875rem !important;
  }
  .m-sm-7 {
    margin: 2.5rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 2.5rem !important;
  }
  .m-sm-8 {
    margin: 3.125rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.125rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.125rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.125rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.125rem !important;
  }
  .m-sm-9 {
    margin: 4.375rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4.375rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4.375rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4.375rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4.375rem !important;
  }
  .m-sm-10 {
    margin: 5rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 5rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 5rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 5rem !important;
  }
  .m-sm-11 {
    margin: 5.625rem !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 5.625rem !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 5.625rem !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 5.625rem !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 5.625rem !important;
  }
  .m-sm-12 {
    margin: 6.25rem !important;
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 6.25rem !important;
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 6.25rem !important;
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 6.25rem !important;
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 6.25rem !important;
  }
  .m-sm-13 {
    margin: 7.5rem !important;
  }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 7.5rem !important;
  }
  .m-sm-14 {
    margin: 9.375rem !important;
  }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 9.375rem !important;
  }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 9.375rem !important;
  }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 9.375rem !important;
  }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 9.375rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.3125rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.3125rem !important;
  }
  .p-sm-2 {
    padding: 0.625rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.625rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.625rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.625rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.25rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.25rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.25rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.25rem !important;
  }
  .p-sm-5 {
    padding: 1.5rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1.5rem !important;
  }
  .p-sm-6 {
    padding: 1.875rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 1.875rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 1.875rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 1.875rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 1.875rem !important;
  }
  .p-sm-7 {
    padding: 2.5rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 2.5rem !important;
  }
  .p-sm-8 {
    padding: 3.125rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.125rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.125rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.125rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.125rem !important;
  }
  .p-sm-9 {
    padding: 4.375rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4.375rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4.375rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4.375rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4.375rem !important;
  }
  .p-sm-10 {
    padding: 5rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 5rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 5rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 5rem !important;
  }
  .p-sm-11 {
    padding: 5.625rem !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 5.625rem !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 5.625rem !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 5.625rem !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 5.625rem !important;
  }
  .p-sm-12 {
    padding: 6.25rem !important;
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 6.25rem !important;
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 6.25rem !important;
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 6.25rem !important;
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 6.25rem !important;
  }
  .p-sm-13 {
    padding: 7.5rem !important;
  }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 7.5rem !important;
  }
  .p-sm-14 {
    padding: 9.375rem !important;
  }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 9.375rem !important;
  }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 9.375rem !important;
  }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 9.375rem !important;
  }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 9.375rem !important;
  }
  .m-sm-n1 {
    margin: -0.3125rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.3125rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.3125rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.3125rem !important;
  }
  .m-sm-n2 {
    margin: -0.625rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.625rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.625rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.625rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.625rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.25rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.25rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.25rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.25rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.25rem !important;
  }
  .m-sm-n5 {
    margin: -1.5rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n6 {
    margin: -1.875rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -1.875rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -1.875rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -1.875rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -1.875rem !important;
  }
  .m-sm-n7 {
    margin: -2.5rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n8 {
    margin: -3.125rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -3.125rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -3.125rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -3.125rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -3.125rem !important;
  }
  .m-sm-n9 {
    margin: -4.375rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -4.375rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -4.375rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -4.375rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -4.375rem !important;
  }
  .m-sm-n10 {
    margin: -5rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -5rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -5rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -5rem !important;
  }
  .m-sm-n11 {
    margin: -5.625rem !important;
  }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -5.625rem !important;
  }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -5.625rem !important;
  }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -5.625rem !important;
  }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -5.625rem !important;
  }
  .m-sm-n12 {
    margin: -6.25rem !important;
  }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -6.25rem !important;
  }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -6.25rem !important;
  }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -6.25rem !important;
  }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -6.25rem !important;
  }
  .m-sm-n13 {
    margin: -7.5rem !important;
  }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -7.5rem !important;
  }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -7.5rem !important;
  }
  .m-sm-n14 {
    margin: -9.375rem !important;
  }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -9.375rem !important;
  }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -9.375rem !important;
  }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -9.375rem !important;
  }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -9.375rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.3125rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.3125rem !important;
  }
  .m-md-2 {
    margin: 0.625rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.625rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.625rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.625rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.25rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.25rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.25rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.25rem !important;
  }
  .m-md-5 {
    margin: 1.5rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.5rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1.5rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1.5rem !important;
  }
  .m-md-6 {
    margin: 1.875rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 1.875rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 1.875rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 1.875rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 1.875rem !important;
  }
  .m-md-7 {
    margin: 2.5rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 2.5rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 2.5rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 2.5rem !important;
  }
  .m-md-8 {
    margin: 3.125rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.125rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.125rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.125rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.125rem !important;
  }
  .m-md-9 {
    margin: 4.375rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4.375rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4.375rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4.375rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4.375rem !important;
  }
  .m-md-10 {
    margin: 5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 5rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 5rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 5rem !important;
  }
  .m-md-11 {
    margin: 5.625rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 5.625rem !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 5.625rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 5.625rem !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 5.625rem !important;
  }
  .m-md-12 {
    margin: 6.25rem !important;
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 6.25rem !important;
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 6.25rem !important;
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 6.25rem !important;
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 6.25rem !important;
  }
  .m-md-13 {
    margin: 7.5rem !important;
  }
  .mt-md-13,
  .my-md-13 {
    margin-top: 7.5rem !important;
  }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 7.5rem !important;
  }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 7.5rem !important;
  }
  .m-md-14 {
    margin: 9.375rem !important;
  }
  .mt-md-14,
  .my-md-14 {
    margin-top: 9.375rem !important;
  }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 9.375rem !important;
  }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 9.375rem !important;
  }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 9.375rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.3125rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.3125rem !important;
  }
  .p-md-2 {
    padding: 0.625rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.625rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.625rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.625rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.25rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.25rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.25rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.25rem !important;
  }
  .p-md-5 {
    padding: 1.5rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.5rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1.5rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1.5rem !important;
  }
  .p-md-6 {
    padding: 1.875rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 1.875rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 1.875rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 1.875rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 1.875rem !important;
  }
  .p-md-7 {
    padding: 2.5rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 2.5rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 2.5rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 2.5rem !important;
  }
  .p-md-8 {
    padding: 3.125rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.125rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.125rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.125rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.125rem !important;
  }
  .p-md-9 {
    padding: 4.375rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4.375rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4.375rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4.375rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4.375rem !important;
  }
  .p-md-10 {
    padding: 5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 5rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 5rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 5rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 5rem !important;
  }
  .p-md-11 {
    padding: 5.625rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 5.625rem !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 5.625rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 5.625rem !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 5.625rem !important;
  }
  .p-md-12 {
    padding: 6.25rem !important;
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 6.25rem !important;
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 6.25rem !important;
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 6.25rem !important;
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 6.25rem !important;
  }
  .p-md-13 {
    padding: 7.5rem !important;
  }
  .pt-md-13,
  .py-md-13 {
    padding-top: 7.5rem !important;
  }
  .pr-md-13,
  .px-md-13 {
    padding-right: 7.5rem !important;
  }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-13,
  .px-md-13 {
    padding-left: 7.5rem !important;
  }
  .p-md-14 {
    padding: 9.375rem !important;
  }
  .pt-md-14,
  .py-md-14 {
    padding-top: 9.375rem !important;
  }
  .pr-md-14,
  .px-md-14 {
    padding-right: 9.375rem !important;
  }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 9.375rem !important;
  }
  .pl-md-14,
  .px-md-14 {
    padding-left: 9.375rem !important;
  }
  .m-md-n1 {
    margin: -0.3125rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.3125rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.3125rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.3125rem !important;
  }
  .m-md-n2 {
    margin: -0.625rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.625rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.625rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.625rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.625rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.25rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.25rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.25rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.25rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.25rem !important;
  }
  .m-md-n5 {
    margin: -1.5rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -1.5rem !important;
  }
  .m-md-n6 {
    margin: -1.875rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -1.875rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -1.875rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -1.875rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -1.875rem !important;
  }
  .m-md-n7 {
    margin: -2.5rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -2.5rem !important;
  }
  .m-md-n8 {
    margin: -3.125rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3.125rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -3.125rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3.125rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -3.125rem !important;
  }
  .m-md-n9 {
    margin: -4.375rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4.375rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -4.375rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4.375rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -4.375rem !important;
  }
  .m-md-n10 {
    margin: -5rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -5rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -5rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -5rem !important;
  }
  .m-md-n11 {
    margin: -5.625rem !important;
  }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -5.625rem !important;
  }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -5.625rem !important;
  }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -5.625rem !important;
  }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -5.625rem !important;
  }
  .m-md-n12 {
    margin: -6.25rem !important;
  }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -6.25rem !important;
  }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -6.25rem !important;
  }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -6.25rem !important;
  }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -6.25rem !important;
  }
  .m-md-n13 {
    margin: -7.5rem !important;
  }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -7.5rem !important;
  }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -7.5rem !important;
  }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -7.5rem !important;
  }
  .m-md-n14 {
    margin: -9.375rem !important;
  }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -9.375rem !important;
  }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -9.375rem !important;
  }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -9.375rem !important;
  }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -9.375rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.3125rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.3125rem !important;
  }
  .m-lg-2 {
    margin: 0.625rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.625rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.625rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.625rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.25rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.25rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.25rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.25rem !important;
  }
  .m-lg-5 {
    margin: 1.5rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1.5rem !important;
  }
  .m-lg-6 {
    margin: 1.875rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 1.875rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 1.875rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 1.875rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 1.875rem !important;
  }
  .m-lg-7 {
    margin: 2.5rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 2.5rem !important;
  }
  .m-lg-8 {
    margin: 3.125rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.125rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.125rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.125rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.125rem !important;
  }
  .m-lg-9 {
    margin: 4.375rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4.375rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4.375rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4.375rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4.375rem !important;
  }
  .m-lg-10 {
    margin: 5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 5rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 5rem !important;
  }
  .m-lg-11 {
    margin: 5.625rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 5.625rem !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 5.625rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 5.625rem !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 5.625rem !important;
  }
  .m-lg-12 {
    margin: 6.25rem !important;
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 6.25rem !important;
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 6.25rem !important;
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 6.25rem !important;
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 6.25rem !important;
  }
  .m-lg-13 {
    margin: 7.5rem !important;
  }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 7.5rem !important;
  }
  .m-lg-14 {
    margin: 9.375rem !important;
  }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 9.375rem !important;
  }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 9.375rem !important;
  }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 9.375rem !important;
  }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 9.375rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.3125rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.3125rem !important;
  }
  .p-lg-2 {
    padding: 0.625rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.625rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.625rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.625rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.25rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.25rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.25rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.25rem !important;
  }
  .p-lg-5 {
    padding: 1.5rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1.5rem !important;
  }
  .p-lg-6 {
    padding: 1.875rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 1.875rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 1.875rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 1.875rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 1.875rem !important;
  }
  .p-lg-7 {
    padding: 2.5rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 2.5rem !important;
  }
  .p-lg-8 {
    padding: 3.125rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.125rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.125rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.125rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.125rem !important;
  }
  .p-lg-9 {
    padding: 4.375rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4.375rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4.375rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4.375rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4.375rem !important;
  }
  .p-lg-10 {
    padding: 5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 5rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 5rem !important;
  }
  .p-lg-11 {
    padding: 5.625rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 5.625rem !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 5.625rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 5.625rem !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 5.625rem !important;
  }
  .p-lg-12 {
    padding: 6.25rem !important;
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 6.25rem !important;
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 6.25rem !important;
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 6.25rem !important;
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 6.25rem !important;
  }
  .p-lg-13 {
    padding: 7.5rem !important;
  }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 7.5rem !important;
  }
  .p-lg-14 {
    padding: 9.375rem !important;
  }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 9.375rem !important;
  }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 9.375rem !important;
  }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 9.375rem !important;
  }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 9.375rem !important;
  }
  .m-lg-n1 {
    margin: -0.3125rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.3125rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.3125rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.3125rem !important;
  }
  .m-lg-n2 {
    margin: -0.625rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.625rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.625rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.625rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.625rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.25rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.25rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.25rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.25rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.25rem !important;
  }
  .m-lg-n5 {
    margin: -1.5rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n6 {
    margin: -1.875rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -1.875rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -1.875rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -1.875rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -1.875rem !important;
  }
  .m-lg-n7 {
    margin: -2.5rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n8 {
    margin: -3.125rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3.125rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -3.125rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3.125rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -3.125rem !important;
  }
  .m-lg-n9 {
    margin: -4.375rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4.375rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -4.375rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4.375rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -4.375rem !important;
  }
  .m-lg-n10 {
    margin: -5rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -5rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -5rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -5rem !important;
  }
  .m-lg-n11 {
    margin: -5.625rem !important;
  }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -5.625rem !important;
  }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -5.625rem !important;
  }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -5.625rem !important;
  }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -5.625rem !important;
  }
  .m-lg-n12 {
    margin: -6.25rem !important;
  }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -6.25rem !important;
  }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -6.25rem !important;
  }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -6.25rem !important;
  }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -6.25rem !important;
  }
  .m-lg-n13 {
    margin: -7.5rem !important;
  }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -7.5rem !important;
  }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -7.5rem !important;
  }
  .m-lg-n14 {
    margin: -9.375rem !important;
  }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -9.375rem !important;
  }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -9.375rem !important;
  }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -9.375rem !important;
  }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -9.375rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.3125rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.3125rem !important;
  }
  .m-xl-2 {
    margin: 0.625rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.625rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.625rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.625rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.25rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.25rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.25rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.25rem !important;
  }
  .m-xl-5 {
    margin: 1.5rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1.5rem !important;
  }
  .m-xl-6 {
    margin: 1.875rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 1.875rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 1.875rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 1.875rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 1.875rem !important;
  }
  .m-xl-7 {
    margin: 2.5rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 2.5rem !important;
  }
  .m-xl-8 {
    margin: 3.125rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.125rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.125rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.125rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.125rem !important;
  }
  .m-xl-9 {
    margin: 4.375rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4.375rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4.375rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4.375rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4.375rem !important;
  }
  .m-xl-10 {
    margin: 5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 5rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 5rem !important;
  }
  .m-xl-11 {
    margin: 5.625rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 5.625rem !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 5.625rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 5.625rem !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 5.625rem !important;
  }
  .m-xl-12 {
    margin: 6.25rem !important;
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 6.25rem !important;
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 6.25rem !important;
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 6.25rem !important;
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 6.25rem !important;
  }
  .m-xl-13 {
    margin: 7.5rem !important;
  }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 7.5rem !important;
  }
  .m-xl-14 {
    margin: 9.375rem !important;
  }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 9.375rem !important;
  }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 9.375rem !important;
  }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 9.375rem !important;
  }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 9.375rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.3125rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.3125rem !important;
  }
  .p-xl-2 {
    padding: 0.625rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.625rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.625rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.625rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.25rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.25rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.25rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.25rem !important;
  }
  .p-xl-5 {
    padding: 1.5rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.5rem !important;
  }
  .p-xl-6 {
    padding: 1.875rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 1.875rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 1.875rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 1.875rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 1.875rem !important;
  }
  .p-xl-7 {
    padding: 2.5rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 2.5rem !important;
  }
  .p-xl-8 {
    padding: 3.125rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.125rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.125rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.125rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.125rem !important;
  }
  .p-xl-9 {
    padding: 4.375rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4.375rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4.375rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4.375rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4.375rem !important;
  }
  .p-xl-10 {
    padding: 5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 5rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 5rem !important;
  }
  .p-xl-11 {
    padding: 5.625rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 5.625rem !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 5.625rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 5.625rem !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 5.625rem !important;
  }
  .p-xl-12 {
    padding: 6.25rem !important;
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 6.25rem !important;
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 6.25rem !important;
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 6.25rem !important;
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 6.25rem !important;
  }
  .p-xl-13 {
    padding: 7.5rem !important;
  }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 7.5rem !important;
  }
  .p-xl-14 {
    padding: 9.375rem !important;
  }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 9.375rem !important;
  }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 9.375rem !important;
  }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 9.375rem !important;
  }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 9.375rem !important;
  }
  .m-xl-n1 {
    margin: -0.3125rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.3125rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.3125rem !important;
  }
  .m-xl-n2 {
    margin: -0.625rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.625rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.625rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.625rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.25rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.25rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.25rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.25rem !important;
  }
  .m-xl-n5 {
    margin: -1.5rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n6 {
    margin: -1.875rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -1.875rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -1.875rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -1.875rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -1.875rem !important;
  }
  .m-xl-n7 {
    margin: -2.5rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n8 {
    margin: -3.125rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3.125rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -3.125rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3.125rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -3.125rem !important;
  }
  .m-xl-n9 {
    margin: -4.375rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4.375rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -4.375rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4.375rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -4.375rem !important;
  }
  .m-xl-n10 {
    margin: -5rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -5rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -5rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -5rem !important;
  }
  .m-xl-n11 {
    margin: -5.625rem !important;
  }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -5.625rem !important;
  }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -5.625rem !important;
  }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -5.625rem !important;
  }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -5.625rem !important;
  }
  .m-xl-n12 {
    margin: -6.25rem !important;
  }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -6.25rem !important;
  }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -6.25rem !important;
  }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -6.25rem !important;
  }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -6.25rem !important;
  }
  .m-xl-n13 {
    margin: -7.5rem !important;
  }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -7.5rem !important;
  }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -7.5rem !important;
  }
  .m-xl-n14 {
    margin: -9.375rem !important;
  }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -9.375rem !important;
  }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -9.375rem !important;
  }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -9.375rem !important;
  }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -9.375rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: "Work Sans", sans-serif !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #ff5a5f !important;
}

.text-secondary {
  color: #666666 !important;
}

.text-success {
  color: #67981a !important;
}

.text-info {
  color: #3f93f3 !important;
}

.text-warning {
  color: #ffba00 !important;
}

.text-danger {
  color: #ff0000 !important;
}

.text-light {
  color: #f0f0f0 !important;
}

.text-dark {
  color: #000 !important;
}

.text-white {
  color: #fff !important;
}

.text-gray {
  color: #999999 !important;
}

.text-darker-light {
  color: #cccccc !important;
}

.text-dark-red {
  color: #cc0000 !important;
}

.text-lighter-dark {
  color: #262626 !important;
}

.text-green {
  color: #74b100 !important;
}

.text-gray-01 {
  color: #e8edef !important;
}

.text-gray-02 {
  color: #f5f5f5 !important;
}

.text-gray-03 {
  color: #eeeeee !important;
}

.text-gray-04 {
  color: #eef1f2 !important;
}

.text-gray-05 {
  color: #ecf0f1 !important;
}

.text-gray-06 {
  color: #f0f2f3 !important;
}

.text-body {
  color: #666666 !important;
}

.text-muted {
  color: #999999 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-link {
  color: #0099cc !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.link-hover-primary-primary {
  color: #ff5a5f;
}
.link-hover-primary-primary:hover,
.link-hover-primary-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-secondary-primary {
  color: #666666;
}
.link-hover-secondary-primary:hover,
.link-hover-secondary-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-success-primary {
  color: #67981a;
}
.link-hover-success-primary:hover,
.link-hover-success-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-info-primary {
  color: #3f93f3;
}
.link-hover-info-primary:hover,
.link-hover-info-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-warning-primary {
  color: #ffba00;
}
.link-hover-warning-primary:hover,
.link-hover-warning-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-danger-primary {
  color: #ff0000;
}
.link-hover-danger-primary:hover,
.link-hover-danger-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-light-primary {
  color: #f0f0f0;
}
.link-hover-light-primary:hover,
.link-hover-light-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-dark-primary {
  color: #000;
}
.link-hover-dark-primary:hover,
.link-hover-dark-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-white-primary {
  color: #fff;
}
.link-hover-white-primary:hover,
.link-hover-white-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-gray-primary {
  color: #999999;
}
.link-hover-gray-primary:hover,
.link-hover-gray-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-darker-light-primary {
  color: #cccccc;
}
.link-hover-darker-light-primary:hover,
.link-hover-darker-light-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-dark-red-primary {
  color: #cc0000;
}
.link-hover-dark-red-primary:hover,
.link-hover-dark-red-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-lighter-dark-primary {
  color: #262626;
}
.link-hover-lighter-dark-primary:hover,
.link-hover-lighter-dark-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-green-primary {
  color: #74b100;
}
.link-hover-green-primary:hover,
.link-hover-green-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-gray-01-primary {
  color: #e8edef;
}
.link-hover-gray-01-primary:hover,
.link-hover-gray-01-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-gray-02-primary {
  color: #f5f5f5;
}
.link-hover-gray-02-primary:hover,
.link-hover-gray-02-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-gray-03-primary {
  color: #eeeeee;
}
.link-hover-gray-03-primary:hover,
.link-hover-gray-03-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-gray-04-primary {
  color: #eef1f2;
}
.link-hover-gray-04-primary:hover,
.link-hover-gray-04-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-gray-05-primary {
  color: #ecf0f1;
}
.link-hover-gray-05-primary:hover,
.link-hover-gray-05-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-gray-06-primary {
  color: #f0f2f3;
}
.link-hover-gray-06-primary:hover,
.link-hover-gray-06-primary:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-primary-dark {
  color: #ff5a5f;
}
.link-hover-primary-dark:hover,
.link-hover-primary-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-secondary-dark {
  color: #666666;
}
.link-hover-secondary-dark:hover,
.link-hover-secondary-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-success-dark {
  color: #67981a;
}
.link-hover-success-dark:hover,
.link-hover-success-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-info-dark {
  color: #3f93f3;
}
.link-hover-info-dark:hover,
.link-hover-info-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-warning-dark {
  color: #ffba00;
}
.link-hover-warning-dark:hover,
.link-hover-warning-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-danger-dark {
  color: #ff0000;
}
.link-hover-danger-dark:hover,
.link-hover-danger-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-light-dark {
  color: #f0f0f0;
}
.link-hover-light-dark:hover,
.link-hover-light-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-dark-dark {
  color: #000;
}
.link-hover-dark-dark:hover,
.link-hover-dark-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-white-dark {
  color: #fff;
}
.link-hover-white-dark:hover,
.link-hover-white-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-gray-dark {
  color: #999999;
}
.link-hover-gray-dark:hover,
.link-hover-gray-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-darker-light-dark {
  color: #cccccc;
}
.link-hover-darker-light-dark:hover,
.link-hover-darker-light-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-dark-red-dark {
  color: #cc0000;
}
.link-hover-dark-red-dark:hover,
.link-hover-dark-red-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-lighter-dark-dark {
  color: #262626;
}
.link-hover-lighter-dark-dark:hover,
.link-hover-lighter-dark-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-green-dark {
  color: #74b100;
}
.link-hover-green-dark:hover,
.link-hover-green-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-gray-01-dark {
  color: #e8edef;
}
.link-hover-gray-01-dark:hover,
.link-hover-gray-01-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-gray-02-dark {
  color: #f5f5f5;
}
.link-hover-gray-02-dark:hover,
.link-hover-gray-02-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-gray-03-dark {
  color: #eeeeee;
}
.link-hover-gray-03-dark:hover,
.link-hover-gray-03-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-gray-04-dark {
  color: #eef1f2;
}
.link-hover-gray-04-dark:hover,
.link-hover-gray-04-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-gray-05-dark {
  color: #ecf0f1;
}
.link-hover-gray-05-dark:hover,
.link-hover-gray-05-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-gray-06-dark {
  color: #f0f2f3;
}
.link-hover-gray-06-dark:hover,
.link-hover-gray-06-dark:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-primary-blue {
  color: #ff5a5f;
}
.link-hover-primary-blue:hover,
.link-hover-primary-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-secondary-blue {
  color: #666666;
}
.link-hover-secondary-blue:hover,
.link-hover-secondary-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-success-blue {
  color: #67981a;
}
.link-hover-success-blue:hover,
.link-hover-success-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-info-blue {
  color: #3f93f3;
}
.link-hover-info-blue:hover,
.link-hover-info-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-warning-blue {
  color: #ffba00;
}
.link-hover-warning-blue:hover,
.link-hover-warning-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-danger-blue {
  color: #ff0000;
}
.link-hover-danger-blue:hover,
.link-hover-danger-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-light-blue {
  color: #f0f0f0;
}
.link-hover-light-blue:hover,
.link-hover-light-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-dark-blue {
  color: #000;
}
.link-hover-dark-blue:hover,
.link-hover-dark-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-white-blue {
  color: #fff;
}
.link-hover-white-blue:hover,
.link-hover-white-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-gray-blue {
  color: #999999;
}
.link-hover-gray-blue:hover,
.link-hover-gray-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-darker-light-blue {
  color: #cccccc;
}
.link-hover-darker-light-blue:hover,
.link-hover-darker-light-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-dark-red-blue {
  color: #cc0000;
}
.link-hover-dark-red-blue:hover,
.link-hover-dark-red-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-lighter-dark-blue {
  color: #262626;
}
.link-hover-lighter-dark-blue:hover,
.link-hover-lighter-dark-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-green-blue {
  color: #74b100;
}
.link-hover-green-blue:hover,
.link-hover-green-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-gray-01-blue {
  color: #e8edef;
}
.link-hover-gray-01-blue:hover,
.link-hover-gray-01-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-gray-02-blue {
  color: #f5f5f5;
}
.link-hover-gray-02-blue:hover,
.link-hover-gray-02-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-gray-03-blue {
  color: #eeeeee;
}
.link-hover-gray-03-blue:hover,
.link-hover-gray-03-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-gray-04-blue {
  color: #eef1f2;
}
.link-hover-gray-04-blue:hover,
.link-hover-gray-04-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-gray-05-blue {
  color: #ecf0f1;
}
.link-hover-gray-05-blue:hover,
.link-hover-gray-05-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-gray-06-blue {
  color: #f0f2f3;
}
.link-hover-gray-06-blue:hover,
.link-hover-gray-06-blue:focus {
  color: #0099cc !important;
  text-decoration: none;
}

.link-hover-primary-white {
  color: #ff5a5f;
}
.link-hover-primary-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-primary-white:focus {
  color: #ff5a5f !important;
  text-decoration: none;
}

.link-hover-secondary-white {
  color: #666666;
}
.link-hover-secondary-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-secondary-white:focus {
  color: #666666 !important;
  text-decoration: none;
}

.link-hover-success-white {
  color: #67981a;
}
.link-hover-success-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-success-white:focus {
  color: #67981a !important;
  text-decoration: none;
}

.link-hover-info-white {
  color: #3f93f3;
}
.link-hover-info-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-info-white:focus {
  color: #3f93f3 !important;
  text-decoration: none;
}

.link-hover-warning-white {
  color: #ffba00;
}
.link-hover-warning-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-warning-white:focus {
  color: #ffba00 !important;
  text-decoration: none;
}

.link-hover-danger-white {
  color: #ff0000;
}
.link-hover-danger-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-danger-white:focus {
  color: #ff0000 !important;
  text-decoration: none;
}

.link-hover-light-white {
  color: #f0f0f0;
}
.link-hover-light-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-light-white:focus {
  color: #f0f0f0 !important;
  text-decoration: none;
}

.link-hover-dark-white {
  color: #000;
}
.link-hover-dark-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-dark-white:focus {
  color: #000 !important;
  text-decoration: none;
}

.link-hover-white-white {
  color: #fff;
}
.link-hover-white-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-white-white:focus {
  color: #fff !important;
  text-decoration: none;
}

.link-hover-gray-white {
  color: #999999;
}
.link-hover-gray-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-gray-white:focus {
  color: #999999 !important;
  text-decoration: none;
}

.link-hover-darker-light-white {
  color: #cccccc;
}
.link-hover-darker-light-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-darker-light-white:focus {
  color: #cccccc !important;
  text-decoration: none;
}

.link-hover-dark-red-white {
  color: #cc0000;
}
.link-hover-dark-red-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-dark-red-white:focus {
  color: #cc0000 !important;
  text-decoration: none;
}

.link-hover-lighter-dark-white {
  color: #262626;
}
.link-hover-lighter-dark-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-lighter-dark-white:focus {
  color: #262626 !important;
  text-decoration: none;
}

.link-hover-green-white {
  color: #74b100;
}
.link-hover-green-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-green-white:focus {
  color: #74b100 !important;
  text-decoration: none;
}

.link-hover-gray-01-white {
  color: #e8edef;
}
.link-hover-gray-01-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-gray-01-white:focus {
  color: #e8edef !important;
  text-decoration: none;
}

.link-hover-gray-02-white {
  color: #f5f5f5;
}
.link-hover-gray-02-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-gray-02-white:focus {
  color: #f5f5f5 !important;
  text-decoration: none;
}

.link-hover-gray-03-white {
  color: #eeeeee;
}
.link-hover-gray-03-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-gray-03-white:focus {
  color: #eeeeee !important;
  text-decoration: none;
}

.link-hover-gray-04-white {
  color: #eef1f2;
}
.link-hover-gray-04-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-gray-04-white:focus {
  color: #eef1f2 !important;
  text-decoration: none;
}

.link-hover-gray-05-white {
  color: #ecf0f1;
}
.link-hover-gray-05-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-gray-05-white:focus {
  color: #ecf0f1 !important;
  text-decoration: none;
}

.link-hover-gray-06-white {
  color: #f0f2f3;
}
.link-hover-gray-06-white:hover {
  color: #fff !important;
  text-decoration: none;
}
.link-hover-gray-06-white:focus {
  color: #f0f2f3 !important;
  text-decoration: none;
}

.font-size-sm {
  font-size: 0.875rem !important;
}

.font-size-base {
  font-size: "Work Sans", sans-serif !important;
}

.font-size-md {
  font-size: 1rem !important;
}

.font-size-lg {
  font-size: 1.125rem !important;
}

.font-size-h1 {
  font-size: 3rem !important;
}

.font-size-h2 {
  font-size: 2.25rem !important;
}

.font-size-h3 {
  font-size: 1.875rem !important;
}

.font-size-h4 {
  font-size: 1.5rem !important;
}

.font-size-h5 {
  font-size: 1.25rem !important;
}

.font-size-h6 {
  font-size: 1.125rem !important;
}

@media (min-width: 576px) {
  .font-size-sm-sm {
    font-size: 0.875rem !important;
  }
  .font-size-sm-base {
    font-size: "Work Sans", sans-serif !important;
  }
  .font-size-sm-md {
    font-size: 1rem !important;
  }
  .font-size-sm-lg {
    font-size: 1.125rem !important;
  }
  .font-size-sm-h1 {
    font-size: 3rem !important;
  }
  .font-size-sm-h2 {
    font-size: 2.25rem !important;
  }
  .font-size-sm-h3 {
    font-size: 1.875rem !important;
  }
  .font-size-sm-h4 {
    font-size: 1.5rem !important;
  }
  .font-size-sm-h5 {
    font-size: 1.25rem !important;
  }
  .font-size-sm-h6 {
    font-size: 1.125rem !important;
  }
}

@media (min-width: 768px) {
  .font-size-md-sm {
    font-size: 0.875rem !important;
  }
  .font-size-md-base {
    font-size: "Work Sans", sans-serif !important;
  }
  .font-size-md-md {
    font-size: 1rem !important;
  }
  .font-size-md-lg {
    font-size: 1.125rem !important;
  }
  .font-size-md-h1 {
    font-size: 3rem !important;
  }
  .font-size-md-h2 {
    font-size: 2.25rem !important;
  }
  .font-size-md-h3 {
    font-size: 1.875rem !important;
  }
  .font-size-md-h4 {
    font-size: 1.5rem !important;
  }
  .font-size-md-h5 {
    font-size: 1.25rem !important;
  }
  .font-size-md-h6 {
    font-size: 1.125rem !important;
  }
}

@media (min-width: 992px) {
  .font-size-lg-sm {
    font-size: 0.875rem !important;
  }
  .font-size-lg-base {
    font-size: "Work Sans", sans-serif !important;
  }
  .font-size-lg-md {
    font-size: 1rem !important;
  }
  .font-size-lg-lg {
    font-size: 1.125rem !important;
  }
  .font-size-lg-h1 {
    font-size: 3rem !important;
  }
  .font-size-lg-h2 {
    font-size: 2.25rem !important;
  }
  .font-size-lg-h3 {
    font-size: 1.875rem !important;
  }
  .font-size-lg-h4 {
    font-size: 1.5rem !important;
  }
  .font-size-lg-h5 {
    font-size: 1.25rem !important;
  }
  .font-size-lg-h6 {
    font-size: 1.125rem !important;
  }
}

@media (min-width: 1200px) {
  .font-size-xl-sm {
    font-size: 0.875rem !important;
  }
  .font-size-xl-base {
    font-size: "Work Sans", sans-serif !important;
  }
  .font-size-xl-md {
    font-size: 1rem !important;
  }
  .font-size-xl-lg {
    font-size: 1.125rem !important;
  }
  .font-size-xl-h1 {
    font-size: 3rem !important;
  }
  .font-size-xl-h2 {
    font-size: 2.25rem !important;
  }
  .font-size-xl-h3 {
    font-size: 1.875rem !important;
  }
  .font-size-xl-h4 {
    font-size: 1.5rem !important;
  }
  .font-size-xl-h5 {
    font-size: 1.25rem !important;
  }
  .font-size-xl-h6 {
    font-size: 1.125rem !important;
  }
}

.letter-spacing-50 {
  letter-spacing: -0.05em !important;
}

.letter-spacing-35 {
  letter-spacing: -0.035em !important;
}

.letter-spacing-25 {
  letter-spacing: -0.025em !important;
}

.letter-spacing-10 {
  letter-spacing: -0.01em !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-11 {
  line-height: 1.1 !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

.lh-13 {
  line-height: 1.3 !important;
}

.lh-14 {
  line-height: 1.4 !important;
}

.lh-15 {
  line-height: 1.5 !important;
}

.lh-16 {
  line-height: 1.6 !important;
}

.lh-1625 {
  line-height: 1.625 !important;
}

.lh-18 {
  line-height: 1.8 !important;
}

.lh-19 {
  line-height: 1.9 !important;
}

.lh-base {
  line-height: 1.7 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.box-shadow-hover {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.box-shadow-hover:hover {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #cccccc;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/*--------------------------------------------------------------
  ## Initial
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ## Core Css
  --------------------------------------------------------------*/
.pb-42 {
  padding-bottom: 42px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.ml-35px {
  margin-left: 35px;
}

.bg-pattern-01 {
  background-image: url("../../assets/image/other/background-thedir-cover.jpg");
  background-size: cover;
  background-position: center;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #000 50%),
    linear-gradient(135deg, #000 50%, transparent 50%);
  background-position: calc(100% - 19px) calc(18px),
    calc(100% - 14px) calc(18px), 100% 0px;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: none;
  border-bottom: 1px dashed;
  border-color: #000;
  width: 100%;
}

select::-ms-expand {
  display: none;
}

.select-custom,
.arrows {
  position: relative;
  width: 100%;
}
.select-custom select,
.arrows select {
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 30px !important;
  position: relative;
  z-index: 10;
}
.select-custom::after,
.select-custom::before,
.arrows::after,
.arrows::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 14px;
  line-height: 2;
  position: absolute;
  right: 12px;
  color: #666666;
  z-index: 9;
}

.select-custom::after {
  content: "\f078";
  font-size: 1rem;
  line-height: 2;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.arrows::before {
  content: "\f077";
  top: 4px;
}

.arrows::after {
  content: "\f078";
  bottom: 4px;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: transparent;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  color: #000;
  top: 0;
  font-size: 16px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background: transparent;
  color: #000;
  border-color: #000;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: transparent;
  border-color: #000;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  background: #000;
}

.custom-radio .custom-control-label::after {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  top: 0.5rem;
  left: -20px;
}

svg.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  opacity: 1 !important;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
  border-width: 20px 20px 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  left: 50% !important;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  top: -9px !important;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-width: 8px 3px 0;
}

iframe {
  border: 0;
}

/*--------------------------------------------------------------
  ## Site layout style
  --------------------------------------------------------------*/
#site-wrapper {
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
}

#wrapper-content {
  padding-top: 117px;
  padding-bottom: 60px;
}
@media (max-width: 1199.98px) {
  #wrapper-content {
    padding-top: 83px;
  }
}

.full-banner .content-wrap {
  margin-top: -85px;
}

@media (min-width: 992px) {
  .container-1720 {
    max-width: 1750px;
  }
}

.row.gutter-tb-19 {
  margin-top: -19px;
  margin-bottom: -19px;
}
.row.gutter-tb-19 .col-12 {
  padding-top: 19px;
  padding-bottom: 19px;
}

@media (max-width: 1199.98px) {
  .page-two-column {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.page-two-column .page-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
@media (max-width: 1199.98px) {
  .page-two-column .page-content {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }
}

.page-two-column .sidebar {
  -ms-flex: 0 0 300px;
  -webkit-box-flex: 0;
  flex: 0 0 300px;
  max-width: 300px;
  margin-left: 70px;
}
@media (max-width: 1199.98px) {
  .page-two-column .sidebar {
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 100%;
    margin-left: 0;
  }
}

/*--------------------------------------------------------------
  ## Header
  --------------------------------------------------------------*/
.header-wrapper {
  font-family: "Work Sans", sans-serif;
  color: #000;
}

.header-light .header-wrapper {
  color: #fff;
}

.header-float {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.language .dropdown .dropdown-menu {
  margin: 0;
  min-width: 83px;
  border-radius: 0;
  padding-top: 14px;
  padding-bottom: 15px;
  border: none;
}
.language .dropdown .dropdown-menu .dropdown-item {
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #999999;
}
.language .dropdown .dropdown-menu .dropdown-item:hover {
  color: #000;
  background: 0;
}

.language .dropdown .dropdown-toggle {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.language .dropdown .dropdown-toggle:hover,
.language .dropdown .dropdown-toggle:active {
  text-decoration: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.language .dropdown .dropdown-toggle::after {
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  border: none !important;
  font-size: 12px;
  margin-left: 9px;
  font-weight: 400;
}

.header-customize-item {
  position: relative;
  line-height: 16px;
  font-size: 16px;
  font-weight: 600;
  height: 100%;
}
.header-customize-item .icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.header-customize-item.button {
  padding: 0 !important;
}
.header-customize-item .btn {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
}
.header-customize-item .btn i {
  font-size: 18px;
}

.header-customize-item .link {
  color: inherit;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
.header-customize-item .link:hover {
  color: #ff5a5f;
  text-decoration: none;
}

.header-customize-item + .header-customize-item {
  margin-left: 35px;
}

.hover-primary .nav-link:hover {
  color: #000 !important;
}

.mini-cart-icon .icon {
  stroke-width: 2;
}

.mini-cart-icon span {
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
  line-height: 1.6;
}

.header-hidden {
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.main-header.bg-gradient .header-wrapper {
  background-color: rgba(0, 0, 0, 0.3);
}

.main-header .navbar {
  padding: 0;
}

.main-header .navbar-brand img {
  max-height: 64px;
  -webkit-transition: max-height 0.5s;
  transition: max-height 0.5s;
}

.main-header .nav-link {
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
.main-header .nav-link span.caret {
  margin-left: auto;
  line-height: 1;
}

.main-header .nav-item {
  position: relative;
}

.main-header .sub-menu {
  display: none;
  list-style: none;
  padding: 0;
}
.main-header .sub-menu .nav-link {
  text-transform: capitalize;
  font-weight: 400;
}

.main-header .mobile-button-search,
.main-header .navbar-toggler {
  font-size: 24px;
  color: inherit;
}

.main-header .mobile-button-search:not(:last-child) {
  margin-right: 15px;
}

.main-header .mini-cart-icon {
  position: relative;
}
.main-header .mini-cart-icon .icon {
  margin-right: 0;
  padding: 2.5px 0;
  height: 25px;
}

.main-header .shopping-cart-popup {
  display: none;
  position: absolute;
  z-index: 999;
  top: 100%;
  right: 0;
  font-size: 1rem;
  width: 366px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #000;
  padding: 18px 20px 30px;
}
.main-header .shopping-cart-popup .media .image {
  width: 57px;
  height: 57px;
  max-width: 57px;
  max-height: 57px;
  min-width: 57px;
  min-height: 57px;
}
.main-header .shopping-cart-popup .btn i {
  font-size: 1rem;
}

.main-header .header-customize-item:hover .shopping-cart-popup {
  display: block;
}

.main-header .my-account-dropdown {
  position: relative;
}
.main-header .my-account-dropdown .image {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  margin-right: 13px;
}
.main-header .my-account-dropdown .image img {
  border-radius: 100%;
}
.main-header .my-account-dropdown .my-account-popup {
  position: absolute;
  top: 100%;
  left: 0;
  width: 164px;
  z-index: 999;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: none;
}
.main-header .my-account-dropdown .my-account-popup ul {
  padding: 20px 25px 25px;
  margin-bottom: 0;
}
.main-header .my-account-dropdown .my-account-popup ul a {
  color: #999999;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 16px;
  font-weight: 400;
  display: block;
  text-transform: capitalize;
  text-decoration: none;
}
.main-header .my-account-dropdown .my-account-popup ul a:hover {
  color: #000;
}
.main-header
  .my-account-dropdown
  .my-account-popup
  ul
  .item:not(:last-child)
  a {
  padding-bottom: 14px;
}

.main-header .header-customize-item:hover .my-account-popup {
  display: block;
}

.main-header .form-search {
  padding-right: 30px;
}
.main-header .form-search .input-group {
  position: static;
}
.main-header .form-search .form-control {
  padding-right: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-header .form-search-item {
  padding-left: 13px;
  position: relative;
  text-transform: capitalize;
}
.main-header .form-search-item .form-control {
  height: auto !important;
}
.main-header .form-search-item.what .form-control {
  width: 286px;
}
.main-header .form-search-item.where .form-control {
  width: 155px;
}

.main-header .button-search {
  display: none;
  margin-left: 35px;
}

.main-header.bg-white .header-wrapper {
  background: #fff;
}

.main-header.bg-white .sticky-area-wrap.sticky .sticky-area {
  -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);
}

.main-header.bg-white .toggle .icon {
  fill: #000;
  stroke: #000;
}

.main-header.bg-white .toggle:hover .icon {
  fill: #ff5a5f;
  stroke: #ff5a5f;
}

.main-header.header-light .sticky-area-wrap.sticky .sticky-area {
  background-color: #222;
}

.main-header.header-light .navbar-toggler,
.main-header.header-light .mobile-button-search {
  color: #fff;
}

.main-header.header-light .navbar-toggler,
.main-header.header-light .mobile-button-search {
  color: #fff;
}

.main-header.header-light .toggle .icon {
  fill: #fff;
}

.main-header.header-light .toggle:hover .icon {
  fill: #e6e6e6;
  stroke: #e6e6e6;
}

.main-header.text-uppercase .navbar-nav > .nav-item > .nav-link,
.main-header.text-uppercase .header-customize-item a {
  text-transform: uppercase;
}

.main-header.font-normal .nav-link,
.main-header.font-normal .header-customize-item a {
  font-weight: 400;
}

.sticky-area {
  width: 100%;
  position: relative;
  z-index: 9999;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

@media (min-width: 1200px) {
  .header-wrapper .container-90 {
    max-width: 95%;
  }
  .main-header .navbar-brand {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .main-header .navbar-brand img {
    max-height: 110px;
  }
  .main-header .nav-link {
    color: inherit;
    font-size: 1rem;
  }
  .main-header .nav-link span.caret {
    margin-left: 10px;
  }
  .main-header .nav-link span.caret i {
    font-size: 20px;
    font-weight: 300;
  }
  .main-header .nav-link:hover {
    color: #000;
  }
  .main-header .nav-item {
    -webkit-transition: padding 0.5s;
    transition: padding 0.5s;
  }
  .main-header .navbar-nav > .nav-item + .nav-item {
    margin-left: 30px;
  }
  .main-header .nav-item:hover > .sub-menu {
    display: block;
  }
  .main-header .sub-menu {
    margin: 0;
    min-width: 246px;
    position: absolute;
    left: 0;
    top: 100%;
    border-radius: 0;
    z-index: 1099;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #999999;
    font-family: "Work Sans", sans-serif;
    padding: 15px 0 25px;
  }
  .main-header .sub-menu .nav-item .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    padding-bottom: 0 !important;
  }
  .main-header .sub-menu .nav-item .nav-link:hover {
    color: #000;
  }
  .main-header .sub-menu .nav-item {
    padding: 0;
  }
  .main-header .sub-menu .nav-item:not(:first-child) .nav-link {
    padding-top: 5px;
  }
  .main-header .sub-menu .sub-menu {
    top: 0;
    left: 100%;
  }
  .main-header .sub-menu span.caret {
    margin-left: auto;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .main-header .x-menu-mega {
    padding: 25px 25px 0;
    left: -200%;
    overflow: auto;
  }
  .main-header .x-menu-mega h5 {
    font-family: inherit;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  .main-header .x-menu-mega .nav-item .nav-link {
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
  }
  .main-header .x-menu-mega .nav-item {
    padding: 0;
  }
  .main-header .x-menu-mega .nav-item:not(:first-child) .nav-link {
    padding-top: 5px;
  }
  .main-header .x-menu-mega.width-500 {
    min-width: 500px;
  }
  .main-header .x-menu-mega.width-800 {
    min-width: 800px;
  }
  .main-header .x-menu-mega.width-900 {
    min-width: 900px;
  }
  .main-header .x-menu-mega.width-1000 {
    min-width: 1000px;
  }
  .main-header .sub-menu .x-menu-mega {
    top: 0;
    min-width: 500px;
    left: 100%;
  }
  .main-header .mega-menu-col {
    list-style: none;
    padding: 0;
    margin-bottom: 35px;
  }
  .sticky-area-wrap.sticky {
    height: 86px;
  }
  .sticky-area-wrap.sticky .navbar-brand img {
    max-height: 56px;
  }
}

.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

@media screen and (max-width: 1199px) {
  .navbar {
    padding: 20px 0 !important;
  }
  .navbar-brand {
    margin-right: 0;
  }
  .main-header .nav-link {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .main-header .nav-link span.caret {
    width: 40px;
    line-height: 46px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
  }
  .main-header .nav-link span.caret i {
    font-size: 24px;
    font-weight: 300;
  }
  .main-header .nav-link:hover {
    color: #000;
  }
  .main-header .navbar-collapse {
    padding-top: 15px;
    margin-top: 20px;
  }
  .main-header .navbar-nav > .nav-item:last-child {
    border: 0;
  }
  .main-header .navbar-nav > .nav-item > .nav-link {
    color: #000;
  }
  .main-header .navbar-nav > .nav-item > .nav-link:hover {
    color: #000;
  }
  .main-header .navbar-nav > .nav-item > .nav-link .active {
    color: #000;
  }
  .main-header .sub-menu.show {
    display: block;
  }
  .main-header .sub-menu .nav-link {
    padding-left: 20px !important;
    color: #666666;
  }
  .main-header .sub-menu .sub-menu .nav-link {
    padding-left: 40px !important;
  }
  .main-header .x-menu-mega h5 {
    color: #000;
    font-family: inherit;
    line-height: 1.6;
    text-transform: uppercase;
    padding: 12px 20px;
    margin: 0;
  }
  .main-header .x-menu-mega h5,
  .main-header .x-menu-mega .nav-link {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .main-header .mega-menu-col {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .main-header.show-menu-dropdown {
    background-color: transparent;
  }
  .header-light .navbar {
    color: #ccc;
  }
  .sticky-area {
    max-height: 500px;
    overflow: auto;
  }
  .sticky-area-wrap.sticky {
    height: 94px;
  }
}

.header-style-01 .sticky-area {
  background: transparent;
}

.header-style-01 .sticky .sticky-area {
  background: #fff;
}

.header-style-01 .navbar-nav > .nav-item > .nav-link,
.header-style-01 .header-customize-item .link {
  padding: 28px 0 !important;
  line-height: 25px;
}

.header-style-01 .form-search {
  margin-left: 50px;
}

.header-style-04 .sticky-area {
  background: transparent;
}

.header-style-04 .sticky .sticky-area {
  background: #fff;
}

.header-style-04 .navbar-nav > .nav-item > .nav-link,
.header-style-04 .header-customize-item .link {
  padding: 28px 0 !important;
  line-height: 25px;
}

.header-style-04 .form-search {
  margin-left: 50px;
}

@media screen and (max-width: 1920px) {
  .header-style-01 .form-search {
    display: none !important;
    margin-left: 0;
  }
  .header-style-01 .navbar-nav {
    margin-left: auto;
  }
  .header-style-01 .button-search {
    display: block;
  }
  .header-style-01 .button-search .mobile-button-search {
    font-size: 20px;
  }
  .header-style-01 .button-search .mobile-button-search:hover {
    color: #ff5a5f;
  }

  /*  */
  .header-style-04 .form-search {
    display: none !important;
    margin-left: 0;
  }
  .header-style-04 .navbar-nav {
    margin-left: auto;
  }
  .header-style-04 .button-search {
    display: block;
  }
  .header-style-04 .button-search .mobile-button-search {
    font-size: 20px;
  }
  .header-style-04 .button-search .mobile-button-search:hover {
    color: #ff5a5f;
  }
}

@media (max-width: 1199.98px) {
  .header-style-01 .nav-item {
    -webkit-transition: padding 0.5s;
    transition: padding 0.5s;
  }
  .header-style-04 .nav-item {
    -webkit-transition: padding 0.5s;
    transition: padding 0.5s;
  }
}
@media screen and (max-width: 1199.98px) and (max-width: 1720px) {
  .header-style-01 .nav-item:last-child .sub-menu .sub-menu {
    left: -100%;
    -webkit-animation-name: x-fadeInLeft;
    animation-name: x-fadeInLeft;
  }
  .header-style-04 .nav-item:last-child .sub-menu .sub-menu {
    left: -100%;
    -webkit-animation-name: x-fadeInLeft;
    animation-name: x-fadeInLeft;
  }
}

@media screen and (max-width: 1199.98px) and (max-width: 2000px) {
  .header-style-01 .sub-menu .x-menu-mega {
    left: -500px;
    -webkit-animation-name: x-fadeInLeft;
    animation-name: x-fadeInLeft;
  }
  .header-style-04 .sub-menu .x-menu-mega {
    left: -500px;
    -webkit-animation-name: x-fadeInLeft;
    animation-name: x-fadeInLeft;
  }
}

@media (max-width: 1199.98px) {
  /* .header-style-01 .sticky-area {
      background: #fff; } */
  .header-style-01.header-light .sticky-area {
    background: #222;
  }
  .header-style-01.header-light a {
    color: #fff !important;
  }
  .header-style-01.header-light a:hover {
    color: #ff5a5f !important;
  }

  /*  */
  .header-style-04.header-light .sticky-area {
    background: #fff;
  }
  .header-style-04.header-light a {
    color: #222 !important;
  }
  .header-style-04.header-light a:hover {
    color: #222 !important;
  }
}

.header-style-01 .header-customize {
  margin-left: 60px;
}
.header-style-04 .header-customize {
  margin-left: 60px;
}
@media screen and (max-width: 1250px) {
  .header-style-01 .header-customize {
    margin-left: 30px;
  }
  .header-style-04 .header-customize {
    margin-left: 30px;
  }
}

.header-style-01 .sticky .navbar-nav > .nav-item > .nav-link,
.header-style-01 .sticky .header-customize-item .link {
  padding: 30px 0;
}

.header-style-04 .sticky .navbar-nav > .nav-item > .nav-link,
.header-style-04 .sticky .header-customize-item .link {
  padding: 30px 0;
}
@media (min-width: 1200px) {
  .header-style-02 .navbar-nav > .nav-item > .nav-link,
  .header-style-02 .header-customize-item .link,
  .header-style-03 .navbar-nav > .nav-item > .nav-link,
  .header-style-03 .header-customize-item .link,
  .header-style-05 .navbar-nav > .nav-item > .nav-link,
  .header-style-05 .header-customize-item .link {
    padding: 30px 0;
  }
}

@media (max-width: 1199.98px) {
  .header-style-02.header-light .sticky-area,
  .header-style-03.header-light .sticky-area,
  .header-style-05.header-light .sticky-area {
    background-color: #222 !important;
  }
  .header-style-02.header-light .nav-link,
  .header-style-03.header-light .nav-link,
  .header-style-05.header-light .nav-link {
    color: #fff !important;
  }
  .header-style-02.header-light .nav-link:hover,
  .header-style-03.header-light .nav-link:hover,
  .header-style-05.header-light .nav-link:hover {
    color: #000 !important;
  }
}

.header-style-02 .form-search,
.header-style-03 .form-search,
.header-style-05 .form-search {
  margin-left: 0;
}

@media screen and (max-width: 1750px) {
  .header-style-02 .form-search,
  .header-style-03 .form-search,
  .header-style-05 .form-search {
    display: none !important;
  }
  .header-style-02 .header-customize,
  .header-style-03 .header-customize,
  .header-style-05 .header-customize {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .header-style-02 .navbar-brand {
    margin-right: 140px;
  }
}

.header-style-02 .header-customize-item + .header-customize-item {
  margin-left: 48px;
}

@media (min-width: 1200px) {
  .header-style-03 .navbar-brand {
    margin-right: 50px;
  }
}

.header-style-03 .nav-link:hover,
.header-style-03 .link:hover {
  color: #000;
}

.header-style-03.bg-white .nav-link:hover,
.header-style-03.bg-white .link:hover {
  color: #000;
}

.header-style-03 .toggle .icon {
  width: 24px;
  height: 24px;
  margin-right: 0;
}

.header-style-03.bg-white .toggle .icon {
  fill: #000;
}

.header-style-03.bg-white .toggle:hover .icon {
  fill: #ff5a5f;
  stroke: #ff5a5f;
}

.header-style-03 .header-customize-item {
  margin-left: 35px;
}
.header-style-03 .header-customize-item .btn {
  font-weight: 400;
}
.header-style-03 .header-customize-item .header-customize {
  margin-left: auto !important;
}

@media screen and (max-width: 1860px) {
  .header-style-03 .form-search {
    display: none !important;
  }
  .header-style-03 .header-customize {
    margin-left: auto !important;
  }
}

.header-style-04 .topbar .topbar-item,
.header-style-06 .topbar .topbar-item {
  line-height: 1;
}
.header-style-04 .topbar .topbar-item.topbar-item-left .item,
.header-style-06 .topbar .topbar-item.topbar-item-left .item {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  text-transform: none;
}
.header-style-04 .topbar .topbar-item.topbar-item-left .icon,
.header-style-06 .topbar .topbar-item.topbar-item-left .icon {
  stroke-width: 2;
  margin-right: 7px;
}
.header-style-04 .topbar .topbar-item.topbar-item-left i,
.header-style-06 .topbar .topbar-item.topbar-item-left i {
  margin-right: 7px;
}

.header-style-04 .topbar .separate,
.header-style-06 .topbar .separate {
  padding: 0 15px;
}

.header-style-04 .topbar .social-icon li:not(:last-child),
.header-style-06 .topbar .social-icon li:not(:last-child) {
  margin-right: 17px;
}

.header-style-04 .topbar .social-icon a,
.header-style-06 .topbar .social-icon a {
  font-size: 14px;
}
.header-style-04 .topbar .social-icon a:hover,
.header-style-06 .topbar .social-icon a:hover {
  color: #ff8d90;
}

.header-style-04 .topbar .social-icon .icon,
.header-style-06 .topbar .social-icon .icon {
  width: 14px;
  height: 14px;
  stroke-width: 0;
}

.header-style-04 .topbar .dropdown-toggle,
.header-style-06 .topbar .dropdown-toggle {
  padding-top: 3px;
  padding-bottom: 3px;
}
.header-style-04 .topbar .dropdown-toggle:hover,
.header-style-06 .topbar .dropdown-toggle:hover {
  color: #ff8d90;
}

.header-style-04 .navbar-nav > .nav-item > .nav-link,
.header-style-06 .navbar-nav > .nav-item > .nav-link {
  padding: 0;
}

.header-style-04 .header-customize-item:first-child a:hover,
.header-style-06 .header-customize-item:first-child a:hover {
  color: #ff8d90;
}

@media (min-width: 992px) {
  .header-style-04 .nav-item,
  .header-style-06 .nav-item {
    padding: 26px 0;
  }
  .header-style-04 .header-wrapper,
  .header-style-06 .header-wrapper {
    padding: 0;
  }
}

@media screen and (max-width: 991px) {
  .header-style-04 .topbar,
  .header-style-06 .topbar {
    display: none !important;
  }
}

.header-style-04 {
  background-color: #ff5a5f !important;
}
.header-style-04 .header-wrapper {
  background: #ff5a5f;
}
.header-style-04 .sticky-area-wrap.sticky .sticky-area {
  background-color: #ff5a5f !important;
}
.header-style-04 .topbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.header-style-04 .topbar .dropdown-toggle {
  padding: 10px 0;
  color: #fff;
}
.header-style-04 .btn {
  color: #ff5a5f !important;
}
.header-style-04 .navbar-toggler,
.header-style-04 .mobile-button-search {
  color: #fff;
}
.header-style-04 .navbar-nav > .nav-item > .nav-link,
.header-style-04 .navbar-toggler,
.header-style-04 .mobile-button-search {
  color: #fff;
}
.header-style-04 .navbar-nav > .nav-item > .nav-link:hover,
.header-style-04 .navbar-toggler:hover,
.header-style-04 .mobile-button-search:hover {
  color: #000;
}
@media screen and (max-width: 1199px) {
  .header-style-04 .x-menu-mega h5 {
    color: #fff;
  }
  .header-style-04 .x-menu-mega h5,
  .header-style-04 .x-menu-mega .nav-link {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .header-style-04 .nav-item {
    padding: 0 !important;
  }
  .header-style-04 .nav-link {
    color: #fff !important;
  }
  .header-style-04 .nav-link:hover {
    color: #000 !important;
  }
  .header-style-04 .navbar-collapse {
    background-color: #ff5a5f !important;
  }
  .header-style-04.show-menu-dropdown {
    background: #ff5a5f;
  }
}

.header-style-06 .topbar {
  border-bottom: 1px solid #e1e1e1;
  padding: 10px 0;
}
.header-style-06 .topbar .dropdown-toggle {
  color: black;
}

@media (min-width: 992px) {
  .header-style-05 .navbar-brand {
    margin-right: 50px;
  }
}

.header-style-05 .header-customize-item:not(:last-child) {
  margin-left: 30px;
}

.header-style-05 .button {
  margin-left: 20px;
}

@media (min-width: 992px) {
  .header-style-07 .navbar-brand {
    margin-right: 88px;
  }
}

.header-style-07 .navbar-nav > .nav-item > .nav-link,
.header-style-07 .header-customize-item .link {
  padding: 19px 0;
  line-height: 32px;
}

.header-style-07 .nav-link {
  font-size: 18px;
}

.header-style-07 .header-customize-item {
  margin-left: 0;
}
.header-style-07 .header-customize-item .btn {
  padding-left: 20px;
  padding-right: 15px;
}

.header-style-07 .signal {
  position: relative;
  margin-right: 34px;
}
.header-style-07 .signal::after {
  content: "";
  position: absolute;
  top: -2px;
  right: -1px;
  width: 8px;
  height: 8px;
  background: #ff5a5f;
  border-radius: 50%;
}
.header-style-07 .signal .icon {
  width: 24px;
  height: 24px;
}

.header-style-07 .language {
  margin-left: 30px;
  margin-right: 20px;
}

.header-style-07 .sticky-area-wrap.sticky .sticky-area {
  background-color: #fff;
}

@media (max-width: 1199.98px) {
  .header-style-10 .header-wrapper {
    padding: 2px 0;
  }
}

.header-style-10 .navbar-nav > .nav-item > .nav-link,
.header-style-10 .header-customize-item .link {
  padding: 22px 0;
  line-height: 28px;
}

.header-style-10 .nav-item {
  -webkit-transition: padding 0.5s;
  transition: padding 0.5s;
}
@media screen and (max-width: 1720px) {
  .header-style-10 .nav-item:last-child .sub-menu .sub-menu {
    left: -100%;
    -webkit-animation-name: x-fadeInLeft;
    animation-name: x-fadeInLeft;
  }
}

.header-style-10 .form-search {
  margin-left: 50px;
}

@media screen and (max-width: 1600px) {
  .header-style-10 .form-search {
    display: none !important;
  }
  .header-style-10 .navbar-nav {
    margin-left: auto;
  }
  .header-style-10 .button-search {
    display: block;
  }
  .header-style-10 .button-search .mobile-button-search {
    font-size: 20px;
  }
  .header-style-10 .button-search .mobile-button-search:hover {
    color: #ff5a5f;
  }
}

.header-style-12 .sticky-area-wrap.sticky .sticky-area {
  background: #ff5a5f;
  padding-top: 15px;
  padding-bottom: 15px;
}

.header-style-12 .btn,
.header-style-13 .btn {
  padding: 9px 15px;
}

.canvas-menu {
  padding: 52px 100px 100px !important;
  max-width: 675px !important;
  margin-left: auto !important;
  margin-right: 0 !important;
}
.canvas-menu .menu-top .separate {
  position: relative;
}
.canvas-menu .menu-top .separate::after {
  content: "";
  position: absolute;
  top: -15px;
  left: 0;
  width: 1px;
  height: 30px;
  background: #e0e0e0;
  margin-left: 5px;
  margin-right: 25px;
}
.canvas-menu .menu .list-group .list-group-item {
  padding-top: 0;
}
.canvas-menu .menu > .list-group > .list-group-item > a:not(.collapsed) {
  color: #000;
}
.canvas-menu .menu > .list-group > .list-group-item > a.collapsed {
  color: #999999;
}
.canvas-menu .social-icon .list-inline-item:not(:last-child) {
  margin-right: 39px !important;
}
.canvas-menu .social-icon a:hover {
  color: #ff5a5f;
}

.top-campaign {
  background: #fbb700;
}
.top-campaign .top-campaign-container {
  padding: 9px 0;
  color: #000;
}
.top-campaign .code {
  background: #ff3c00;
  display: inline-block;
  height: 27px;
  line-height: 27px;
  padding: 0 6px;
  border-radius: 2px;
  color: #fff;
  font-weight: 600;
}
.top-campaign .campaign-action {
  padding-left: 30px;
}
.top-campaign .close-button {
  color: #000;
}
.top-campaign .close-button i {
  font-size: 24px;
  font-weight: 300;
}

.header-style-01 .sticky-area-wrap.sticky .sticky-area,
.header-style-04 .sticky-area-wrap.sticky .sticky-area,
.header-style-06 .sticky-area-wrap.sticky .sticky-area,
.header-style-10 .sticky-area-wrap.sticky .sticky-area,
.header-style-12 .sticky-area-wrap.sticky .sticky-area,
.header-style-07 .sticky-area-wrap.sticky .sticky-area {
  -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);
}

/*--------------------------------------------------------------
  ## Footer
  --------------------------------------------------------------*/
.main-footer {
  padding-bottom: 25px;
}

.gtf-back-to-top {
  visibility: hidden;
}
.gtf-back-to-top.in {
  visibility: visible;
}

.footer-first {
  padding-top: 38px;
  padding-bottom: 38px;
}
@media (max-width: 991.98px) {
  .footer-first .footer-first-container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .footer-first .footer-first-container .download {
    margin-left: 0 !important;
    margin-top: 30px;
  }
  .footer-first .footer-first-container .fact {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.footer-first .download .item:not(:last-child) {
  margin-right: 15px;
}

.footer-second .form-newsletter .btn,
.footer-second .form-newsletter .form-control {
  height: 47px;
}

.footer-second .form-newsletter .input-group-append {
  display: inline-block;
}

.footer-second .region .select-custom {
  width: 200px;
}
.footer-second .region .select-custom::after {
  font-size: 12px;
}

.footer-second .region select {
  border-color: transparent;
}

.main-footer.bg-lighter-dark {
  color: #999999;
}
.main-footer.bg-lighter-dark .footer-first {
  border-bottom: 1px solid #404040;
}
.main-footer.bg-lighter-dark .input-group {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background: #666666 !important;
}
.main-footer.bg-lighter-dark .form-control {
  border: none;
  color: #cccccc;
}
.main-footer.bg-lighter-dark .form-control:focus {
  background: #666666;
}
.main-footer.bg-lighter-dark .form-control::-webkit-input-placeholder {
  color: #cccccc;
}
.main-footer.bg-lighter-dark .form-control::-moz-placeholder {
  color: #cccccc;
}
.main-footer.bg-lighter-dark .form-control:-ms-input-placeholder {
  color: #cccccc;
}
.main-footer.bg-lighter-dark .form-control::-ms-input-placeholder {
  color: #cccccc;
}
.main-footer.bg-lighter-dark .form-control::placeholder {
  color: #cccccc;
}
.main-footer.bg-lighter-dark .social-icon a:hover {
  color: #fff;
}
.main-footer.bg-lighter-dark .back-top a {
  color: #f5f5f5;
}

.main-footer-style-01 .footer-second .input-group-append:hover,
.main-footer-style-01 .footer-second .input-group-append:focus {
  background: #fff;
}

.main-footer-style-01 .social-icon li i {
  font-size: 16px;
}

.main-footer-style-01 .social-icon li .icon {
  font-size: 16px;
}

.main-footer-style-01 .back-top {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 767.98px) {
  .main-footer-style-01 .back-top {
    left: 0;
    right: auto;
    bottom: -30px;
    top: auto;
  }
}
.main-footer-style-01 .back-top a {
  color: #666666;
}
.main-footer-style-01 .back-top i:not(:last-child) {
  margin-right: 10px;
}
.main-footer-style-01 .back-top.in {
  visibility: visible;
}

.main-footer-style-02 {
  position: relative;
}
@media (max-width: 1199.98px) {
  .main-footer-style-02 .footer-last-container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .main-footer-style-02 .footer-last-container .social-icon {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
.main-footer-style-02 .back-top {
  font-size: 30px;
  line-height: 1;
  display: inline-block;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 99999;
  top: auto;
  -webkit-transform: none;
  transform: none;
}
.main-footer-style-02 .back-top a {
  color: #000;
}
@media screen and (max-width: 1500px) {
  .main-footer-style-02 .back-top {
    right: 10px;
  }
}

/*--------------------------------------------------------------
  ## Page Title
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ## Widgets
  --------------------------------------------------------------*/
.widget .list-group-item {
  padding: 5px 0;
  border-color: #dee2e6;
}
.widget .list-group-item .item-icon {
  font-size: 18px;
}
.widget .list-group-item .icon {
  width: 18px;
  height: 18px;
}

.widget.datepicker {
  position: static;
  opacity: 1;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
}
.widget.datepicker .datepicker--nav {
  padding: 8px 0;
}
.widget.datepicker .datepicker--days-names {
  margin: 0;
  padding: 6px 0;
}

.widget.reservation .datepicker {
  width: 100%;
  border: none;
  border-radius: 0;
}

.widget.reservation .form-group {
  margin-bottom: 20px;
}

.widget.reservation label {
  color: #000;
}

.widget.reservation .person {
  -ms-flex: 0 0 80px;
  -webkit-box-flex: 0;
  flex: 0 0 80px;
  max-width: 80px;
}

.widget.reservation .type {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding-left: 18px;
}

.widget.reservation .form-control {
  color: #000;
}

.widget.reservation .btn {
  font-size: 18px;
}

.widget.reservation .arrows .form-control {
  position: relative;
  z-index: 2;
}

.widget.reservation .arrows::after,
.widget.reservation .arrows:before {
  font-size: 12px;
  line-height: 1;
}

.widget.reservation .arrows::after {
  bottom: 20%;
}

.widget.reservation .arrows:before {
  top: 20%;
}

.widget.reservation .select-custom::after {
  font-size: 12px;
  line-height: 1;
}

.widget.map .button-direction {
  top: 10px;
  right: 10px;
}
.widget.map .button-direction .btn {
  background: #333333;
  border: #333333;
  color: #fff;
  font-weight: 400;
}
.widget.map .button-direction .btn:hover {
  background: #fff;
  color: #333333;
}

.widget.gallery .photo-gallery {
  margin: -10px;
}

.widget.gallery .photo-item {
  -ms-flex: 0 0 33.3333%;
  -webkit-box-flex: 0;
  flex: 0 0 33.3333%;
  max-width: 33.333%;
  padding: 10px;
}

.widget.contact .image {
  max-width: 77px;
  max-height: 77px;
  min-width: 77px;
  min-height: 77px;
  width: 77px;
  height: 77px;
}

.widget.contact .media .btn {
  margin-right: 10px;
  font-weight: 400;
}

.widget.contact .form-control {
  font-size: 14px;
}

.widget.contact textarea {
  height: 124px !important;
}

.widget.contact .contact-form .btn {
  font-size: 16px !important;
}

.tags a {
  color: #999999;
  line-height: 2.1;
}
.tags a:hover {
  color: #000;
}

.page-title .breadcrumb {
  padding: 6px 0;
}

.page-title-style-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 605px;
}
@media (max-width: 767.98px) {
  .page-title-style-background {
    height: 400px;
  }
}
.page-title-style-background .container {
  height: 100%;
}

.page-title-style-no-background {
  padding-top: 210px;
  padding-bottom: 30px;
  text-align: center;
}

/*--------------------------------------------------------------
  ## Elements
  --------------------------------------------------------------*/
.toggle-icon {
  cursor: pointer;
  display: inline-block;
  width: 32px;
  height: 30px;
  position: relative;
  vertical-align: middle;
}

.toggle-icon > span {
  bottom: 0;
  display: block;
  height: 2px;
  left: 2px;
  right: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: currentColor;
}

.toggle-icon > span:after,
.toggle-icon > span:before {
  content: "";
  height: 2px;
  left: 0;
  margin: auto;
  position: absolute;
  right: -6px;
  -webkit-transition: top 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out,
    background 0.3s;
  -webkit-transition: top 0.3s ease-in-out, background 0.3s,
    -webkit-transform 0.3s ease-in-out;
  transition: top 0.3s ease-in-out, background 0.3s,
    -webkit-transform 0.3s ease-in-out;
  transition: top 0.3s ease-in-out, transform 0.3s ease-in-out, background 0.3s;
  transition: top 0.3s ease-in-out, transform 0.3s ease-in-out, background 0.3s,
    -webkit-transform 0.3s ease-in-out;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background-color: currentColor;
}

.toggle-icon > span:before {
  top: 8px;
}

.toggle-icon > span:after {
  top: -8px;
}

.toggle-icon:hover {
  color: #e4573d;
}

.toggle-icon.in > span {
  background-color: transparent !important;
}

.toggle-icon.in > span:after,
.toggle-icon.in > span:before {
  -webkit-transition: top 0.3s ease-in-out,
    -webkit-transform 0.3s 0.5s ease-in-out, background 0.3s;
  -webkit-transition: top 0.3s ease-in-out, background 0.3s,
    -webkit-transform 0.3s 0.5s ease-in-out;
  transition: top 0.3s ease-in-out, background 0.3s,
    -webkit-transform 0.3s 0.5s ease-in-out;
  transition: top 0.3s ease-in-out, transform 0.3s 0.5s ease-in-out,
    background 0.3s;
  transition: top 0.3s ease-in-out, transform 0.3s 0.5s ease-in-out,
    background 0.3s, -webkit-transform 0.3s 0.5s ease-in-out;
  top: 0;
}

.toggle-icon.in > span:after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}

.toggle-icon.in > span:before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}

.icon-box {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.icon-box.icon-box-style-03 {
  font-size: 0.875rem;
}
.icon-box.icon-box-style-03 .icon-box-icon {
  margin-right: 15px;
}
.icon-box.background-icon.color-primary .icon-box-icon {
  background-color: #ff5a5f;
}
.icon-box.background-icon.color-primary .icon-box-icon::after {
  opacity: 0;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: background 0.5s, color 0.5s, opacity 0.3s, transform 0.3s;
  -webkit-transition: background 0.5s, color 0.5s, opacity 0.3s,
    -webkit-transform 0.3s;
  transition: background 0.5s, color 0.5s, opacity 0.3s, -webkit-transform 0.3s;
  transition: background 0.5s, color 0.5s, opacity 0.3s, transform 0.3s;
  transition: background 0.5s, color 0.5s, opacity 0.3s, transform 0.3s,
    -webkit-transform 0.3s;
}
.icon-box.background-icon.color-primary .icon-box-icon:hover {
  background-color: #ff5a5f;
}
.icon-box.background-icon.color-primary .icon-box-icon:hover::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-box-shadow: 0 0 0 2px #ff5a5f;
  box-shadow: 0 0 0 2px #ff5a5f;
}
.icon-box.background-icon.color-primary .icon {
  fill: #fff;
  stroke: #fff;
}
.icon-box.background-icon.color-primary i {
  color: #fff;
}
.icon-box.shape-icon .icon-box-icon {
  position: relative;
}
.icon-box.shape-icon .icon-box-icon::after {
  width: 100%;
  height: 100%;
  content: "";
  top: -3px;
  left: -3px;
  padding: 3px;
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.icon-box.shape-icon .icon-box-icon {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33px;
  flex: 0 0 33px;
}
.icon-box.shape-icon .icon {
  width: 16px;
  height: 16px;
}
.icon-box.shape-icon i {
  font-size: 16px;
}
.icon-box.shape-icon.icon-box-size-lg .icon-box-icon {
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.icon-box.shape-icon.icon-box-size-lg .icon {
  width: 42px;
  height: 42px;
}
.icon-box.shape-icon.icon-box-size-lg i {
  font-size: 42px;
}
.icon-box.shape-icon.icon-box-size-lg .title {
  font-size: 24px;
}
.icon-box.shape-icon.icon-box-size-lg .description {
  font-size: 18px;
}
.icon-box.shape-circle .icon-box-icon {
  border-radius: 50%;
}
.icon-box.shape-circle .icon-box-icon:after {
  border-radius: 50%;
}
.icon-box.icon-outline.color-primary .icon-box-icon {
  -webkit-box-shadow: 0 0 0 2px #ff5a5f;
  box-shadow: 0 0 0 2px #ff5a5f;
}
.icon-box.icon-outline.color-primary .icon-box-icon .icon {
  fill: #ff5a5f;
  stroke: #ff5a5f;
}
.icon-box.icon-outline.color-primary .icon-box-icon i {
  color: #ff5a5f;
}
.icon-box.icon-outline.color-primary .icon-box-icon::after {
  opacity: 0;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: background 0.5s, color 0.5s, opacity 0.3s, transform 0.3s;
  -webkit-transition: background 0.5s, color 0.5s, opacity 0.3s,
    -webkit-transform 0.3s;
  transition: background 0.5s, color 0.5s, opacity 0.3s, -webkit-transform 0.3s;
  transition: background 0.5s, color 0.5s, opacity 0.3s, transform 0.3s;
  transition: background 0.5s, color 0.5s, opacity 0.3s, transform 0.3s,
    -webkit-transform 0.3s;
}
.icon-box.icon-outline.color-primary .icon-box-icon:hover {
  background-color: #ff5a5f;
  -webkit-box-shadow: 0 0 0 0 #ff5a5f;
  box-shadow: 0 0 0 0 #ff5a5f;
}
.icon-box.icon-outline.color-primary .icon-box-icon:hover::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-box-shadow: 0 0 0 2px #ff5a5f;
  box-shadow: 0 0 0 2px #ff5a5f;
}
.icon-box.icon-outline.color-primary .icon-box-icon:hover .icon {
  fill: #fff;
  stroke: #fff;
}
.icon-box.icon-outline.color-primary .icon-box-icon:hover i {
  color: #fff;
}
.icon-box.icon-outline.color-gray .icon-box-icon {
  -webkit-box-shadow: 0 0 0 2px #666666;
  box-shadow: 0 0 0 2px #666666;
}
.icon-box.icon-outline.color-gray .icon-box-icon .icon {
  fill: #666666;
  stroke: #666666;
}
.icon-box.icon-outline.color-gray .icon-box-icon i {
  color: #666666;
}
.icon-box.icon-outline.color-gray .icon-box-icon::after {
  opacity: 0;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: background 0.5s, color 0.5s, opacity 0.3s, transform 0.3s;
  -webkit-transition: background 0.5s, color 0.5s, opacity 0.3s,
    -webkit-transform 0.3s;
  transition: background 0.5s, color 0.5s, opacity 0.3s, -webkit-transform 0.3s;
  transition: background 0.5s, color 0.5s, opacity 0.3s, transform 0.3s;
  transition: background 0.5s, color 0.5s, opacity 0.3s, transform 0.3s,
    -webkit-transform 0.3s;
}
.icon-box.icon-outline.color-gray .icon-box-icon:hover {
  background-color: #666666;
  -webkit-box-shadow: 0 0 0 0 #666666;
  box-shadow: 0 0 0 0 #666666;
}
.icon-box.icon-outline.color-gray .icon-box-icon:hover::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-box-shadow: 0 0 0 2px #666666;
  box-shadow: 0 0 0 2px #666666;
}
.icon-box.icon-outline.color-gray .icon-box-icon:hover .icon {
  fill: #fff;
  stroke: #fff;
}
.icon-box.icon-outline.color-gray .icon-box-icon:hover i {
  color: #fff;
}
.icon-box.no-shape .icon {
  width: 16px;
  height: 16px;
}
.icon-box.no-shape i {
  font-size: 16px;
}
.icon-box.no-shape .title {
  font-weight: 600;
}
.icon-box.no-shape.icon-box-size-lg .icon {
  width: 72px;
  height: 72px;
}
.icon-box.no-shape.icon-box-size-lg i {
  font-size: 72px;
}
.icon-box.no-shape.icon-box-size-lg .title {
  font-size: 24px;
}
.icon-box.no-shape.icon-box-size-lg .description {
  font-size: 18px;
}

.icon-box-style-01 {
  width: 120px;
  height: 120px;
  text-align: center;
  padding: 24px 0;
}
.icon-box-style-01 .icon {
  width: 40px;
  height: 40px;
  stroke: #000;
  fill: #000;
}
.icon-box-style-01:hover {
  background: #ff5a5f;
  color: #fff !important;
}
.icon-box-style-01:hover .icon {
  stroke: #fff;
  fill: #fff;
}

.icon-box-style-02 .icon {
  width: 50px;
  height: 50px;
  fill: #87bc15;
  stroke: #87bc15;
}

.icon-box-style-02 .number {
  color: #e7e7e7;
  line-height: 1;
  margin-top: 10px;
}

.icon-box-style-02 .text-muted {
  line-height: 1.5;
}

.icon-box-style-02 .color-primary i {
  color: #ff5a5f;
}

.icon-box-style-02 .color-primary .icon {
  fill: #ff5a5f;
  stroke: #ff5a5f;
}

.image-box {
  overflow: hidden;
}
.image-box .image {
  z-index: 1;
}
.image-box .image::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, transparent),
    color-stop(rgba(0, 0, 0, 0.4)),
    to(rgba(0, 0, 0, 0.6))
  );
  background: linear-gradient(
    to bottom,
    transparent 50%,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.6)
  );
}
.image-box .content-box {
  left: 0;
  bottom: 0;
  top: auto;
  z-index: 3;
  line-height: 1.2;
}
.image-box.overlay-lighter .image::after {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(80%, transparent),
    color-stop(rgba(0, 0, 0, 0.05)),
    to(rgba(0, 0, 0, 0.1))
  );
  background: linear-gradient(
    to bottom,
    transparent 80%,
    rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.1)
  );
}
.image-box.no-overlay .image::after {
  display: none;
}

.image-box-center .image {
  min-height: 186px;
}

.image-box-center .card-body .card-title {
  font-size: 26px;
  line-height: 0.92;
  letter-spacing: -0.05em;
}

.image-box-center-style-02 .card-header {
  min-height: 139px;
}

.image-box-style-card .card-body {
  padding: 14.5px 18px;
}

.listing-image-card:not(:last-child) {
  margin-bottom: 65px;
}

@media screen and (max-width: 400px) {
  .listing-image-card .heading {
    max-width: 200px;
  }
}

.listing-image-card .slick-arrows {
  top: -58px !important;
  right: 15px !important;
}

.hover-scale {
  overflow: hidden;
}
.hover-scale .image {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.hover-scale:hover .image {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

.hover-flash:hover .image {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.heading.heading-style-title-behind .heading-title-wrapper {
  position: relative;
}
.heading.heading-style-title-behind .heading-title-wrapper span {
  font-size: 60px;
  font-weight: 700;
  color: #cccccc;
  text-transform: uppercase;
  opacity: 0.1;
}

.heading.heading-style-title-behind .heading-title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  color: #000;
}

.post {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
}
.post .feature-image {
  position: relative;
  height: 100%;
}
.post .feature-image .image-content {
  position: absolute;
  top: 15px;
  right: 15px;
}
.post .feature-image .media span {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #000;
  color: #fff;
  font-size: 18px;
  text-align: center;
  border-radius: 2px;
}
.post .feature-image .box {
  margin-bottom: 0 !important;
}
.post .feature-image .slick-arrows {
  position: absolute;
  bottom: 43px;
  left: 33px;
}
.post .feature-image .slick-arrows .slick-arrow {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #000;
  color: #fff;
  font-size: 18px;
  text-align: center;
}
.post .feature-image .slick-arrows .slick-arrow i {
  font-weight: 300;
}
.post .feature-image .slick-arrows .slick-next {
  left: 45px;
}
.post .feature-image .slick-arrows .slick-disabled {
  background: #2f2f2f;
}

.post-style-1 .feature-image,
.post-style-2 .feature-image {
  width: 200px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  height: auto;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.post-style-1 .post:hover .feature-image,
.post-style-2 .post:hover .feature-image {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

@media screen and (max-width: 576px) {
  .post-style-1 .post,
  .post-style-2 .post {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .post-style-1 .feature-image,
  .post-style-2 .feature-image {
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 100%;
    height: 0;
    padding-top: 75%;
  }
}

.post-style-5 .post {
  background: #f2f2f2;
}

.post-style-5 .media {
  top: 20px !important;
  right: 25px !important;
}

.post-style-5 .card {
  padding: 60px 40px 55px 55px;
}
@media screen and (max-width: 400px) {
  .post-style-5 .card {
    padding: 30px;
  }
}

.banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.banner::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.banner .container {
  position: relative;
  height: 100%;
}
.banner .banner-content {
  padding: 150px 0;
  position: relative;
  z-index: 3;
}
.banner .banner-content .heading {
  color: #fff;
}
.banner .banner-content .heading .heading-title {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  color: #fff;
  line-height: 1;
}
.banner .banner-content .heading .heading-description {
  color: #fff;
  margin-top: 20px;
}

.section-search {
  width: 1269px;
  padding-top: 40px;
  position: absolute;
  z-index: 999;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media screen and (max-width: 1269px) {
  .section-search {
    width: 100%;
  }
}
.section-search .dropdown-menu {
  top: -3px !important;
}

.client-logo .slick-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.client-logo .item img {
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.client-logo .item:hover img {
  opacity: 0.5;
}

.client-logo.inline h4 {
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.client-logo.inline .slick-slider {
  padding-left: 160px;
}

.social-icon .list-inline {
  margin-bottom: 0;
}
.social-icon .list-inline .list-inline-item {
  text-align: center;
}
.social-icon .list-inline .list-inline-item:not(:last-of-type) {
  margin-right: 21px;
}

.social-icon span {
  display: none;
}

.social-icon ul,
.social-icon li,
.social-icon a,
.social-icon i,
.social-icon span {
  color: inherit;
}

.social-icon.text-white a:hover {
  color: #ff5a5f;
}

.social-icon.text-white.si-outline a {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.social-icon.text-white.si-outline a:hover {
  background-color: #fff;
  color: #000;
}

.social-icon.primary-color a {
  color: #ff5a5f;
}

.social-icon.primary-color.si-circle a,
.social-icon.primary-color.si-square a {
  background-color: #ff5a5f;
  color: #fff;
  border: 1px solid #ff5a5f;
}
.social-icon.primary-color.si-circle a:hover,
.social-icon.primary-color.si-square a:hover {
  background-color: transparent;
  color: #ff5a5f;
}

.social-icon.primary-color.si-outline a {
  background-color: transparent;
  color: #ff5a5f;
  border: 1px solid #ff5a5f;
}
.social-icon.primary-color.si-outline a:hover {
  background-color: #ff5a5f;
  color: #fff;
}

.social-icon.primary-color a:hover {
  color: #ff5a5f;
}

.social-icon.gray-color a {
  color: #666666;
}

.social-icon.gray-color.si-circle a,
.social-icon.gray-color.si-square a {
  background-color: #666666;
  color: #fff;
  border: 1px solid #7d7d7d;
}
.social-icon.gray-color.si-circle a:hover,
.social-icon.gray-color.si-square a:hover {
  background-color: transparent;
  color: #666666;
}

.social-icon.gray-color.si-outline a {
  background-color: transparent;
  color: #666666;
  border: 1px solid #666666;
}
.social-icon.gray-color.si-outline a:hover {
  background-color: #666666;
  color: #fff;
}

.social-icon.gray-color a:hover {
  color: #ff5a5f;
}

.social-icon.text-dark a {
  color: #000;
}

.social-icon.text-dark.si-circle a,
.social-icon.text-dark.si-square a {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
}
.social-icon.text-dark.si-circle a:hover,
.social-icon.text-dark.si-square a:hover {
  background-color: transparent;
  color: #000;
}

.social-icon.text-dark.si-outline a {
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
}
.social-icon.text-dark.si-outline a:hover {
  background-color: #000;
  color: #fff;
}

.social-icon.text-dark a:hover {
  color: #ff5a5f;
}

.social-icon.light-color a {
  color: #999999;
}

.social-icon.light-color.si-circle a,
.social-icon.light-color.si-square a {
  background-color: #999999;
  color: #fff;
  border: 1px solid #999999;
}
.social-icon.light-color.si-circle a:hover,
.social-icon.light-color.si-square a:hover {
  background-color: transparent;
  color: #999999;
}

.social-icon.light-color.si-outline a {
  background-color: transparent;
  color: #999999;
  border: 1px solid #999999;
}
.social-icon.light-color.si-outline a:hover {
  background-color: #fff;
}

.social-icon.light-color a:hover {
  color: #ff5a5f;
}

.social-icon.origin-color .si-facebook a {
  color: #3b5998;
}
.social-icon.origin-color .si-facebook a:hover {
  color: #2d4373;
}

.social-icon.origin-color .si-twitter a {
  color: #1da1f2;
}
.social-icon.origin-color .si-twitter a:hover {
  color: #0c85d0;
}

.social-icon.origin-color .si-google a {
  color: #cc0000;
}
.social-icon.origin-color .si-google a:hover {
  color: #990000;
}

.social-icon.origin-color .si-tumblr a {
  color: #2d567f;
}
.social-icon.origin-color .si-tumblr a:hover {
  color: #203d59;
}

.social-icon.origin-color .si-rss a {
  color: #f86a02;
}
.social-icon.origin-color .si-rss a:hover {
  color: #c55402;
}

.social-icon.origin-color.si-circle .si-facebook a,
.social-icon.origin-color.si-square .si-facebook a {
  background-color: #3b5998;
}

.social-icon.origin-color.si-circle .si-twitter a,
.social-icon.origin-color.si-square .si-twitter a {
  background-color: #1da1f2;
}

.social-icon.origin-color.si-circle .si-google a,
.social-icon.origin-color.si-square .si-google a {
  background-color: #cc0000;
}

.social-icon.origin-color.si-circle .si-tumblr a,
.social-icon.origin-color.si-square .si-tumblr a {
  background-color: #2d567f;
}

.social-icon.origin-color.si-circle .si-rss a,
.social-icon.origin-color.si-square .si-rss a {
  background-color: #f86a02;
}

.social-icon.origin-color.si-circle .list-inline-item a,
.social-icon.origin-color.si-square .list-inline-item a {
  color: #fff;
  border: 1px solid transparent;
}

.social-icon.origin-color.si-circle .list-inline-item:hover a,
.social-icon.origin-color.si-square .list-inline-item:hover a {
  background: transparent;
  color: #fff;
}

.social-icon.origin-color.si-circle .list-inline-item:hover.si-facebook a,
.social-icon.origin-color.si-square .list-inline-item:hover.si-facebook a {
  color: #2d4373;
  border-color: #2d4373;
}

.social-icon.origin-color.si-circle .list-inline-item:hover.si-twitter a,
.social-icon.origin-color.si-square .list-inline-item:hover.si-twitter a {
  color: #0c85d0;
  border-color: #0c85d0;
}

.social-icon.origin-color.si-circle .list-inline-item:hover.si-google a,
.social-icon.origin-color.si-square .list-inline-item:hover.si-google a {
  color: #990000;
  border-color: #990000;
}

.social-icon.origin-color.si-circle .list-inline-item:hover.si-tumblr a,
.social-icon.origin-color.si-square .list-inline-item:hover.si-tumblr a {
  color: #203d59;
  border-color: #203d59;
}

.social-icon.origin-color.si-circle .list-inline-item:hover.si-rss a,
.social-icon.origin-color.si-square .list-inline-item:hover.si-rss a {
  color: #c55402;
  border-color: #c55402;
}

.social-icon.origin-color.si-outline .list-inline-item a {
  background-color: transparent !important;
}
.social-icon.origin-color.si-outline .list-inline-item a:hover {
  color: #fff !important;
}

.social-icon.origin-color.si-outline .si-facebook a {
  border: 1px solid #3b5998;
  color: #3b5998;
}
.social-icon.origin-color.si-outline .si-facebook a:hover {
  background-color: #3b5998 !important;
}

.social-icon.origin-color.si-outline .si-twitter a {
  border: 1px solid #1da1f2;
  color: #1da1f2;
}
.social-icon.origin-color.si-outline .si-twitter a:hover {
  background-color: #1da1f2 !important;
}

.social-icon.origin-color.si-outline .si-google a {
  border: 1px solid #cc0000;
  color: #cc0000;
}
.social-icon.origin-color.si-outline .si-google a:hover {
  background-color: #cc0000 !important;
}

.social-icon.origin-color.si-outline .si-tumblr a {
  border: 1px solid #2d567f;
  color: #2d567f;
}
.social-icon.origin-color.si-outline .si-tumblr a:hover {
  background-color: #2d567f !important;
}

.social-icon.origin-color.si-outline .si-rss a {
  border: 1px solid #f86a02;
  color: #f86a02;
}
.social-icon.origin-color.si-outline .si-rss a:hover {
  background-color: #f86a02 !important;
}

.social-icon.origin-color .list-inline-item a:hover {
  color: #ff8d90;
}

.social-icon.si-xs {
  font-size: 12px;
}

.social-icon.si-sm {
  font-size: 0.875rem;
}

.social-icon.si-md {
  font-size: 1rem;
}

.social-icon.text-left .list-inline {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.social-icon.text-right .list-inline {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.social-icon.si-text span {
  display: inline-block;
}

.social-icon.si-circle .list-inline-item:not(:last-of-type),
.social-icon.si-square .list-inline-item:not(:last-of-type) {
  margin-right: 10px;
}

.social-icon.si-circle a,
.social-icon.si-square a {
  width: 36px;
  height: 36px;
  display: inline-block;
  line-height: 34px;
}

.social-icon.si-circle .list-inline-item a {
  border-radius: 50%;
}

.social-icon.si-square .list-inline-item a {
  border-radius: 2px;
}

.author-rate {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
}
.author-rate .rate-item {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 0 -1px;
}
.author-rate .rate-item .icon {
  width: 24px;
  height: 24px;
  stroke: #cccccc;
  fill: #cccccc;
}
.author-rate .rate-item.checked .icon {
  stroke: #67a900;
  fill: #67a900;
}

.testimonials-slider.arrow-center .testimonial {
  height: 100%;
}

.testimonials-slider.arrow-center .slick-slider .box {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.testimonials-slider.arrow-center .slick-slider .slick-dots {
  top: 108%;
}

.testimonials-slider.arrow-center .author-rate .icon {
  width: 24px;
  height: 24px;
}

.testimonials-slider.arrow-center .slick-arrow {
  top: calc(50% - 60px);
}

.testimonials-slider.arrow-center .card-body {
  padding-bottom: 55px;
}
.testimonials-slider.arrow-center .card-body .icon {
  width: 8px;
  height: 8px;
  margin-bottom: 10px;
}

.testimonials-slider.arrow-center .slick-slider {
  margin: 0 -27px;
}

.testimonials-slider.arrow-center .slick-slide {
  padding: 0 27px;
  padding-bottom: 20px;
}

.testimonials-slider.arrow-center .slick-arrows .slick-arrow i {
  font-size: 26px;
}

.testimonials-slider.arrow-center .media-body a {
  line-height: 1.1;
}

.testimonials-slider.arrow-bottom,
.testimonials-slider.no-nav {
  font-family: "Poppins", sans-serif;
}
.testimonials-slider.arrow-bottom .testimonial,
.testimonials-slider.no-nav .testimonial {
  padding: 0;
}
.testimonials-slider.arrow-bottom .testimonial .content,
.testimonials-slider.no-nav .testimonial .content {
  font-size: 13px;
  margin-bottom: 25px;
  letter-spacing: 0;
}
.testimonials-slider.arrow-bottom .testimonial .author-name,
.testimonials-slider.no-nav .testimonial .author-name {
  color: #000;
  margin-right: 3px;
}
.testimonials-slider.arrow-bottom .testimonial .author-position,
.testimonials-slider.no-nav .testimonial .author-position {
  font-size: 13px;
  color: #999999;
  font-style: italic;
}

.testimonials-slider.arrow-bottom .slick-arrow {
  left: 15px;
  bottom: -50px;
  top: auto;
  -webkit-transform: none;
  transform: none;
  width: 34px;
  height: 34px;
  line-height: 35px;
  color: #fff;
  border-radius: 50%;
}
.testimonials-slider.arrow-bottom .slick-arrow i {
  display: none;
}
.testimonials-slider.arrow-bottom .slick-arrow::before {
  font-family: "Font Awesome 5 Pro";
}

.testimonials-slider.arrow-bottom .slick-prev {
  background: #ff5a5f;
}
.testimonials-slider.arrow-bottom .slick-prev::before {
  content: "\f177";
}

.testimonials-slider.arrow-bottom .slick-next {
  left: 55px;
  background: #dedcd9;
}
.testimonials-slider.arrow-bottom .slick-next::before {
  content: "\f178";
}

.testimonials-slider.no-nav .testimonial {
  padding: 27px 0;
}
.testimonials-slider.no-nav .testimonial .content-wrapper {
  position: relative;
  margin-bottom: 25px;
}
.testimonials-slider.no-nav .testimonial .content-wrapper .quote-left,
.testimonials-slider.no-nav .testimonial .content-wrapper .quote-right {
  position: absolute;
  font-size: 30px;
  color: #cccccc;
  opacity: 0.3;
}
@media (max-width: 1199.98px) {
  .testimonials-slider.no-nav .testimonial .content-wrapper .quote-left,
  .testimonials-slider.no-nav .testimonial .content-wrapper .quote-right {
    font-size: 16px;
  }
}
.testimonials-slider.no-nav .testimonial .content-wrapper .quote-left {
  content: "\f10d";
  top: -15px;
  left: 0;
}
.testimonials-slider.no-nav .testimonial .content-wrapper .quote-right {
  content: "\f10e";
  bottom: -15px;
  right: 0;
}
.testimonials-slider.no-nav .testimonial .content {
  display: block;
  max-width: 630px;
  margin: 0 auto;
}

.testimonials-slider.no-nav .author-rate {
  margin-bottom: 5px;
}
.testimonials-slider.no-nav .author-rate .rate-item.checked .icon {
  stroke: #fed66c;
  fill: #fed66c;
}

.testimonials-slider.arrow-top .author-image {
  max-width: 90px;
  max-height: 90px;
  width: 90px;
  height: 90px;
  min-height: 90px;
  min-width: 90px;
  margin-bottom: -45px;
  margin-left: 30px;
  position: relative;
  z-index: 2;
}

.testimonials-slider.arrow-top .card-body {
  padding: 0 35px 45px 40px;
}

.testimonials-slider.arrow-top .testimonial-icon {
  padding-top: 33px;
}
.testimonials-slider.arrow-top .testimonial-icon .icon {
  width: 60px;
  height: 48px;
  stroke: #ff5a5f;
  fill: #ff5a5f;
}

.testimonials-slider.arrow-top .position {
  line-height: 27px;
}

.testimonials-slider.arrow-top .slick-arrows {
  top: -60px;
}

.testimonial-navs .testimonial {
  padding: 35px 40px 48px 40px;
}

.testimonial-navs .card-title {
  line-height: 30px;
}

@media screen and (min-width: 768px) {
  .testimonial-navs .view-more {
    line-height: 8px;
  }
}

.testimonial-navs .slider-nav {
  margin-top: 30px;
}
.testimonial-navs .slider-nav .slick-slide:focus {
  outline: none;
}
.testimonial-navs .slider-nav .box .media {
  padding: 20px 0;
  opacity: 0.3;
}
.testimonial-navs .slider-nav .slick-current .media {
  opacity: 1;
}
.testimonial-navs .slider-nav .author-image {
  max-width: 54px;
  max-height: 54px;
  width: 54px;
  height: 54px;
  min-height: 54px;
  min-width: 54px;
}
.testimonial-navs .slider-nav .media-body .name {
  line-height: 1.25;
}

.store.card {
  display: block;
}

.store .store-image {
  z-index: 1;
}
.store .store-image:hover .content-right {
  opacity: 1;
}

.store .image-content {
  bottom: 0;
  width: 100%;
  padding: 10px 15px;
}

.store .icon {
  fill: #0099cc;
}

.store .content-left .badge {
  padding: 5px 8px;
}

.store .content-left .badge-primary {
  background: #ff5a5f;
}

.store .content-right {
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  width: 80px;
  margin-right: -15px;
}
.store .content-right.show-link {
  opacity: 1;
}
.store .content-right.w-lg {
  width: 106px !important;
}
.store .content-right .item {
  background: #000;
  color: #fff;
  width: 30px;
  height: 30px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.store .content-right .item:not(:last-child) {
  margin-right: 5px;
}
.store .content-right .icon {
  width: 14px;
  height: 14px;
  fill: #fff;
}
.store .content-right a:hover .icon {
  stroke: #0099cc;
  fill: #0099cc;
}
.store .content-right .marking i {
  font-size: 14px;
  color: #fff;
}
.store .content-right .marking:hover i {
  color: #0099cc;
}

.store .check .icon {
  fill: #74b100 !important;
}

.store .card-body .icon {
  width: 16px;
  height: 16px;
  stroke: #0099cc;
  fill: #0099cc;
  margin-right: 5px;
}

.store .store-meta .list-inline-item:not(:last-child) {
  margin-right: 15px;
}

.store .store-meta .separate {
  width: 1px;
  height: 25px;
  background: #dee2e6;
  font-size: 1rem;
}

.store.job-store {
  padding: 25px 28px;
  font-size: 0.875rem;
}
.store.job-store .store-image {
  width: 140px;
}
.store.job-store .favourite {
  font-size: 20px;
}

.store.map {
  max-width: 270px;
}
.store.map .card-body {
  font-family: "Work Sans", sans-serif !important;
}

.store .card-footer {
  margin-top: auto;
}

.store .address {
  font-size: 0.875rem;
}
.store .address:hover {
  color: #0099cc !important;
}

.store .badge {
  color: #fff;
}

.store .media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.store-grid-style .store-image {
  height: 100%;
}

.store-grid-style .slick-arrows {
  top: -60px !important;
}

.store-grid-style.slick-dotted .slick-dots {
  top: 108%;
}

.store-listing-style-01 .store-image {
  width: 300px;
}

.store-listing-style-02 .store-image {
  width: 245px;
}

.recent-view .heading,
.relate-listing .heading {
  margin-bottom: 38px;
}

.recent-view .store-image,
.relate-listing .store-image {
  -ms-flex: 0 0 120px;
  -webkit-box-flex: 0;
  flex: 0 0 120px;
  max-width: 120px;
}

.recent-view .media-body {
  margin-top: -4px;
}

@media (max-width: 991.98px) {
  .store-listing-style .store {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.store-listing-style .store-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 991.98px) {
  .store-listing-style .store-image {
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 991.98px) {
  .store-listing-style-01 .store-image,
  .store-listing-style-02 .store-image,
  .job-store .store-image {
    height: 0;
    padding-top: 75%;
  }
}

.store-listing-style-03 .store {
  padding: 25px 23px;
  height: 100%;
}

.store-listing-style-03 .store-image {
  width: 76px;
  height: 73px;
  max-width: 76px;
  max-height: 73px;
  min-width: 76px;
  min-height: 73px;
}

.store-listing-style-03 .store-top {
  margin-bottom: 7px;
}

.store-listing-style-03 .slick-arrows {
  top: -52px !important;
}

@media (max-width: 767.98px) {
  .store-listing-style-04 .store {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.store-listing-style-04 .store .store-image {
  margin-right: 23px;
  -ms-flex: 0 0 155px;
  -webkit-box-flex: 0;
  flex: 0 0 155px;
  max-width: 155px;
}

.store-listing-style-04 .store .media-body {
  -ms-flex: 0 0 350px;
  -webkit-box-flex: 0;
  flex: 0 0 350px;
  max-width: 350px;
  padding: 0;
}

@media (max-width: 767.98px) {
  .store-listing-style-04 .store {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .store-listing-style-04 .store .store-image {
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 100%;
  }
  .store-listing-style-04 .store .media-body {
    margin-top: 23px;
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.full-slide .slick-arrows {
  right: 274px;
}

.job-recent-view {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
@media (max-width: 767.98px) {
  .job-recent-view {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.job-recent-view .store-image {
  -ms-flex: 0 0 90px;
  -webkit-box-flex: 0;
  flex: 0 0 90px;
  max-width: 90px;
}

@media (max-width: 575.98px) {
  .relate-listing .store,
  .recent-view .store {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .relate-listing .store .store-image,
  .recent-view .store .store-image {
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.equal-height .store {
  height: 100%;
}

.equal-height .store-image {
  height: auto;
}

.nav-pills.font-size-lg .nav-link {
  font-size: 18px !important;
}

.nav-pills.fs-20 .nav-link {
  font-size: 20px !important;
}

.nav-pills.text-capitalize .nav-link {
  text-transform: capitalize !important;
}

.nav-pills.text-primary .nav-link.active {
  color: #ff5a5f !important;
}

.nav-pills.tab-style-01 {
  margin: 0 -20px;
}
.nav-pills.tab-style-01 .nav-item {
  padding: 0 20px;
}
.nav-pills.tab-style-01 .nav-link,
.nav-pills.tab-style-01 .card-header.collapsed {
  text-transform: uppercase;
  color: #999999;
  font-weight: 600;
  padding: 0;
  font-size: 16px;
}
.nav-pills.tab-style-01 .nav-link.active,
.nav-pills.tab-style-01 .nav-link :not(.collapsed),
.nav-pills.tab-style-01 .card-header.collapsed.active,
.nav-pills.tab-style-01 .card-header.collapsed :not(.collapsed) {
  color: #000;
  background: none;
}

.nav-pills.tab-style-02 .nav-item {
  padding: 0;
}
.nav-pills.tab-style-02 .nav-item:first-of-type .nav-link {
  border-top-left-radius: 3px;
}
.nav-pills.tab-style-02 .nav-item:last-of-type .nav-link {
  border-top-right-radius: 3px;
}

.nav-pills.tab-style-02 .nav-link {
  background-color: #333333;
  border-radius: 0;
  padding: 11px 0;
  text-align: center;
  color: #fff;
}
.nav-pills.tab-style-02 .nav-link.active {
  background: #000;
}

.nav-pills.tab-style-03 {
  margin-left: -28px;
  margin-right: -28px;
}
.nav-pills.tab-style-03 .nav-link {
  font-size: 30px;
  font-weight: 700;
  color: #ccc;
  padding: 0 28px;
  text-transform: capitalize;
  line-height: 1.2;
}
.nav-pills.tab-style-03 .nav-link.active {
  background-color: transparent;
  color: #000;
}

.nav-pills.tab-style-04 {
  margin-left: -25px;
  margin-right: -25px;
}
.nav-pills.tab-style-04 .nav-link {
  font-size: 24px;
  font-weight: 700;
  color: #ccc;
  padding: 0 25px;
  text-transform: uppercase;
  line-height: 1.2;
}
.nav-pills.tab-style-04 .nav-link.active {
  background-color: transparent;
  color: #000;
}

.nav-pills.tab-style-05 .nav-link {
  padding-left: 12px;
  padding-right: 12px;
  text-transform: uppercase;
  color: #999999;
  font-weight: 600;
  border-radius: 0;
  position: relative;
}
.nav-pills.tab-style-05 .nav-link.active {
  background: none;
  color: #ff5a5f;
}
.nav-pills.tab-style-05 .nav-link.active::after {
  position: absolute;
  content: "";
  background: #ff5a5f;
}

.nav-pills.tab-style-05.tab-horizontal .nav-link {
  padding-bottom: 20px;
}

.nav-pills.tab-style-05.tab-horizontal .nav-item:first-child .nav-link {
  padding-right: 12px;
  padding-left: 0;
}

.nav-pills.tab-style-05.tab-horizontal .nav-link.active::after {
  height: 2px;
  width: 100%;
  left: 0;
  bottom: -1px;
}

.nav-pills.tab-style-05.tab-vertical {
  border-right: 1px solid #dee2e6;
  height: 100%;
}
.nav-pills.tab-style-05.tab-vertical .nav-link.active::after {
  height: 100%;
  width: 2px;
  right: -1px;
  top: 0;
}
@media (max-width: 991.98px) {
  .nav-pills.tab-style-05.tab-vertical {
    border-right: none;
    padding-bottom: 30px;
  }
  .nav-pills.tab-style-05.tab-vertical .nav-link:first-child {
    padding-left: 0;
  }
  .nav-pills.tab-style-05.tab-vertical .nav-link.active::after {
    width: 100%;
    height: 2px;
    top: 100%;
    left: 0;
  }
}

.accordion .card {
  border-bottom: 1px solid #dee2e6 !important;
  border-radius: 0 !important;
}

.accordion .card-header {
  background: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.accordion .card-header .card-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 20px;
  width: 55px;
  text-align: center;
  min-height: 58px;
  background: #ff5a5f;
  color: #fff;
}
.accordion .card-header .card-title {
  padding-left: 18px;
  font-size: 18px;
  color: #ff5a5f;
  font-weight: 600;
  -ms-flex-item-align: center;
  align-self: center;
}
.accordion .card-header .dynamic-icon {
  position: relative;
  margin-left: auto;
  width: 55px;
  min-height: 58px;
  text-align: center;
  border-left: 1px solid #dee2e6;
}
.accordion .card-header .dynamic-icon::after {
  content: "\f068";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  right: 0;
  color: #ff5a5f;
}
.accordion .card-header.collapsed .card-icon {
  background: #f0f0f0;
  color: #999999;
}
.accordion .card-header.collapsed .card-title {
  color: #000;
}
.accordion .card-header.collapsed .dynamic-icon::after {
  content: "\f067";
  color: #000;
}

.accordion .card-body {
  padding: 25px 15px 25px 72px;
}

.job-by {
  position: relative;
}

.job-filter {
  background-color: transparent;
  position: absolute;
  top: -49px;
  z-index: 5;
  width: 100%;
}
@media (max-width: 991.98px) {
  .job-filter {
    top: -150px;
  }
}

.form-search-ajax {
  max-height: 248px;
  overflow: auto;
  width: 100%;
  padding: 0;
  border: none;
  z-index: 6;
  border-radius: 0;
  scrollbar-color: #666666 #cccccc;
  scrollbar-width: thin;
  margin: 0 !important;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

.client-review-card::-webkit-scrollbar {
  width: 7px;
}

.form-search-ajax::-webkit-scrollbar {
  width: 7px;
}
.form-search-ajax::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 7px #cccccc;
}
.form-search-ajax::-webkit-scrollbar-thumb {
  background-color: #666666;
  outline: 1px solid #666666;
}
.form-search-ajax .item {
  padding: 13px 25px;
}
.form-search-ajax .item:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.form-search-ajax .item .icon {
  width: 22px;
  height: 22px;
  margin-right: 20px;
}
.form-search-ajax .item:hover {
  background: #ff5a5f;
}
.form-search-ajax .item:hover a {
  color: #fff;
}
.form-search-ajax .item a:focus {
  outline: none;
}
.form-search-ajax.dropdown-menu {
  width: 100% !important;
  top: 100% !important;
  -webkit-transform: none !important;
  transform: none !important;
}

.form-search {
  position: relative;
  z-index: 9000;
}
.form-search .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-search .input-group {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  background: #fff;
  padding-right: 16px;
}
.form-search .input-group .input-group-append {
  color: #000;
  margin-left: 0;
}
.form-search .input-group .input-group-append i {
  font-size: 16px;
}
.form-search .input-group .icon {
  width: 24px;
  height: 24px;
}
.form-search .advance-search {
  display: block;
}
.form-search .advance-search i {
  margin-left: 10px;
}
.form-search .form-advance-search .price-range .price-range-wrapper {
  height: 52px;
}
.form-search .form-advance-search .price-range .amount {
  background: transparent;
  color: #fff;
}
.form-search
  .form-advance-search
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background: #fff;
  border: none;
}
.form-search .bottom-no-round {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.form-search.form-search-style-02 .select-custom::after {
  font-size: 20px;
}
.form-search.form-search-style-02 .input-group {
  position: static;
}
.form-search.form-search-style-02 .form-control {
  line-height: 30px;
  font-size: 16px;
  min-height: 30px;
  height: 30px;
}
.form-search.form-search-style-02 .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-search.form-search-style-02 .button {
  padding-left: 30px;
}
@media (max-width: 1199.98px) {
  .form-search.form-search-style-02 .button {
    padding-left: 0;
  }
}
.form-search.form-search-style-02 .btn {
  font-size: 20px;
  padding-top: 23.5px;
  padding-bottom: 23.5px;
}
@media (max-width: 1199.98px) {
  .form-search.form-search-style-02 .form-search-item {
    border: 0 !important;
    border-radius: 3px !important;
  }
}
.form-search.form-search-style-03 .input-group {
  border: 1px solid #dee2e6;
}
.form-search.form-search-style-03 .input-group-prepend {
  margin-bottom: 0;
  padding-left: 13px;
}
.form-search.form-search-style-03 .form-control {
  border: none;
  height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.form-search.form-search-style-03 .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}
.form-search.form-search-style-04 .form-search-items {
  border: 1px solid #dee2e6;
  height: 41px;
}
.form-search.form-search-style-04 .form-search-items label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
}
.form-search.form-search-style-04 .form-search-item {
  padding-left: 13px;
}
.form-search.form-search-style-04 .form-control {
  font-size: 14px;
}
.form-search.form-search-style-04 .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -2px;
}
.form-search.form-search-style-04 .btn i {
  font-size: 16px;
}

.search-popup {
  background: #f7f7f7;
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 68px 100px 50px;
  margin-top: 100px;
  margin-bottom: 100px;
}
@media screen and (max-width: 1200px) {
  .search-popup {
    width: auto;
    padding: 30px;
  }
}
.search-popup .form-search-item {
  height: 85px;
  border: 1px solid #dee2e6;
}
.search-popup .form-search-item .btn {
  height: auto;
}
.search-popup .form-search-item:first-child {
  border-right: 0 !important;
}
.search-popup .form-search-item i {
  font-weight: 400;
}
.search-popup .form-control {
  min-height: 30px;
  height: 30px;
}
.search-popup .form-search-ajax {
  width: calc(100% + 30px);
  left: -15px !important;
  margin-top: 13px !important;
}
.search-popup .button-close i {
  font-size: 30px;
}

.counter {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 170px;
  padding: 10px 0;
}
.counter:not(:last-child) {
  margin-right: 120px;
}
.counter .counterup {
  color: #020202;
}

.listing-table td {
  padding: 22px !important;
  vertical-align: middle;
}

.listing-table tr:first-child td {
  border-top: 0;
}

.listing-table tr:last-child td {
  border-bottom: 0;
}

.listing-table .name {
  line-height: 1.2;
}

.listing-table .image {
  width: 54px;
  min-width: 54px;
  max-width: 54px;
  max-height: 54px;
  min-height: 54px;
  height: 54px;
}

.listing-table-job td {
  text-align: left;
}

.listing-table-job .image {
  width: 57px;
}

.feature .card-body {
  padding: 23px 26px;
}

.company-feature .image {
  position: relative;
}

.company-feature .small-image {
  position: absolute;
  left: 50%;
  bottom: -50px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.company-feature a {
  line-height: 1.875;
}

.company-feature i {
  font-size: 14px;
  margin-left: 8px;
}

.company-feature .card-title {
  line-height: 1.3;
}

.listing-job-feature .slick-arrows {
  top: -55px !important;
}

.listing-job-feature .contact {
  color: #666666;
  margin-top: 30px;
}
.listing-job-feature .contact a {
  color: #0099cc;
}
.listing-job-feature .contact a:hover {
  text-decoration: underline;
}

.listing-thedir-cover {
  padding-top: 110px;
  padding-bottom: 105px;
}

.listing .item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
@media (max-width: 991.98px) {
  .listing .item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333%;
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}
@media (max-width: 767.98px) {
  .listing .item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.breadcrumb {
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
.breadcrumb .breadcrumb-item a {
  color: #000;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.breadcrumb .breadcrumb-item a:hover {
  color: #ff5a5f;
  text-decoration: none;
}
.breadcrumb .breadcrumb-item:last-child {
  color: #666666;
}
.breadcrumb.breadcrumb-style-01 .breadcrumb-item a {
  color: #000;
}
.breadcrumb.breadcrumb-style-01 .breadcrumb-item a:hover {
  color: #ff5a5f;
}
.breadcrumb.breadcrumb-style-01 .breadcrumb-item:last-child {
  color: #ff5a5f;
}
.breadcrumb.breadcrumb-style-02 .breadcrumb-item {
  color: #666666;
}
.breadcrumb.breadcrumb-style-02 .breadcrumb-item a {
  color: #ff5a5f;
}
.breadcrumb.breadcrumb-style-02 .breadcrumb-item a:hover {
  color: #ff8d90;
}
.breadcrumb.breadcrumb-style-03 .breadcrumb-item {
  font-weight: 500;
}
.breadcrumb.breadcrumb-style-03 .breadcrumb-item a {
  color: #000;
}
.breadcrumb.breadcrumb-style-03 .breadcrumb-item:first-child a {
  color: #ff5a5f;
}
.breadcrumb.breadcrumb-style-03 .breadcrumb-item:first-child a:hover {
  color: #ff8d90;
}
.breadcrumb.breadcrumb-style-03 .breadcrumb-item:last-child {
  color: #666666;
}

.pagination {
  margin-bottom: 0;
}
.pagination .page-link {
  border: none;
  color: #000;
  border-radius: 0 !important;
}
.pagination .page-link i {
  font-size: 1rem;
}
.pagination.pagination-style-01 .page-link {
  color: #000;
  font-size: 18px;
}
.pagination.pagination-style-01 .page-link.current {
  color: #ff5a5f;
}
.pagination.pagination-style-02 {
  border-radius: 0;
}
.pagination.pagination-style-02 .page-item {
  font-weight: 600;
}
.pagination.pagination-style-02 .page-item:not(:last-child) {
  margin-right: 3px;
}
.pagination.pagination-style-02 .page-item .page-link {
  padding: 0 12px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.pagination.pagination-style-02 .page-item .page-link.bg-gray {
  background: #f0f3f4 !important;
}
.pagination.pagination-style-02 .page-item .page-link.current {
  background: #ff5a5f !important;
  color: #fff;
}
.pagination.pagination-style-02 .page-item .page-link.bg-white {
  background: #fff;
}

.claim-request-popup {
  max-width: 630px;
  margin: auto;
}
.claim-request-popup .form-control::-webkit-input-placeholder {
  color: #666666;
}
.claim-request-popup .form-control::-moz-placeholder {
  color: #666666;
}
.claim-request-popup .form-control:-ms-input-placeholder {
  color: #666666;
}
.claim-request-popup .form-control::-ms-input-placeholder {
  color: #666666;
}
.claim-request-popup .form-control::placeholder {
  color: #666666;
}
.claim-request-popup textarea {
  height: 80px !important;
}

.form-login-register {
  max-width: 430px;
  margin: 0 auto;
  background: #fff;
  padding: 65px 30px 45px;
}
.form-login-register .tabs .nav-link h3 {
  color: #999999;
}
.form-login-register .tabs .nav-link.active h3 {
  color: #000;
}
.form-login-register .tabs .nav-item {
  padding: 0 28px;
}
.form-login-register .input-group {
  border: 1px solid #dee2e6;
}
.form-login-register .input-group .form-control {
  border: none;
}
.form-login-register .input-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}
.form-login-register .input-group .input-group-append {
  padding: 0 13px;
}
.form-login-register .custom-control {
  line-height: 1.7;
}
.form-login-register .social-icon ul {
  margin: 0 -5px;
}
.form-login-register .social-icon li {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
  height: 50px;
  margin: 0 !important;
  padding: 0 5px;
}
@media screen and (max-width: 430px) {
  .form-login-register .social-icon li {
    width: 70px;
  }
}
.form-login-register .social-icon a {
  width: 100%;
  height: 100%;
  line-height: 50px;
  border-radius: 2px;
}
.form-login-register .social-icon i {
  font-size: 18px;
}
.form-login-register .social-icon .icon {
  width: 18px;
  height: 18px;
}
.form-login-register .btn-register {
  margin-top: 65px;
}

.collapse-tabs .card-header {
  border: 1px solid #dee2e6;
}
.collapse-tabs .card-header:hover,
.collapse-tabs .card-header:focus,
.collapse-tabs .card-header:active {
  background: transparent !important;
}

.collapse-tabs .card {
  border: 0 !important;
}

@media (max-width: 575.98px) {
  .collapse-tabs .tab-pane {
    display: block;
    opacity: 1;
  }
  .collapse-tabs .card-body {
    border: 1px solid #dee2e6;
    border-top: 0;
  }
}

.collapse-tabs .card-header .btn {
  color: #999999;
}

.collapse-tabs .card-header .btn:not(.collapsed) {
  color: #000;
}

/*--------------------------------------------------------------
  ## Pages
  --------------------------------------------------------------*/
.home-main .feature-destination .separate {
  border-bottom: 1px solid #dee2e6;
}

.home-main .feature-destination .heading {
  padding-top: 85px;
}

.home-main .feature-destination .slick-arrow {
  top: calc(50% - 40px);
}
.home-main .feature-destination .slick-arrow i {
  font-size: 22px;
  font-weight: 400;
}

.home-main .feature-destination .slick-dots {
  top: 104%;
}

.home-main .our-directory .container-1720 {
  padding: 0 30px;
}

.home-main .our-directory .nav-link {
  font-size: 16px;
}

@media screen and (max-width: 1500px) {
  .home-main .our-directory .tab-content {
    max-width: 1170px;
    margin: 0 auto;
  }
}

@media (max-width: 767.98px) {
  .home-main .our-directory .slick-custom-nav {
    display: none !important;
  }
}

.home-main-intro {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  padding-top: 210px;
}
.home-main-intro::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home-main-intro .home-main-intro-container {
  position: relative;
  z-index: 3;
}
.home-main-intro .heading h1 {
  font-size: 90px;
  line-height: 0.93;
  letter-spacing: -0.05em;
}
@media (max-width: 767.98px) {
  .home-main-intro .heading h1 {
    font-size: 60px;
  }
}
.home-main-intro .dropdown-menu {
  z-index: 9999 !important;
}
.home-main-intro .listing-icon-box {
  padding-bottom: 55px;
}

.be-partner-main-intro {
  /* background-image: url("../../assets/image/other/banner.jpg"); */
  background-size: cover;
  background-color: rgb(234, 239, 243);
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  padding-top: 0px;
}
.be-partner-main-intro::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.be-partner-main-intro .be-partner-main-intro-container {
  position: relative;
  z-index: 3;
}
.be-partner-main-intro .heading h1 {
  font-size: 90px;
  line-height: 0.93;
  letter-spacing: -0.05em;
}
@media (max-width: 767.98px) {
  .be-partner-main-intro .heading h1 {
    font-size: 60px;
  }
}
.be-partner-main-intro .dropdown-menu {
  z-index: 9999 !important;
}
.be-partner-main-intro .listing-icon-box {
  padding-bottom: 55px;
}

.home-main-how-it-work {
  max-width: 1370px;
  margin: 0 auto;
}

.home-main-testimonial {
  background-image: url("../../assets/image/other/main-background-testimonion.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.blog-listing .nav-pills {
  margin-bottom: 60px;
}
@media (max-width: 767.98px) {
  .blog-listing .nav-pills {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
}
.blog-listing .nav-pills .nav-link {
  color: #000;
}
.blog-listing .nav-pills .nav-link.active {
  color: #ff5a5f;
}

.blog-listing .slick-list {
  height: auto !important;
}

.blog-listing-grid .page-title {
  background-image: url("../../assets/image/blog/image-5.jpg");
}

.blog-listing-grid .wrapper-content {
  padding-top: 65px !important;
}

.blog-with-sidebar .sidebar .sidebar-inner {
  padding-left: 70px;
}
@media (max-width: 1199.98px) {
  .blog-with-sidebar .sidebar .sidebar-inner {
    padding-left: 0;
  }
}

.blog-with-sidebar .sidebar .category .list-group-item i {
  font-size: 18px;
}

.blog-with-sidebar .sidebar .category .sub-categories {
  list-style: none;
  padding-top: 10px;
  padding-left: 13px;
}
.blog-with-sidebar .sidebar .category .sub-categories .sub-category a {
  color: #999999;
}
.blog-with-sidebar .sidebar .category .sub-categories .sub-category a:hover {
  color: #000;
}

.blog-with-sidebar .sidebar .twitter .link {
  color: #0099cc;
}

.blog-with-sidebar .sidebar .twitter .tags a {
  line-height: 1.7;
}

.blog-with-sidebar .sidebar .archives .archive {
  font-family: "Poppins", sans-serif;
}
.blog-with-sidebar .sidebar .archives .archive span {
  padding-right: 10px;
}
.blog-with-sidebar .sidebar .archives .archive span i {
  font-size: 18px;
}
.blog-with-sidebar .sidebar .archives .archive:not(:last-child) {
  margin-bottom: 15px;
}

.blog-with-sidebar .sidebar .search form {
  padding: 20px 0;
}
.blog-with-sidebar .sidebar .search form .form-control {
  height: 50px;
}

.blog-with-sidebar .sidebar .datepicker {
  width: 100%;
  border: 0;
}

.single-blog .single-blog-media.audio {
  padding-top: 110px;
  margin-bottom: 85px;
  height: 470px;
}
.single-blog .single-blog-media.audio iframe {
  width: 100%;
  height: 100%;
}

.single-blog .single-blog-media.gallery {
  margin-bottom: 110px;
}

.single-blog .single-blog-media.video {
  margin-bottom: 85px;
  height: 470px;
}
.single-blog .single-blog-media.video iframe {
  width: 100%;
  height: 100%;
}

.single-blog .icon {
  display: inline-block;
  stroke-width: 0;
}

.single-blog .author {
  margin-left: 3px;
}
.single-blog .author span {
  color: #000;
}

.single-blog blockquote p {
  margin-bottom: 0;
  padding-left: 100px;
  font-size: 24px;
  font-weight: 300;
  position: relative;
  line-height: 1.5;
}
.single-blog blockquote p::before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0;
  width: 4px;
  height: 85%;
  background: #ff5a5f;
}

.single-blog blockquote {
  max-width: 411px;
  margin-top: 60px;
  font-family: "Poppins", sans-serif;
  color: #000;
}
.single-blog blockquote p {
  font-size: 24px;
}

.single-blog .quote-author {
  display: block;
  padding-left: 100px;
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
  margin-bottom: 60px;
  margin-top: 30px;
}
.single-blog .quote-author .icon {
  width: 30px;
  height: 30px;
  stroke: #999999;
  fill: #999999;
  margin-right: 15px;
}

.single-blog .post-author {
  background: #f0f0f0;
  padding: 63px 70px 63px 70px;
  border-top: 1px solid #d4d4d4;
}
@media (max-width: 991.98px) {
  .single-blog .post-author {
    padding: 30px;
  }
}
.single-blog .post-author .image {
  -ms-flex: 0 0 130px;
  -webkit-box-flex: 0;
  flex: 0 0 130px;
}
@media (max-width: 767.98px) {
  .single-blog .post-author .image {
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    text-align: center;
  }
}

.single-blog .page-content .social-icon a {
  font-size: 16px;
  color: #cccccc;
}

.single-blog .single-blog-content {
  max-width: 770px;
  margin: 0 auto;
}

.single-blog .comments,
.single-blog .form-comment {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
}

.comments .media:not(:last-child) {
  margin-bottom: 11px;
}

.comments .media .image {
  width: 70px;
  height: 70px;
  max-width: 70px;
  max-height: 70px;
  min-width: 70px;
  min-height: 70px;
  margin-right: 30px;
}

.comments .media .action {
  font-size: 13px;
  font-style: italic;
  color: #666666;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  text-decoration: none;
}
.comments .media .action:hover {
  color: #0099cc;
}

.comments .sub-comment {
  margin-left: 100px;
  border-top: 1px solid #dee2e6;
}
.comments .sub-comment .image {
  width: 43px;
  height: 43px;
  max-width: 43px;
  max-height: 43px;
  min-width: 43px;
  min-height: 43px;
  margin-top: 20px;
}
@media (max-width: 991.98px) {
  .comments .sub-comment {
    margin-left: 50px;
  }
}

.form-comment .form-group {
  margin-bottom: 30px;
}
.form-comment .form-group .form-control {
  height: 48px;
  color: #666666;
  border-radius: 0;
}
.form-comment .form-group textarea:-moz-placeholder,
.form-comment .form-group select:-moz-placeholder,
.form-comment .form-group input[type]:-moz-placeholder {
  color: #666666;
}
.form-comment .form-group textarea::-moz-placeholder,
.form-comment .form-group select::-moz-placeholder,
.form-comment .form-group input[type]::-moz-placeholder {
  color: #666666;
}
.form-comment .form-group textarea:-ms-input-placeholder,
.form-comment .form-group select:-ms-input-placeholder,
.form-comment .form-group input[type]:-ms-input-placeholder {
  color: #666666;
}
.form-comment .form-group textarea::-webkit-input-placeholder,
.form-comment .form-group select::-webkit-input-placeholder,
.form-comment .form-group input[type]::-webkit-input-placeholder {
  color: #666666;
}

.form-comment textarea {
  height: 260px !important;
}

.form-comment .btn {
  font-size: 14px;
}

.single-blog-gallery .slick-slider {
  margin: 0;
}

.single-blog-gallery .slick-slide {
  padding: 0;
}

.single-blog-gallery .slick-slide img {
  width: 100%;
}

.single-blog-gallery .slick-dots {
  bottom: 48px;
  top: auto;
}
.single-blog-gallery .slick-dots li {
  padding: 0 18px;
  opacity: 1;
}
.single-blog-gallery .slick-dots span {
  display: block;
}
.single-blog-gallery .slick-dots span::before {
  width: 14px;
  height: 14px;
  background: transparent;
  border: 2px solid #fff;
}
.single-blog-gallery .slick-dots .slick-active span::before {
  background: #fff;
}

.single-blog-gallery .comments {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
}

.single-blog-image .single-blog-top,
.single-blog-image .single-blog-content {
  margin-right: auto;
  margin-left: auto;
}

.single-blog-image .single-blog-top {
  max-width: 630px;
  margin-bottom: 140px;
}

.single-blog-video .page-container {
  padding-top: 110px;
}

.explore-sidebar .page-title {
  height: 300px;
}

.explore-sidebar .sidebar {
  font-size: 0.875rem;
}

.explore-sidebar .pagination {
  margin-top: 75px;
  padding-bottom: 40px;
}

.explore-sidebar .map {
  position: relative;
}

.explore-sidebar .map-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.explore-filter {
  margin-bottom: 25px;
}
.explore-filter .form-group {
  width: 300px;
  margin-bottom: 0;
  margin-right: 45px;
}
.explore-filter .form-group .form-control {
  border: none;
  border-radius: 0;
}
.explore-filter .format-listing a {
  color: #ccc;
  font-size: 18px;
}
.explore-filter .format-listing a:not(:last-child) {
  margin-right: 10px;
}
.explore-filter .format-listing a.active {
  color: #000;
}

.widget-filter .form-group {
  margin-bottom: 10px;
}
.widget-filter .form-group .form-label {
  margin-bottom: 12px;
  color: #000;
}
.widget-filter .form-group .description {
  margin-top: 10px;
  color: #666666;
}
.widget-filter .form-group .description span {
  color: #000;
  font-weight: 600;
}
.widget-filter .form-group.category,
.widget-filter .form-group.price-range {
  margin-bottom: 15px;
}
.widget-filter .form-group.filter-tags {
  margin-top: 15px;
}
.widget-filter .form-group.filter-tags .form-label {
  margin-bottom: 15px;
}

.widget-filter .input-group .input-group-append {
  position: absolute;
  right: 13px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.widget-filter .form-control {
  color: #000;
  height: 40px;
  font-size: 0.875rem;
  padding-top: 5px;
  padding-bottom: 5px;
  background: transparent;
}

.widget-filter .form-check {
  margin-bottom: 5px;
  color: #666666;
}
.widget-filter .form-check .form-check-label {
  margin-left: 7px;
}

.widget-filter
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  top: -2px;
}

.explore-haft-map .explore-half-map {
  position: fixed !important;
  top: 0;
  width: calc(100% - 725px);
}
@media (max-width: 1199.98px) {
  .explore-haft-map .explore-half-map {
    position: relative !important;
    width: 100% !important;
    height: 400px !important;
  }
}

.explore-haft-map .wrapper-content {
  position: relative;
  background: #f0f3f4;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.explore-haft-map .page-container {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 725px;
  flex: 0 0 725px;
  max-width: 725px;
  background: #fff;
  padding: 19px 0 75px;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .explore-haft-map .page-container {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.explore-haft-map .map {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: 100%;
}
@media (max-width: 1199.98px) {
  .explore-haft-map .map {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
}

.explore-haft-map .widget-filter {
  margin-bottom: 15px;
  font-size: 0.875rem;
}
.explore-haft-map .widget-filter .category {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding-right: 17px;
}
@media (max-width: 991.98px) {
  .explore-haft-map .widget-filter .category {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
  }
}
.explore-haft-map .widget-filter .select-custom {
  background: #f5f5f5;
}
.explore-haft-map .widget-filter .select-custom .form-control {
  background: transparent !important;
}
.explore-haft-map .widget-filter .form-control,
.explore-haft-map .widget-filter .btn {
  background: #f5f5f5;
  color: #000;
  font-weight: 400;
  border-color: #bfbfbf;
}
.explore-haft-map .widget-filter .form-control {
  min-height: 33px;
  height: 33px !important;
}
.explore-haft-map .widget-filter .filter-item {
  -ms-flex: 0 0 153px;
  -webkit-box-flex: 0;
  flex: 0 0 153px;
  max-width: 153px;
  padding: 0 5px;
}
@media (max-width: 991.98px) {
  .explore-haft-map .widget-filter .filter-item {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.explore-haft-map .widget-filter .btn i {
  margin-right: 2px;
}
.explore-haft-map .widget-filter .btn:hover,
.explore-haft-map .widget-filter .btn:focus {
  background: #ff5a5f;
  color: #fff;
}
.explore-haft-map .widget-filter .price-range {
  padding-bottom: 25px;
}
.explore-haft-map .widget-filter .filter-tags {
  padding-bottom: 20px;
}
.explore-haft-map .widget-filter .filter-tags .dropdown-toggle {
  color: #000;
  margin-bottom: 18px;
  display: block;
}

.explore-haft-map-list .pagination {
  margin-top: 80px;
}

.explore-haft-map-list .store .store-image {
  width: 244px;
}

.explore-sidebar .small-map {
  border: 1px solid #cbcdce;
}

.explore-sidebar-grid .pagination {
  padding-bottom: 112px;
}

.explore-sidebar-list .pagination {
  padding-bottom: 0;
  margin-top: 51px;
}

.explore-sidebar-list .page-content {
  margin-bottom: 135px;
}

.explore-full-map-grid .pagination {
  margin-top: 45px;
  padding-bottom: 100px;
}

.explore-full-map-list .pagination {
  margin-top: 69px;
  padding-bottom: 0;
}

.explore-details .breadcrumb {
  padding: 0;
}

.explore-details .explore-details-top .store .icon {
  width: 16px;
  height: 30px;
}

.explore-details .explore-details-top .check {
  color: #99cc00;
}
.explore-details .explore-details-top .check .icon {
  margin-right: 5px;
}

.explore-details .explore-details-top .btn {
  height: 40px;
  display: inline-block;
  vertical-align: middle;
}
.explore-details .explore-details-top .btn:hover {
  background: #ff5a5f;
  color: #fff;
}
.explore-details .explore-details-top .btn:not(:last-child) {
  margin-right: 8px;
}

.explore-details .explore-details-top.bg-white .btn {
  border: 1px solid #dee2e6;
}

.explore-details .block-more-listing .box {
  margin-bottom: 0;
}

.explore-details .block-more-listing .slick-arrows {
  top: -36px !important;
  right: 16px;
}
.explore-details .block-more-listing .slick-arrows .slick-arrow {
  width: 21px;
  height: 21px;
  line-height: 20px;
  background: #ff5a5f;
  color: #fff;
  border-radius: 2px;
}
.explore-details .block-more-listing .slick-arrows .slick-arrow i {
  font-size: 11px;
}
.explore-details .block-more-listing .slick-arrows .slick-arrow:first-child {
  margin-right: 5px;
}
.explore-details .block-more-listing .slick-arrows .slick-disabled {
  background: #ebebeb;
  color: #000;
}

.explore-details .image-gallery {
  position: relative;
}
.explore-details .image-gallery .slick-list {
  height: 560px !important;
}
.explore-details .image-gallery .slick-slider {
  position: static;
}
.explore-details .image-gallery .slick-arrows {
  width: 100%;
}
.explore-details .image-gallery .slick-arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 51px;
  height: 51px;
  line-height: 51px;
  text-align: center;
}
.explore-details .image-gallery .slick-arrow i {
  font-size: 24px;
  font-weight: 300;
}
.explore-details .image-gallery .slick-prev {
  left: 100px !important;
  background: #fff;
  right: auto;
}
.explore-details .image-gallery .slick-next {
  right: 100px !important;
  background: #ff5a5f;
  color: #fff;
  left: auto;
}

.explore-details .page-wrapper {
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
}

.explore-details .form-reservation-lg .widget-container {
  padding-bottom: 0;
}

.explore-details .form-reservation-lg .datepicker-style-01 {
  border: 1px solid #dee2e6;
}

.explore-details .form-reservation-lg .datepicker--nav {
  background: #f0f2f3;
  border-bottom: 1px solid #dee2e6;
}

.explore-details .form-reservation-lg .datepicker--nav-title {
  text-transform: uppercase;
}

.explore-details
  .form-reservation-lg
  .datepicker--cell.-current-
  ~ .datepicker--cell {
  color: #000;
}

.explore-details .form-reservation-lg .table {
  margin-bottom: 58px;
}
.explore-details .form-reservation-lg .table td {
  padding: 0;
}

.explore-details .form-reservation-lg .form-check {
  padding-left: 0;
}
.explore-details .form-reservation-lg .form-check.none label {
  text-indent: -9999px;
}

.explore-details .form-reservation-lg input[type="radio"] {
  display: none;
}

.explore-details .form-reservation-lg input[type="radio"] + label {
  display: inline-block;
  padding: 12px 12px;
  width: 100%;
  color: #000;
  background: #fff;
}

.explore-details .form-reservation-lg input[type="radio"]:checked + label {
  background: #ff5a5f;
  color: #fff;
}

.reviews .author-image {
  width: 71px;
  height: 71px;
  max-width: 71px;
  max-height: 71px;
  min-width: 71px;
  min-height: 71px;
  margin-right: 30px;
}

.reviews .images img:not(:last-child) {
  margin-right: 8px;
}

.reviews .icons .item:not(:last-child) {
  margin-right: 25px;
}

.reviews .icons .item i {
  font-size: 16px;
}

.reviews .icons .like i {
  color: #0099cc;
}

.reviews .icons .love i {
  color: #ff5a5f;
}

@media (max-width: 767.98px) {
  .reviews .author-image {
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
    margin-right: 15px;
  }
}

.reviews .badge {
  padding: 3.5px 7px;
}

.block-form-review .rate-input {
  direction: rtl;
}
.block-form-review .rate-input input {
  display: none;
}
.block-form-review .rate-input label {
  margin-left: -2px;
  margin-right: -2px;
}
.block-form-review .rate-input label .icon {
  width: 24px;
  height: 24px;
  stroke-width: 0;
  stroke: #ccc;
  fill: #ccc;
}
.block-form-review .rate-input label:hover .icon,
.block-form-review .rate-input label:hover ~ label .icon {
  stroke: #74b100;
  fill: #74b100;
}
.block-form-review .rate-input input:checked ~ label .icon {
  stroke: #74b100;
  fill: #74b100;
}
.block-form-review .rate-input:hover > input:checked ~ label .icon {
  stroke: #74b100;
  fill: #74b100;
}

.block-form-review .upload-file [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

.block-form-review .upload-file [type="file"] + label {
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-weight: 600;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  vertical-align: middle;
}

.block-form-review .form-control {
  border-radius: 2px;
  min-height: 40px;
}
.block-form-review .form-control::-webkit-input-placeholder {
  color: #999999;
}
.block-form-review .form-control::-moz-placeholder {
  color: #999999;
}
.block-form-review .form-control:-ms-input-placeholder {
  color: #999999;
}
.block-form-review .form-control::-ms-input-placeholder {
  color: #999999;
}
.block-form-review .form-control::placeholder {
  color: #999999;
}

.block-form-review textarea {
  height: 148px !important;
}

.block-form-review.bg-gray-06 .form-control {
  border: none;
}

.explore-details-full-gallery .page-container {
  border-bottom: 1px solid #dee2e6;
  padding-top: 72px;
  padding-bottom: 84px;
}

.explore-details-full-gallery .image-gallery .slick-slide {
  padding: 0 2px;
}

.explore-details-full-gallery .block-form-review .form-control {
  border: none;
}

.explore-details-full-gallery .upload-file [type="file"] + label {
  border: none;
}

.explore-details-image .wrapper-content {
  padding-top: 90px;
}

.explore-details-image .page-container {
  padding-bottom: 85px;
}

.explore-details-image .reservation .form-control {
  background: #fff;
}

.explore-details-full-map .map-content-wrapper {
  bottom: 15px;
  right: 0;
  padding: 13px;
}
.explore-details-full-map .map-content-wrapper .icon {
  width: 24px;
  height: 24px;
  line-height: 1;
}
.explore-details-full-map .map-content-wrapper .list-inline-item {
  width: 47px;
  height: 47px;
  border: 1px solid #fff;
}
.explore-details-full-map
  .map-content-wrapper
  .list-inline-item:not(:last-child) {
  margin-right: 10px;
}
.explore-details-full-map .map-content-wrapper .list-inline-item:hover {
  background: #ff5a5f;
  border: none;
}
.explore-details-full-map .map-content-wrapper .list-inline-item .icon-car {
  margin-bottom: 8px;
}

.explore-details-full-map .page-container {
  border-bottom: 1px solid #dee2e6;
  padding-top: 72px;
  padding-bottom: 50px;
}

.explore-details-full-map .slider-for {
  margin: 0 !important;
}
.explore-details-full-map .slider-for .slick-slide {
  padding: 0;
}
.explore-details-full-map .slider-for .slick-slide img {
  width: 100%;
}
.explore-details-full-map .slider-for .slick-arrow {
  background: #000;
  color: #fff;
  width: 39px;
  height: 39px;
  line-height: 39px;
  font-size: 20px;
}
.explore-details-full-map .slider-for .slick-arrow i {
  font-weight: 300;
}
.explore-details-full-map .slider-for .slick-prev {
  left: 30px;
  right: auto;
}
.explore-details-full-map .slider-for .slick-next {
  right: 30px;
  left: auto;
}

.explore-details-full-map .slider-nav {
  margin: 0 -5px 0 !important;
}
.explore-details-full-map .slider-nav .slick-slide {
  padding: 0 5px;
}
.explore-details-full-map .slider-nav .slick-current .box img {
  border: 5px solid #ff5a5f;
}

.explore-details-full-image .page-wrapper {
  margin-top: -122px;
  position: relative;
  z-index: 100;
  margin-bottom: 115px;
}
@media (max-width: 1199.98px) {
  .explore-details-full-image .page-wrapper {
    margin-top: 0;
  }
}

.explore-details-full-image .image {
  height: 680px;
  background-image: url("../../assets/image/banner/image-1.jpg");
  background-size: cover;
  background-position: center;
}
@media (max-width: 1199.98px) {
  .explore-details-full-image .image {
    height: 340px;
  }
}

.explore-details-no-image .explore-details-top {
  margin-bottom: 63px;
}
.explore-details-no-image .explore-details-top .store .store-image {
  width: 120px;
  height: 120px;
  max-width: 120px;
  max-height: 120px;
  min-width: 120px;
  min-height: 120px;
}

.explore-details-gallery .page-wrapper {
  margin-top: 84px;
  margin-bottom: 98px;
}

.explore-details-gallery .image-gallery {
  max-height: 475px;
}
@media (max-width: 1199.98px) {
  .explore-details-gallery .image-gallery {
    max-height: 350px;
  }
}
@media (max-width: 991.98px) {
  .explore-details-gallery .image-gallery {
    max-height: 250px;
  }
}
@media (max-width: 767.98px) {
  .explore-details-gallery .image-gallery {
    max-height: 200px;
  }
}
.explore-details-gallery .image-gallery .slick-dots {
  bottom: 32px;
  top: auto;
}
.explore-details-gallery .image-gallery .slick-dots li {
  opacity: 1;
}
.explore-details-gallery .image-gallery .slick-dots span::before {
  background: #fff;
}
.explore-details-gallery .image-gallery .slick-dots .slick-active span::before {
  background: #ff5a5f;
}

.explore-details-gallery .content-listing li {
  position: relative;
}
.explore-details-gallery .content-listing li::before {
  position: absolute;
  top: 10px;
  left: 0;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: #000;
}

.explore-details-gallery .slick-arrows {
  top: -53px !important;
}

.explore-details-gallery .relate-listing .store-image {
  -ms-flex: 0 0 90px;
  -webkit-box-flex: 0;
  flex: 0 0 90px;
  max-width: 90px;
}
@media screen and (max-width: 400px) {
  .explore-details-gallery .relate-listing .store-image {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.page-404 .page-container {
  padding: 250px 0 277px;
}
.page-404 .page-container .icon {
  width: 72px;
  height: 72px;
  fill: #ff5a5f;
}

.page-404 .form-search {
  max-width: 370px;
  margin: 0 auto;
}
.page-404 .form-search .form-control {
  min-height: 52px;
}
.page-404 .form-search .form-control:focus {
  background: transparent;
}
.page-404 .form-search .input-group-append {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  font-size: 20px;
}
.page-404 .form-search .input-group-append:hover,
.page-404 .form-search .input-group-append:focus,
.page-404 .form-search .input-group-append:active {
  border: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #000;
}

.page-about .banner {
}
.page-about .banner .heading {
  max-width: 830px;
  margin: 0 auto;
}

.page-about .banner-content {
  padding-top: 320px;
  padding-bottom: 288px;
}

.page-about .about-intro .jumbotron {
  max-width: 830px;
  margin: 0 auto 80px;
}

.page-about .counter {
  width: auto;
}

.coming-soon {
  background-image: url("../../assets/image/other/comming-soon.jpg");
  background-size: cover;
  background-position: center;
}
.coming-soon .main-header {
  padding-top: 82px;
}
.coming-soon .wrapper-content {
  max-width: 630px;
  margin: 0 auto;
}
.coming-soon .main-footer {
  padding-bottom: 247px;
}
.coming-soon .main-footer .social-icon li:not(:last-child) {
  margin-right: 33px;
}
.coming-soon .main-footer .social-icon a {
  color: #ccc;
  font-size: 16px;
}
.coming-soon .main-footer .social-icon a:hover {
  color: #000;
}

.common-elements .wrapper-content {
  padding-bottom: 190px;
}

.typography .section-divider {
  padding-bottom: 250px;
}

.typography .alert {
  font-family: "Poppins", sans-serif;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  border-color: transparent;
}
.typography .alert i {
  font-size: 24px;
}
.typography .alert .icon {
  width: 24px;
  height: 24px;
  stroke-width: 2px;
}
.typography .alert .alert-icon {
  padding: 25px 30px;
}
.typography .alert .message {
  padding: 25px 50px 25px 35px;
}
.typography .alert .alert-button {
  margin-left: auto;
  padding: 25px 30px;
}
.typography .alert.alert-outline {
  background: transparent;
}
.typography .alert.alert-info.alert-outline {
  border: 1px solid #3f93f3;
  color: #3f93f3;
}
.typography .alert.alert-info.alert-outline .message {
  border-left: 1px solid #3f93f3;
  padding-bottom: 27px;
  padding-top: 28px;
}
.typography .alert.alert-info.alert-outline .alert-button {
  color: #3f93f3;
}
@media (max-width: 991.98px) {
  .typography .alert.alert-info .message {
    border: 0;
  }
}
.typography .alert.alert-success {
  background: #f0f6e7;
  color: #67981a;
}
.typography .alert.alert-success .message {
  padding-top: 29px;
  padding-bottom: 29px;
}
.typography .alert.alert-warning {
  background: #fee5cc;
}
.typography .alert.alert-warning .alert-icon {
  background: #fd9827;
  padding-top: 28px;
  padding-bottom: 28px;
}
.typography .alert.alert-warning .alert-icon .icon {
  fill: #fff;
  stroke: #fff;
}
@media (max-width: 991.98px) {
  .typography .alert.alert-warning .alert-icon {
    background: none;
  }
  .typography .alert.alert-warning .alert-icon .icon {
    fill: #ffba00;
    stroke: #ffba00;
  }
}

.sidebar-menu .list-group-item {
  padding: 12px 0;
}

.sidebar-menu .label-icon {
  margin-right: 9px;
}

.sidebar-menu .menu-icon {
  font-size: 13px;
}

.pricing-table {
  padding: 30px 35px 50px;
}
.pricing-table .price {
  color: #ff5a5f;
  font-size: 40px;
  line-height: 1;
}
.pricing-table .card-body {
  border-top: 1px dashed #dee2e6;
}

@media (max-width: 991.98px) {
  .pricing-table {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    margin-bottom: 15px !important;
  }
}

.facts-box .icon-box .icon-box-icon {
  height: 36px;
}

.facts-box .icon-box i {
  font-size: 26px;
  font-weight: 300;
}

.facts-box .icon-box .icon {
  width: 36px;
  height: 36px;
}

.section-counter .value,
.section-counter .separate {
  font-size: 70px;
}
@media (max-width: 991.98px) {
  .section-counter .value,
  .section-counter .separate {
    font-size: 36px;
  }
}

.section-counter .separate {
  padding: 0 27px;
}
@media (max-width: 991.98px) {
  .section-counter .separate {
    padding: 0 15px;
  }
}

.progress-bars .progress {
  height: 3px;
}
.progress-bars .progress .progress-bar {
  background: #ff5a5f;
}

.progress-circle {
  font-family: "Poppins", sans-serif;
  width: 140px;
  height: 140px;
  background: none;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress-circle::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}
.progress-circle > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress-circle .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 4px;
  border-style: solid;
  position: relative;
  top: 0;
  border-color: #ff5a5f;
  overflow: visible;
}
.progress-circle .progress-bar.circle::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff5a5f;
  -webkit-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
}
.progress-circle .progress-left {
  left: 0;
}
.progress-circle .progress-left .circle::after {
  top: 125px;
  right: 65px;
}
.progress-circle .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress-circle .progress-right {
  right: 0;
}
.progress-circle .progress-right .circle::after {
  bottom: 125px;
  left: 65px;
}
.progress-circle .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.progress-circle .progress-value {
  position: absolute;
  top: 0;
  left: 0;
}

.dropcap {
  color: #333333;
  letter-spacing: 0;
}
.dropcap .first-charactor {
  font-weight: 600;
  float: left;
  line-height: 71px;
  margin-right: 42px;
}
.dropcap .first-charactor.no-background {
  font-size: 60px;
}
.dropcap .first-charactor.no-background.color-primary {
  color: #ff5a5f;
}
.dropcap .first-charactor.background {
  font-size: 30px;
  width: 52px;
  height: 52px;
  border-radius: 4px;
  text-align: center;
  line-height: 52px;
  margin-top: 7px;
}
.dropcap .first-charactor.background.color-primary {
  background: #ff5a5f;
  color: #fff;
}
.dropcap .first-charactor.background.color-gray {
  background: #f3f3f3;
  color: #999999;
}

.divider .content {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}

.divider.color-style {
  background: #f6f6f6;
}

.divider.shadow-style {
  position: relative;
  border-bottom: 1px solid #dee2e6;
}
.divider.shadow-style .content-wrapper {
  background: #fff;
  position: relative;
  z-index: 1;
}
.divider.shadow-style::after {
  content: "";
  position: absolute;
  -webkit-box-shadow: 0 20px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.08);
  bottom: 0;
  width: 70%;
  height: 30%;
  border-radius: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.common-elements .page-content-inner,
.typography .page-content-inner {
  padding-right: 65px;
  border-right: 1px solid #dee2e6;
  margin-right: 35px;
}
@media (max-width: 991.98px) {
  .common-elements .page-content-inner,
  .typography .page-content-inner {
    padding-right: 0;
    border-right: 0;
    margin-right: 0;
  }
}

.page-contact .heading {
  max-width: 811px;
  margin-right: auto;
  margin-left: auto;
}

.page-contact .form-contact {
  background: #f2f5f6;
}
.page-contact .form-contact .form-control {
  border-width: 0 0 1px 0;
  border-color: #c2c4c5;
}
.page-contact .form-contact .form-control::-webkit-input-placeholder {
  color: #000;
}
.page-contact .form-contact .form-control::-moz-placeholder {
  color: #000;
}
.page-contact .form-contact .form-control:-ms-input-placeholder {
  color: #000;
}
.page-contact .form-contact .form-control::-ms-input-placeholder {
  color: #000;
}
.page-contact .form-contact .form-control::placeholder {
  color: #000;
}
.page-contact .form-contact .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.page-contact .form-contact textarea {
  height: 136px;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  resize: none;
  /*remove the resize handle on the bottom right*/
}
.page-contact .form-contact .btn:hover,
.page-contact .form-contact .btn:focus,
.page-contact .form-contact .btn:active {
  background: none;
  color: #000;
}

.page-faqs .faqs {
  max-width: 830px;
  margin-left: auto;
  margin-right: auto;
}

.page-faqs .join-us {
  padding-bottom: 140px;
}
.page-faqs .join-us .btn {
  font-size: 16px;
  font-weight: 700;
}

.accordion-style-02 .card {
  background: #f6f6f6;
  border: 1px solid #cccccc !important;
  margin: 4px 0;
}

.accordion-style-02 .card-header {
  padding: 13px 22px;
  border-bottom: 0;
  margin-bottom: 0;
}
.accordion-style-02 .card-header .dynamic-icon {
  border-left: 0;
  font-size: 20px;
  font-weight: 200;
  min-height: auto;
}
.accordion-style-02 .card-header .dynamic-icon::after {
  color: #000;
  right: 0;
  left: auto;
}
.accordion-style-02 .card-header.collapsed {
  background: #fff;
}

.accordion-style-02 .card-body {
  padding: 5px 22px 20px;
  color: #262521;
}

.page-how-it-work .page-description {
  max-width: 630px;
  margin: 0 auto 80px;
}

.page-how-it-work .how-it-work {
  max-width: 830px;
  margin: 0 auto 90px;
}

.page-how-it-work .add-listing {
  max-width: 630px;
  margin: 0 auto;
}

.page-submit-listing .page-description {
  background: #f3f6f7;
}

.page-submit-listing .card label {
  line-height: 36px;
}

.page-submit-listing .card .form-control {
  color: #000;
}
.page-submit-listing .card .form-control:not(:last-child) {
  margin-bottom: 10px;
}
.page-submit-listing .card .form-control::-webkit-input-placeholder {
  color: #666666;
}
.page-submit-listing .card .form-control::-moz-placeholder {
  color: #666666;
}
.page-submit-listing .card .form-control:-ms-input-placeholder {
  color: #666666;
}
.page-submit-listing .card .form-control::-ms-input-placeholder {
  color: #666666;
}
.page-submit-listing .card .form-control::placeholder {
  color: #666666;
}
.page-submit-listing .card .form-control.color-gray {
  color: #666666;
}

.page-submit-listing .card .checkbox label {
  margin-bottom: 0;
}

.page-submit-listing .card .btn-add-new {
  padding: 0 12px;
}
.page-submit-listing .card .btn-add-new i {
  font-size: 20px;
  line-height: 43px;
}

.page-submit-listing .card .upload-file {
  border: 1px dashed #dee2e6;
  background: #f2f5f6;
  padding: 35px 20px 25px;
}
.page-submit-listing .card .upload-file .dz-message {
  margin: 0;
}
.page-submit-listing .card .upload-file .title {
  margin-bottom: 17px;
}
.page-submit-listing .card .upload-file .title span {
  line-height: 1;
}
.page-submit-listing .card .upload-file .title span:first-child {
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
}

.page-submit-listing .card textarea.form-control {
  height: 103px;
}

.page-submit-listing .card .form-time {
  margin-bottom: 12px;
}
.page-submit-listing .card .form-time .item {
  margin-bottom: 0;
  padding-top: 7px;
  padding-bottom: 7px;
}
.page-submit-listing .card .form-time .form-group {
  -ms-flex: 0 0 100px;
  -webkit-box-flex: 0;
  flex: 0 0 100px;
}
.page-submit-listing .card .form-time .day {
  -ms-flex: 0 0 121px;
  -webkit-box-flex: 0;
  flex: 0 0 121px;
}
.page-submit-listing .card .form-time select {
  letter-spacing: -0.025em;
}
.page-submit-listing .card .form-time .form-checkbox {
  padding: 0 9px;
}
.page-submit-listing .card .form-time .form-checkbox label {
  line-height: 27px;
}

.page-submit-listing .business-hours {
  font-size: 0.875rem;
}

.page-submit-listing .contact-section {
  max-width: 570px;
  margin: 0 auto;
}
.page-submit-listing .contact-section .custom-checkbox {
  margin-top: 10px;
}
.page-submit-listing .contact-section .custom-checkbox label {
  margin-left: 5px;
}

.page-submit-listing .checkbox label {
  color: #666666;
}

.under-construction {
  background: #edf1f2 !important;
}

.panel .wrapper-content {
  background: #f0f0f0;
}

.panel .container-fluid,
.panel .container-sm,
.panel .container-md,
.panel .container-lg,
.panel .container-xl {
  padding-left: 30px;
  padding-right: 30px;
}

.panel .sidebar {
  -ms-flex: 0 0 305px;
  -webkit-box-flex: 0;
  flex: 0 0 305px;
  max-width: 305px;
  padding: 35px 0;
  background: #fff;
}

.panel .page-content-wrapper {
  height: 100%;
}

.panel .table {
  margin-bottom: 0;
}
.panel .table thead th {
  border-top: 0;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
}
.panel .table td,
.panel .table th {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0;
  color: #000;
}

.panel .user-profile .image {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
}

.panel .sidebar .submenu {
  padding-left: 33px;
}

.panel .sidebar li.active {
  background: transparent;
}
.panel .sidebar li.active a {
  color: #ff5a5f;
}

.panel .page-container {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  width: 100%;
  padding: 25px 0;
}

@media (max-width: 1199.98px) {
  .panel .sidebar,
  .panel .page-container {
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.panel .page-content {
  background: #fff;
  padding: 18px 22px;
}

.panel .step-progress-section {
  width: 870px;
  margin: 0 auto 74px;
}
@media (max-width: 1199.98px) {
  .panel .step-progress-section {
    width: 100%;
  }
}

.panel .status {
  font-weight: 600;
}
.panel .status.paid,
.panel .status.active {
  color: #669900;
}
.panel .status.overdue,
.panel .status.experied {
  color: #ff6600;
}
.panel .status.pending {
  color: #0099cc;
}

.panel .current-status {
  position: relative;
  color: #000;
  margin-left: 20px;
  line-height: 27px;
}
.panel .current-status::before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  background: #74b100;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -18px;
  border-radius: 50%;
}

.panel .nav-pills {
  border-bottom: 1px solid #dee2e6;
  margin-right: 0;
  margin-left: 0;
  padding-bottom: 10px;
}
.panel .nav-pills .nav-item {
  padding-left: 0;
  padding-right: 45px;
}
.panel .nav-pills .nav-link {
  text-transform: capitalize;
  font-weight: 400;
}
.panel .nav-pills .nav-link.active {
  font-weight: 600;
}

.step-progress {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  counter-reset: step;
}
.step-progress li {
  width: 25%;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.step-progress li::before {
  position: absolute;
  width: 58px;
  height: 58px;
  content: counter(step);
  counter-increment: step;
  line-height: 58px;
  display: block;
  text-align: center;
  border-radius: 50%;
  background-color: white;
  z-index: 2;
  font-size: 24px;
  font-weight: 600;
  color: #cccccc;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0;
}
.step-progress li:not(:last-child)::after {
  width: 100%;
  height: 7px;
  content: "";
  position: absolute;
  background-color: #cccccc;
  top: 29px;
  left: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
}
.step-progress li.active::before {
  border: 4px solid #ff5a5f;
  color: #000;
  line-height: 50px;
}
.step-progress li.complete::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  background: #ff5a5f;
  color: #fff;
  font-weight: 300;
}
.step-progress li.complete::after {
  background: #ff5a5f;
}

.panel-ad-campaigns-start-new .section-content {
  max-width: 1140px;
  margin: 0 auto;
  padding: 167px 30px 185px;
}
@media (max-width: 1199.98px) {
  .panel-ad-campaigns-start-new .section-content {
    padding: 50px 0;
  }
}
.panel-ad-campaigns-start-new .section-content .image {
  background-image: url("../../assets/image/other/campaigin-start-new-bubble.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100%;
}
@media (max-width: 767.98px) {
  .panel-ad-campaigns-start-new .section-content .image {
    padding-top: 75%;
  }
}

@media screen and (max-width: 1600px) {
  .dashboards .features .card {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media screen and (max-width: 1400px) {
  .dashboards .features .card {
    -ms-flex: 0 0 33.333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media (max-width: 991.98px) {
  .dashboards .features .card {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 767.98px) {
  .dashboards .features .card {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.dashboards .features .card .published {
  color: #669900;
}

.dashboards .features .card .experied {
  color: #0099cc;
}

.dashboards .features .card .active {
  color: #ff9900;
}

@media screen and (max-width: 1700px) {
  .dashboards .two-column {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.dashboards .page-left {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
@media screen and (max-width: 1700px) {
  .dashboards .page-left {
    margin-bottom: 30px;
  }
}

.dashboards .page-right {
  -ms-flex: 0 0 20%;
  -webkit-box-flex: 0;
  flex: 0 0 20%;
  max-width: 20%;
}

@media screen and (max-width: 1700px) {
  .dashboards .page-left,
  .dashboards .page-right {
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.dashboards .alert-success {
  background: #d6efbc;
  color: #336600;
  font-family: "Work Sans", sans-serif;
}
.dashboards .alert-success .alert-button {
  color: #336600;
}

.dashboards .facts-box .card {
  padding: 37px 50px;
  color: #fff;
}

.dashboards .facts-box .fact-icon {
  font-size: 60px;
  padding-left: 30px;
  opacity: 0.5;
}

.dashboards .facts-box .view {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(20%, #d10000),
    to(#ff6a52)
  );
  background: linear-gradient(to right, #d10000 20%, #ff6a52);
}

.dashboards .facts-box .review {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(20%, #ffae00),
    to(#ffdf4c)
  );
  background: linear-gradient(to right, #ffae00 20%, #ffdf4c);
}

.dashboards .facts-box .time-bookmark {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(30%, #0db282),
    to(#01cdc1)
  );
  background: linear-gradient(to right, #0db282 30%, #01cdc1);
}

.dashboards .table tr:first-child td,
.dashboards .table thead td {
  border-top: 0;
}

.dashboards .table td {
  padding-top: 7px;
  padding-bottom: 7px;
}

.dashboards .reccent-activities .table td {
  color: #666666;
}

.dashboards .contact .contact-icon .icon {
  width: 48px;
  height: 48px;
}

.page-invoice-details .page-content {
  padding: 48px 100px;
  margin-bottom: 156px;
}
@media (max-width: 991.98px) {
  .page-invoice-details .page-content {
    padding: 30px;
  }
}

.page-invoice-details .table td {
  border-bottom: 1px solid #dee2e6 !important;
}

.page-invoice-details .table td:last-child {
  text-align: right;
}

.page-invoice-details .contact-info a {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
@media (max-width: 767.98px) {
  .page-invoice-details .contact-info a {
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.page-invoice-details .contact-info .separate {
  padding: 0 28px;
}
@media (max-width: 767.98px) {
  .page-invoice-details .contact-info .separate {
    display: none;
  }
}

.page-invoice-listing .page-container {
  height: 100vh;
}
@media (max-width: 1199.98px) {
  .page-invoice-listing .page-container {
    height: auto;
  }
}

@media (max-width: 1199.98px) {
  .page-invoice-listing .table td {
    width: auto;
  }
}

.page-favourite .page-content,
.page-my-listing .page-content {
  padding-right: 0;
  padding-left: 0;
}

.page-favourite .store-listing-item,
.page-my-listing .store-listing-item {
  padding-left: 22px;
  padding-right: 22px;
}
.page-favourite .store-listing-item .action,
.page-my-listing .store-listing-item .action {
  display: none;
}
.page-favourite .store-listing-item .action .btn:not(:last-child),
.page-my-listing .store-listing-item .action .btn:not(:last-child) {
  margin-right: 10px;
}
.page-favourite .store-listing-item .action .btn-light,
.page-my-listing .store-listing-item .action .btn-light {
  color: #000;
  background: #ccc;
}
.page-favourite .store-listing-item:hover,
.page-my-listing .store-listing-item:hover {
  background: #f6f6f6;
}
.page-favourite .store-listing-item:hover .action,
.page-my-listing .store-listing-item:hover .action {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
}
.page-favourite .store-listing-item .status,
.page-my-listing .store-listing-item .status {
  padding-left: 0;
}

.page-favourite .page-container {
  height: 100vh;
}
@media (max-width: 1199.98px) {
  .page-favourite .page-container {
    height: auto;
  }
}

.page-my-listing .tabs {
  padding-left: 22px;
  padding-right: 22px;
}

.page-my-listing .pagination {
  margin-top: 35px;
  padding-bottom: 10px;
  padding-left: 22px;
}

.page-my-listing .btn-light {
  padding-right: 35px;
  padding-left: 35px;
}

.my-profile .profile .image {
  width: 130px;
  height: 130px;
  min-width: 130px;
  min-height: 130px;
  max-width: 130px;
  max-height: 130px;
  margin-right: 38px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.page-reviews .review-item {
  padding: 13px 0;
}
.page-reviews .review-item .icon {
  width: 18px;
  height: 18px;
}
.page-reviews .review-item .rate-item {
  margin: 0 -1.5px;
}
.page-reviews .review-item .rate-item.checked .icon {
  fill: #339900;
  stroke: #339900;
}

.page-shop .page-content-inner {
  padding-right: 85px;
}
@media (max-width: 1199.98px) {
  .page-shop .page-content-inner {
    padding-right: 0;
  }
}

.page-shop .table {
  margin-bottom: 0;
}
.page-shop .table thead th {
  border-top: 0;
}

.page-shop .price span {
  font-weight: 400;
}

.page-shop .price .old-price {
  color: #000;
  text-decoration: line-through;
  display: inline-block;
  margin-left: 3px;
}

.page-shop .price .current-price {
  color: #000;
}

.shop-checkout .wrapper-content {
  padding-bottom: 160px !important;
}

.shop-checkout .alert {
  background: #f6f6f6;
  margin-bottom: 58px;
}

.shop-checkout .page-container .form-control {
  border-radius: 0;
}

.shop-checkout textarea {
  height: 142px;
}

.shop-checkout .arrows::after,
.shop-checkout .arrows:before {
  right: 19px;
}

.shop-checkout .arrows::before {
  top: 8px;
}

.shop-checkout .arrows::after {
  bottom: 8px;
}

.shop-checkout .card {
  background: #f6f6f6;
  padding: 35px;
}

.sidebar-checkout .custom-radio {
  line-height: 1.7;
}
.sidebar-checkout .custom-radio label::before {
  border-color: #000;
  top: 0.25em;
  background-color: transparent;
}

.shop-listing .wrapper-content {
  padding-bottom: 140px !important;
}

.shop-listing .page-title {
  background-image: url("../../assets/image/shop/larger-shop.jpg");
}

.shop-listing select {
  background-position: calc(100% - 19px) calc(23px),
    calc(100% - 14px) calc(23px), 100% 0;
}

.shop-listing .filter .form-group {
  -ms-flex: 0 0 270px;
  -webkit-box-flex: 0;
  flex: 0 0 270px;
  max-width: 270px;
  margin-bottom: 0;
  margin-top: -14px;
}
@media (max-width: 767.98px) {
  .shop-listing .filter .form-group {
    margin-top: 0;
  }
}
.shop-listing .filter .form-group .select-custom::after {
  right: 0;
}
.shop-listing .filter .form-group select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.shop-sidebar .form-control {
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
  color: #999999;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.shop-sidebar .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.shop-sidebar .ui-slider-horizontal {
  height: 1px;
  margin-left: 0;
}

.shop-sidebar .ui-widget-content {
  background: #000;
}
.shop-sidebar .ui-widget-content .ui-widget-header {
  background: #000;
}

.shop-sidebar .ui-slider .ui-slider-handle {
  width: 13px;
  height: 13px;
  background-color: #000;
  border-radius: 50%;
  margin-left: 0;
}

.product .image-content {
  right: 13px;
  top: 0;
}

.product .badge {
  padding-left: 8.5px;
  padding-right: 8.5px;
}
.product .badge.badge-success {
  background: #83b900;
}

.product .author-rate {
  margin-bottom: 10px;
}
.product .author-rate .icon {
  width: 16px;
  height: 16px;
  margin: 0 -2px;
}
.product .author-rate .rate-item {
  margin: 0 2px 0 0;
}
.product .author-rate .checked .icon {
  fill: #f2b309;
  stroke: #f2b309;
}

.product .add-to-cart {
  top: -25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.product .product-meta-wrapper {
  height: 53px;
  overflow: hidden;
}

.product .product-meta {
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.product:hover .add-to-cart {
  top: 5px;
}

.product:hover .product-meta {
  top: 53px;
}

.my-account .tabs .nav-item {
  padding: 0 28px;
}

.my-account .table th {
  text-transform: uppercase;
  color: #000;
}

.my-account .table th,
.my-account .table td {
  padding-left: 24px;
  padding-right: 24px;
}

.my-account .table td {
  color: #999999;
  padding-top: 27px;
  padding-bottom: 27px;
}

.my-account .table .btn {
  border-color: #bfbfbf;
}

.my-account .status.pending {
  color: #0099cc;
}

.my-account .status.cancel {
  color: #cc0000;
}

.account-sidebar .card .info-item .label {
  -ms-flex: 0 0 35%;
  -webkit-box-flex: 0;
  flex: 0 0 35%;
  max-width: 35%;
}

.account-sidebar .card .info-item .value {
  -ms-flex: 0 0 65%;
  -webkit-box-flex: 0;
  flex: 0 0 65%;
  max-width: 65%;
}

.my-product .product-image {
  -ms-flex: 0 0 62px;
  -webkit-box-flex: 0;
  flex: 0 0 62px;
  height: 61px;
  max-width: 62px;
}

.my-product .price {
  color: #000;
}

@media (max-width: 991.98px) {
  .my-product {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .my-product .product-image {
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.shopping-cart .wrapper-content {
  padding-bottom: 170px !important;
  padding-top: 107px !important;
}

.shopping-cart .table tr:first-child td {
  border-top: 0;
}

.shopping-cart .table td {
  font-size: 16px;
  padding-top: 37px;
  padding-bottom: 40px;
  padding-left: 0;
  color: #000;
}

.shopping-cart .media .image {
  width: 100px;
}

.shopping-cart .quality {
  border: 1px solid #dee2e6;
  width: 128px;
  padding: 0 10px;
}
.shopping-cart .quality label {
  margin-bottom: 0;
  color: #999999;
}
.shopping-cart .quality input::-webkit-outer-spin-button,
.shopping-cart .quality input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.shopping-cart .quality .form-control {
  border: none;
  text-align: right;
  width: 40%;
  -moz-appearance: textfield;
}
.shopping-cart .quality .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

.shopping-cart .input-group .input-group-prepend {
  font-size: 18px;
  color: #000;
  margin-right: 5px;
  font-weight: 700;
}
.shopping-cart .input-group .input-group-prepend i {
  font-weight: 400;
}

.shopping-cart .input-group .form-control {
  border: none;
  font-size: 14px;
}
.shopping-cart .input-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

.shopping-cart .input-group .btn {
  font-size: 14px;
}

.cart-sidebar .card {
  background: #f6f6f6;
}

.cart-sidebar .custom-radio {
  color: #000;
}
.cart-sidebar .custom-radio label {
  color: #000;
  margin-bottom: 0;
  margin-left: 8px;
}
.cart-sidebar .custom-radio label::before {
  border-color: #000;
  left: -25px;
  width: 12px;
  height: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: transparent;
}
.cart-sidebar .custom-radio .custom-control-label::after {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  top: 11px;
  left: -21.5px;
}

.cart-sidebar .express .custom-control-label {
  color: #999999;
}
.cart-sidebar .express .custom-control-label::before {
  border-color: #999999;
}

.cart-sidebar .express .custom-control-input:checked ~ .custom-control-label {
  color: #000;
}
.cart-sidebar
  .express
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #000;
}

.cart-sidebar .btn {
  font-size: 18px;
  border-radius: 0;
}

.page-single-product .page-title {
  background: #f3f3f3;
}
.page-single-product .page-title .breadcrumb-item:last-child {
  color: #999999;
}

.page-single-product .product-images {
  padding-right: 100px;
}
@media (max-width: 1199.98px) {
  .page-single-product .product-images {
    padding-right: 0;
  }
}
@media (max-width: 991.98px) {
  .page-single-product .product-images {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-right: 0;
  }
}
.page-single-product .product-images .slider-nav {
  -ms-flex: 0 0 70px;
  -webkit-box-flex: 0;
  flex: 0 0 70px;
  max-width: 70px;
  margin: 0;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.page-single-product .product-images .slider-nav .slick-slide {
  padding: 10px 0;
}
.page-single-product .product-images .slider-nav .slick-current .box {
  border: 1px solid #999999;
}
@media (max-width: 991.98px) {
  .page-single-product .product-images .slider-nav {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}
.page-single-product .product-images .slider-for {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.page-single-product .product-images .slider-for .image {
  position: relative;
}
.page-single-product .product-images .slider-for .image-icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 36px;
  font-weight: 300;
}
@media (max-width: 991.98px) {
  .page-single-product .product-images .slider-for {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

.page-single-product .product-details .form-add-cart .input-group {
  -ms-flex: 0 0 114px;
  -webkit-box-flex: 0;
  flex: 0 0 114px;
  border: 1px solid #dee2e6;
}
@media (max-width: 575.98px) {
  .page-single-product .product-details .form-add-cart .input-group {
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.page-single-product .product-details .form-add-cart .form-control:focus {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.page-single-product .product-details .form-add-cart .input-group-prepend,
.page-single-product .product-details .form-add-cart .input-group-append {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.page-single-product .product-details .form-add-cart .input-group-prepend {
  color: #cccccc;
  left: 16px;
}

.page-single-product .product-details .form-add-cart .input-group-append {
  color: #000;
  right: 16px;
}

.page-single-product .product-details .form-add-cart .button-add-cart {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.page-single-product .product-details .social-icon li:not(:last-child) {
  margin-right: 26px;
}

.page-single-product .product-details .social-icon a {
  color: #ccc;
}

.page-single-product .product-details .social-icon a:hover {
  color: #000;
}

.page-single-product .relate-products .slick-arrow i {
  font-size: 30px;
  font-weight: 300;
}

.page-single-product .relate-products .slick-prev {
  left: -50px;
}

.page-single-product .relate-products .slick-next {
  right: -50px;
}

.document .doc-sidebar {
  scrollbar-color: #666666 #cccccc;
}
.document .doc-sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px #f5f5f5;
}
.document .doc-sidebar::-webkit-scrollbar-thumb {
  background-color: #999999;
  outline: 1px solid #999999;
}

.document .sidebar {
  font-size: 1rem;
}
.document .sidebar .sidebar-menu li.active a {
  color: #000;
  font-weight: 500;
}

.document .scroll-spy {
  top: 6rem;
  overflow-y: auto;
  font-size: 1rem;
}
.document .scroll-spy .list-group-item.active {
  color: #000;
  font-weight: 500;
}

.document .table th {
  font-weight: 500;
}

.document a:hover {
  text-decoration: none;
}

.callout {
  border-left-width: 10px;
  border-left-style: solid;
  padding: 1.5rem;
  margin-bottom: 1.25rem;
}

.callout-primary {
  border-color: rgba(255, 90, 95, 0.25);
  background-color: rgba(255, 90, 95, 0.05);
}

.callout-secondary {
  border-color: rgba(102, 102, 102, 0.25);
  background-color: rgba(102, 102, 102, 0.05);
}

.callout-success {
  border-color: rgba(103, 152, 26, 0.25);
  background-color: rgba(103, 152, 26, 0.05);
}

.callout-info {
  border-color: rgba(63, 147, 243, 0.25);
  background-color: rgba(63, 147, 243, 0.05);
}

.callout-warning {
  border-color: rgba(255, 186, 0, 0.25);
  background-color: rgba(255, 186, 0, 0.05);
}

.callout-danger {
  border-color: rgba(255, 0, 0, 0.25);
  background-color: rgba(255, 0, 0, 0.05);
}

.callout-light {
  border-color: rgba(240, 240, 240, 0.25);
  background-color: rgba(240, 240, 240, 0.05);
}

.callout-dark {
  border-color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.05);
}

.callout-white {
  border-color: rgba(255, 255, 255, 0.25);
  background-color: rgba(255, 255, 255, 0.05);
}

.callout-gray {
  border-color: rgba(153, 153, 153, 0.25);
  background-color: rgba(153, 153, 153, 0.05);
}

.callout-darker-light {
  border-color: rgba(204, 204, 204, 0.25);
  background-color: rgba(204, 204, 204, 0.05);
}

.callout-dark-red {
  border-color: rgba(204, 0, 0, 0.25);
  background-color: rgba(204, 0, 0, 0.05);
}

.callout-lighter-dark {
  border-color: rgba(38, 38, 38, 0.25);
  background-color: rgba(38, 38, 38, 0.05);
}

.callout-green {
  border-color: rgba(116, 177, 0, 0.25);
  background-color: rgba(116, 177, 0, 0.05);
}

.callout-gray-01 {
  border-color: rgba(232, 237, 239, 0.25);
  background-color: rgba(232, 237, 239, 0.05);
}

.callout-gray-02 {
  border-color: rgba(245, 245, 245, 0.25);
  background-color: rgba(245, 245, 245, 0.05);
}

.callout-gray-03 {
  border-color: rgba(238, 238, 238, 0.25);
  background-color: rgba(238, 238, 238, 0.05);
}

.callout-gray-04 {
  border-color: rgba(238, 241, 242, 0.25);
  background-color: rgba(238, 241, 242, 0.05);
}

.callout-gray-05 {
  border-color: rgba(236, 240, 241, 0.25);
  background-color: rgba(236, 240, 241, 0.05);
}

.callout-gray-06 {
  border-color: rgba(240, 242, 243, 0.25);
  background-color: rgba(240, 242, 243, 0.05);
}

code[class*="language-"] {
  -o-tab-size: 0;
  tab-size: 0;
  -moz-tab-size: 0;
  margin: 0;
}

pre[class*="language-"] {
  margin-bottom: 20px;
  margin-top: 0;
}

.bd-example .border,
.bd-example .border-top,
.bd-example .border-right,
.bd-example .border-bottom,
.bd-example .border-left {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  background: #fff;
}

.starter .doc-sidebar,
.document .doc-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: thin;
}
.starter .doc-sidebar::-webkit-scrollbar,
.document .doc-sidebar::-webkit-scrollbar {
  width: 4px;
}
@media (max-width: 767.98px) {
  .starter .doc-sidebar,
  .document .doc-sidebar {
    height: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
    position: static;
    overflow-y: unset;
  }
}

.starter .sidebar,
.document .sidebar {
  max-height: 100vh;
  width: 100%;
}
.starter .sidebar::-webkit-scrollbar-thumb,
.document .sidebar::-webkit-scrollbar-thumb {
  border-radius: 2px;
}
.starter .sidebar .navbar-collapse,
.document .sidebar .navbar-collapse {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  overflow-y: auto;
}

.starter .tab-pane .code-toolbar,
.document .tab-pane .code-toolbar {
  max-height: 700px;
  overflow: auto;
}

.starter .search-result,
.document .search-result {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background: #fff;
  width: 100%;
  z-index: 9999;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.starter .search-result .list-group-item:not(:last-child),
.document .search-result .list-group-item:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
}
.starter .search-result .list-group-item:hover,
.starter .search-result .list-group-item.active,
.document .search-result .list-group-item:hover,
.document .search-result .list-group-item.active {
  background: #e9ecef;
  color: #000 !important;
}
.starter .search-result.show,
.document .search-result.show {
  display: block;
}

.starter .doc-heading .anchorjs-link,
.document .doc-heading .anchorjs-link {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  text-decoration: none;
  font-size: 80%;
  visibility: hidden;
}

.starter .doc-heading:hover .anchorjs-link,
.document .doc-heading:hover .anchorjs-link {
  visibility: visible;
}

.starter {
  font-size: 1rem;
}
.starter .doc-sidebar {
  scrollbar-color: #666666 #cccccc;
}
.starter .doc-sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px #262626;
}
.starter .doc-sidebar::-webkit-scrollbar-thumb {
  background-color: #666666;
  outline: 1px solid #666666;
}
.starter .doc-sidebar .navbar-collapse .list-group-item {
  font-size: 1rem;
}
.starter .doc-sidebar .navbar-collapse .list-group-item.active a {
  color: #fff;
}
.starter .doc-sidebar .toogle::after {
  display: inline-block;
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  font-size: 10px;
  margin-left: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.starter .doc-sidebar .toogle.open::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.demo-header pre[class*="language-"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/*--------------------------------------------------------------
  ## Vendors
  --------------------------------------------------------------*/
[data-animate] {
  opacity: 0;
}

[data-animate].animated {
  opacity: 1;
}

@media (min-width: 992px) {
  .x-animated {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .x-fadeInUp {
    -webkit-animation-name: x-fadeInUp;
    animation-name: x-fadeInUp;
  }
}

@-webkit-keyframes x-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes x-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes x-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes x-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.slick-slider {
  /* margin-left: -15px;
    margin-right: -15px;  */
}
.slick-slider .box {
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  font-size: 1rem;
}

.slick-slide {
  height: inherit;
}

.slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.slick-vertical {
  height: auto;
}
.slick-vertical .slick-track {
  display: block;
}
.slick-vertical .slick-dots {
  right: 50px;
  left: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}
.slick-vertical .slick-dots li:not(:last-child) {
  margin-bottom: 30px;
}

.slick-slide-center {
  opacity: 0.3;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.slick-slide-center.slick-active {
  opacity: 1;
}

.equal-height.slick-initialized .slick-slide > div,
.equal-height.slick-initialized .box {
  height: 100%;
}

.slick-arrows {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.slick-arrow {
  text-align: center;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #000;
  cursor: pointer;
}
.slick-arrow:not(:last-child) {
  margin-right: 30px;
}
.slick-arrow.slick-disabled {
  cursor: no-drop;
  color: #ccc;
}
.slick-arrow.slick-prev {
  left: 0;
  right: auto;
}
.slick-arrow.slick-next {
  left: auto;
  right: 0;
}
.slick-arrow i {
  line-height: inherit;
}

.arrow-center .slick-arrow i {
  font-size: 36px;
  font-weight: 300;
}

.arrow-center .slick-arrow.slick-prev {
  left: -45px;
}

.arrow-center .slick-arrow.slick-next {
  right: -45px;
  left: auto;
}

.arrow-top .slick-arrows {
  position: absolute;
  top: 0;
  right: 15px;
}
.arrow-top .slick-arrows .slick-arrow {
  position: static;
}
.arrow-top .slick-arrows .slick-arrow i {
  font-size: 20px;
  font-weight: 400;
}
.arrow-top .slick-arrows .slick-next {
  left: auto;
}

.arrows-inner .slick-prev {
  left: 50px !important;
}

.arrows-inner .slick-next {
  right: 50px !important;
}

.slick-dots {
  position: absolute;
  width: 100%;
  left: 0;
  top: auto;
  bottom: 0;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
.slick-dots li {
  width: 12px;
  cursor: pointer;
  text-align: center;
  color: currentColor;
  opacity: 0.4;
  padding: 0 15px;
}
.slick-dots span {
  position: relative;
}
.slick-dots span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  line-height: 12px;
  width: 12px;
  height: 12px;
  background: #ccc;
  border-radius: 50%;
}

.slick-dots li.slick-active,
.slick-dots li:hover,
.slick-arrow:active,
.slick-dots li:active,
.slick-arrow:focus,
.slick-dots li:focus {
  opacity: 1;
}

.slick-dots li.slick-active span:before {
  background: #ff5a5f;
}

.slick-slider.hide-arrow .slick-arrows {
  display: none;
}

.slick-slider.slick-dot-style-01 .slick-dots li {
  opacity: 1;
  color: transparent;
}
.slick-slider.slick-dot-style-01 .slick-dots li span:before {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  background-color: #ff5a5f;
  border: 2px solid #fff;
}
.slick-slider.slick-dot-style-01 .slick-dots li.slick-active span:before {
  width: 11px;
  height: 11px;
  background-color: transparent;
  border: 2px solid #ffba00;
  color: transparent;
}

.slick-custom-nav .arrow {
  font-size: 20px;
  color: #000;
  cursor: pointer;
}
.slick-custom-nav .arrow i {
  font-weight: 400;
}
.slick-custom-nav .arrow:not(:last-child) {
  margin-right: 30px;
}
.slick-custom-nav .arrow.disabled {
  color: #cccccc;
  cursor: not-allowed;
}

@media screen and (max-width: 400px) {
  .slick-custom-nav {
    display: none !important;
  }
}

.dots-inner .slick-dots {
  bottom: 50px;
}

.mfp-bg {
  z-index: 999999 !important;
}

.mfp-popup {
  background-color: #fff;
  max-width: 1000px;
  margin: auto;
  padding: 20px;
}

button.mfp-close {
  font-family: "Font Awesome 5 Pro";
  font-size: 3rem;
  width: 64px;
  height: 64px;
  line-height: 64px;
}
button.mfp-close:hover,
button.mfp-close:focus,
button.mfp-close:active {
  background-color: transparent !important;
  border-color: transparent !important;
  opacity: 1;
}

button.mfp-arrow:hover,
button.mfp-arrow:focus,
button.mfp-arrow:active {
  background-color: transparent !important;
  border-color: transparent !important;
}

/**
  * Zoom effect
  * ----------------------------------------------------------------------------
  */
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.6;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
  * Newspaper effect
  * ----------------------------------------------------------------------------
  */
.mfp-newspaper {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-newspaper .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: scale(0) rotate(500deg);
  transform: scale(0) rotate(500deg);
}
.mfp-newspaper.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.mfp-newspaper.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
.mfp-newspaper.mfp-ready.mfp-bg {
  opacity: 0.95;
}
.mfp-newspaper.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(0) rotate(500deg);
  transform: scale(0) rotate(500deg);
  opacity: 0;
}
.mfp-newspaper.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
  * Move-horizontal effect
  * ----------------------------------------------------------------------------
  */
.mfp-move-horizontal {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-move-horizontal .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translateX(-50px);
  transform: translateX(-50px);
}
.mfp-move-horizontal.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.mfp-move-horizontal.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.mfp-move-horizontal.mfp-ready.mfp-bg {
  opacity: 0.95;
}
.mfp-move-horizontal.mfp-removing .mfp-with-anim {
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
  opacity: 0;
}
.mfp-move-horizontal.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
  * Move-from-top effect
  * ----------------------------------------------------------------------------
  */
.mfp-align-top .mfp-content {
  vertical-align: top;
}

.mfp-move-from-top {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-move-from-top .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
}
.mfp-move-from-top .mfp-close,
.mfp-move-from-top.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.mfp-move-from-top.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.mfp-move-from-top.mfp-ready.mfp-bg {
  opacity: 0.95;
}
.mfp-move-from-top.mfp-ready.gsf-menu-popup {
  opacity: 1;
}
.mfp-move-from-top.mfp-ready .mfp-close {
  opacity: 1;
  font-weight: 400;
}
.mfp-move-from-top.mfp-removing .mfp-with-anim {
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.mfp-move-from-top.mfp-removing .mfp-close,
.mfp-move-from-top.mfp-removing.mfp-bg {
  opacity: 0;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

/**
  * 3d unfold
  * ----------------------------------------------------------------------------
  */
.mfp-3d-unfold {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-3d-unfold .mfp-content {
  -webkit-perspective: 2000px;
  perspective: 2000px;
}
.mfp-3d-unfold .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-60deg);
  transform: rotateY(-60deg);
}
.mfp-3d-unfold.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.mfp-3d-unfold.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.mfp-3d-unfold.mfp-ready.mfp-bg {
  opacity: 0.95;
}
.mfp-3d-unfold.mfp-removing .mfp-with-anim {
  -webkit-transform: rotateY(60deg);
  transform: rotateY(60deg);
  opacity: 0;
}
.mfp-3d-unfold.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
  * Zoom-out effect
  * ----------------------------------------------------------------------------
  */
.mfp-zoom-out {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-out .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.mfp-zoom-out.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-zoom-out.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.mfp-zoom-out.mfp-ready.mfp-bg {
  opacity: 0.95;
}
.mfp-zoom-out.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  opacity: 0;
}
.mfp-zoom-out.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
  * Simple fade transition
  * ----------------------------------------------------------------------------
  */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.datepicker .datepicker--content {
  padding: 0;
}

.datepicker .datepicker--nav-title {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.datepicker .datepicker--day-name {
  font-size: 14px;
  color: #666666;
}

.datepicker .datepicker--cell {
  border-radius: 0;
  border-top: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  height: 34px;
  color: #999999;
}
.datepicker .datepicker--cell.-weekend- {
  border-left: 0;
}
.datepicker .datepicker--cell.-current- ~ .datepicker--cell {
  color: #000;
}

.datepicker-style-01 {
  border: none;
}
.datepicker-style-01 .datepicker--cells {
  background: #f0f2f3;
}
.datepicker-style-01 .datepicker--cell {
  background: #f0f2f3;
}
.datepicker-style-01 .datepicker--cell.-current- ~ .datepicker--cell {
  background: #fff;
  color: #000;
}
.datepicker-style-01 .datepicker--cell.-current-,
.datepicker-style-01 .datepicker--cell.-selected- {
  background: #ff5a5f !important;
  color: #fff !important;
}

.datepicker-style-02 {
  border: none;
}
.datepicker-style-02 .datepicker {
  background: #f7f7f7;
}
.datepicker-style-02 .datepicker--nav {
  background: #e1e1e1;
}
.datepicker-style-02 .datepicker--days-names {
  border-bottom: 1px solid #dee2e6;
  margin: 0;
}
.datepicker-style-02 .datepicker--day-name {
  font-size: 18px;
  color: #cccccc;
  height: 52px;
}
.datepicker-style-02 .datepicker--cell {
  font-size: 14px;
  border-radius: 0;
  height: 42px;
  font-weight: 600;
  color: #666666;
  border-top: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}
.datepicker-style-02 .datepicker--cell.-weekend- {
  border-left: 0 !important;
}
.datepicker-style-02 .datepicker--cell.-current-,
.datepicker-style-02 .datepicker--cell.-selected- {
  color: #ff5a5f !important;
  background: transparent;
}
.datepicker-style-02 .datepicker--cell.-current- ~ .datepicker--cell {
  color: #666666;
}
.datepicker-style-02 .datepicker--cell.-other-month- {
  color: #f7f7f7 !important;
  border-left: none;
}
.datepicker-style-02 .datepicker--cell.-other-month-[data-date="1"] {
  border-left: 1px solid #dee2e6;
}

.ui-datepicker {
  min-width: 300px;
  background: #f7f7f7 !important;
  padding: 0;
}
.ui-datepicker .ui-datepicker-header {
  background: #e1e1e1 !important;
}
.ui-datepicker thead th {
  color: #cccccc;
  text-transform: uppercase;
}
.ui-datepicker tbody td a {
  color: #666666 !important;
  font-size: 14px;
  font-weight: 600 !important;
  text-align: center;
}

.gm-style-iw {
  padding: 0 !important;
}
.gm-style-iw button {
  display: none !important;
}

.gm-style .gm-style-iw-d {
  overflow: visible !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 0;
}

.ui-slider-horizontal {
  height: 4px;
  margin-left: 8px;
}

.ui-widget.ui-widget-content {
  border: none;
}

.ui-widget-content {
  background: #cccccc;
}
.ui-widget-content .ui-widget-header {
  background: #ff5a5f;
}

.ui-slider .ui-slider-handle {
  width: 14px;
  height: 14px;
  background-color: #ff5a5f;
  border: none;
  border-radius: 50%;
}
.ui-slider .ui-slider-handle:focus {
  outline: none;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -6px;
}

.price-range .amount {
  background: transparent;
  border: none;
}

/*--------------------------------------------------------------
  
  /*--------------------------------------------------------------
  ## Elements
  --------------------------------------------------------------*/
.loader {
  height: 50vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.GGCloader {
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 7px solid rgb(21, 62, 128);
  border-bottom: 7px solid #fe7a7c;
  /* Dark Green */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spinloader 1s linear infinite;
  margin-bottom: 50px;
}
.GGCAdminloader {
  border: 5px solid #f3f3f3;
  /* Light grey */
  border-top: 5px solid rgb(21, 62, 128);
  border-bottom: 5px solid #fe7a7c;
  /* Dark Green */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spinloader 1s linear infinite;
  margin-top: 50px;
  margin-bottom: 50px;
}

@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.show_all_before_after {
  position: relative;
  width: 100%;
  margin: auto;
  padding: auto;
}

.show_all_before_after::before {
  content: "";
  /* border: 1px solid #F07579; */
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 50%;
  background-color: #f07579;
}
/* .show_all_before_after::after {
  content: "";
  border: 1px solid #F07579;
  width: 40%;
} */
.places-container-loading p{
  width: 100%;
  margin-top: 9px;
  border-radius: 4px;
  border: 1px solid lightgray;
  padding-left: 13px;
  padding-bottom: 5px;
  padding-top: 10px;
  color: #666666;
}
.places-container input{
  width: 100%;
  margin-top: 9px;
  border-radius: 4px;
  border: 1px solid lightgray;
  padding-left: 13px;
  padding-bottom: 9px;
  padding-top: 10px;
  color: #666666;
}
.places-container-error input{
  width: 100%;
  margin-top: 9px;
  border-radius: 4px;
  border: 1px solid red;
  padding-left: 13px;
  padding-bottom: 9px;
  padding-top: 10px;
  color: #666666;
}

.combobox-input::placeholder {
  font-size:14px;
  color: #999; /* Default placeholder color */
}

.combobox-input-error::placeholder {
  font-size:14px;
  color: red; /* Placeholder color when there is an error */
}

.borderRadius{
  border-radius: 10px !important;
}

.recharts-legend-wrapper{
display: none !important
}

.custom-date-picker .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: gray;
}

.custom-date-picker .MuiFormLabel-root.MuiInputLabel-root.Mui-error {
color:gray
}


.css-62mxlt-MuiFormControl-root-MuiTextField-root .MuiInputBase-input{
color: black;
    margin-left: 10px !important; 
}


/* customScrollbar.css */
.custom-scrollbar::-webkit-scrollbar {
  height: 10px; /* Set your desired height */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scroll thumb */
  border-radius: 10px; /* Rounded corners for the scroll thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scroll thumb on hover */
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #ffffff;
}

/* Optional: For Edge and Internet Explorer */
.custom-scrollbar {
  -ms-overflow-style: scrollbar;
}