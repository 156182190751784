@charset "UTF-8";
@keyframes x-fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
*,
::after,
::before {
  box-sizing: border-box;
}
body,
html {
  font-size: 16px;
}
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
  line-height: 1.15;
}
@-ms-viewport {
  width: device-width;
}
.main-header .header-customize-item:hover .shopping-cart-popup,
post,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lora";
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: normal;
  color: #636363;
  background-color: #fff;
  text-rendering: optimizeLegibility;
  text-align: left;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
address,
hr {
  margin-bottom: 1.5rem;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1.5rem;
  border: 0;
  border-top: 1px solid #ebebeb;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-weight: 700;
  line-height: 1.2;
  /* font-family: "Berkshire Swash"; */
  clear: both;
}
.h1,
h1 {
  font-size: 48px;
}
.h2,
h2 {
  font-size: 36px;
}
.h3,
h3 {
  font-size: 30px;
}
.h4,
h4 {
  font-size: 24px;
}
.h5,
h5 {
  font-size: 20px;
}
.h6,
h6 {
  font-size: 18px;
}
dl,
ol,
ol ol,
ol ul,
p,
ul,
ul ol,
ul ul {
  margin-top: 0;
  margin-bottom: 1.5rem;
}
abbr[data-original-title],
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  font-style: normal;
  line-height: inherit;
}
blockquote p,
li > ol,
li > ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1.5rem;
  border-left: solid 3px #ebebeb;
  padding: 30px 0 30px 40px;
}
blockquote p {
  font-weight: 600;
  font-size: 1rem;
  /* font-family: "Berkshire Swash"; */
  text-transform: uppercase;
  color: #000;
}
blockquote cite {
  display: block;
  /* font-family: "Berkshire Swash"; */
  font-size: 0.75rem;
  color: #a1a1a1;
  font-style: normal;
}
blockquote p + cite {
  margin-top: 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a,
a:hover {
  text-decoration: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  color: #764c24;
}
a:hover {
  color: #9d6530;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
.header-customize-item a,
a > code,
pre {
  color: inherit;
}
pre {
  font-size: 87.5%;
  margin-top: 0;
  margin-bottom: 1.5rem;
  padding: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
  background-color: rgba(0, 0, 0, 0.05);
}
figure {
  margin: 0 0 1.5rem;
}
img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  height: auto;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
}
table td,
table th {
  padding: 0.5rem;
  vertical-align: top;
  /* border: 1px solid #ebebeb; */
}
table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #ebebeb;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #a1a1a1;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button,
input,
optgroup {
  line-height: inherit;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
}
button,
input {
  overflow: visible;
}
select {
  text-transform: none;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
::-moz-selection {
  background-color: #764c24;
  color: #fff;
}
::selection {
  background-color: #764c24;
  color: #fff;
}
ins,
mark {
  background: #fff9c0;
  text-decoration: none;
  padding: 0 0.25rem;
}
big {
  font-size: 125%;
}
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="datetime"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea {
  color: #636363;
  background-color: transparent;
  max-width: 100%;
  border-radius: 0;

}
input[type="color"]:focus,
input[type="date"]:focus,
input[type="datetime-local"]:focus,
input[type="datetime"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="range"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="week"]:focus,
select:focus,
textarea:focus {
  outline: 0;
  border-color: #e0a45e;
}
input[type]:-moz-placeholder,
input[type]::-moz-placeholder,
select:-moz-placeholder,
select::-moz-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder {
  color: #b6b6b6;
}
input[type]:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #b6b6b6;
}
input[type]::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #b6b6b6;
}
textarea {
  height: auto;
  line-height: 1.6;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #e0a45e 50%),
    linear-gradient(135deg, #e0a45e 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(20px),
    calc(100% - 15px) calc(20px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}
#site-wrapper {
  background-color: #fff;
}
#wrapper-content {
  padding-top: 60px;
  padding-bottom: 60px;
}
.header-wrapper {
  padding: 0px 0;
  color: #000;
  font-family: "Lora";
  /* background: white !important; */
}
.header-light .header-wrapper {
  color: #fff;
}
.header-float {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.header-customize-item {
  position: relative;
  font-size: 20px;
  line-height: 20px;
}
.header-customize-item + .header-customize-item {
  margin-left: 1.5rem;
}
.header-customize-time {
  font-size: 14px;
}
.header-customize-cart {
  padding: 15px 0;
}
.header-customize-wishlist a > span,
.mini-cart-icon span {
  position: absolute;
  margin: auto;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 50%;
  background-color: #e0a45e;
  color: #fff;
  top: -13px;
  right: -13px;
}
.header-hidden {
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.main-header .navbar {
  padding: 0;
}
.main-header .navbar-brand img {
  max-height: 64px;
  -webkit-transition: max-height 0.5s;
  transition: max-height 0.5s;
}
.main-header .nav-link {
  font-size: 0.875rem;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5rem 0;
}
.main-header .nav-link span.caret {
  margin-left: auto;
}
.main-header .nav-item {
  position: relative;
}
.main-header .sub-menu {
  display: none;
  list-style: none;
  padding: 0;
}
.main-header .mobile-button-search,
.main-header .navbar-toggler {
  font-size: 24px;
  color: inherit;
}
.main-header .mini-cart-icon,
.sticky-area {
  position: relative;
}
.main-header .shopping-cart-popup {
  display: none;
  position: absolute;
  z-index: 999;
  top: 100%;
  right: 0;
  font-size: 1rem;
  width: 350px;
  -webkit-box-shadow: 0 14px 24px rgba(0, 0, 0, 0.15);
  box-shadow: 0 14px 24px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #777;
  padding: 20px;
}
.main-header .shopping-cart-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.main-header .mini-cart-item {
  position: relative;
  margin-bottom: 15px;
  border-bottom: 1px solid #ededed;
}
.main-header .mini-cart-item .remove {
  position: absolute;
  right: 0;
  top: 0;
}
.main-header .mini-cart-item a {
  font-size: 1rem;
  color: #222;
  margin-bottom: 5px;
  font-weight: 600;
  display: block;
}
.main-header .mini-cart-item img {
  width: 85px;
  float: left;
  margin: 0 15px 0 0;
  height: auto;
}
.main-header .mini-cart-item .quantity {
  display: block;
  font-size: 0.875em;
}
.main-header .mini-cart-item .amount {
  color: #222;
  font-weight: 600;
}
.sticky-area {
  width: 100%;
  z-index: 1099;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.sticky-area-wrap.sticky .sticky-area {
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
.header-light .sticky-area-wrap.sticky .sticky-area {
  background-color: #000;
  -webkit-box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .header-wrapper .container-90 {
    max-width: 95%;
  }
  .main-header .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .main-header .navbar-brand img {
    max-height: 110px;
  }
  .main-header .nav-link {
    color: inherit;
  }
  .main-header .nav-link span.caret {
    margin-left: 5px;
  }
  .main-header .navbar-expand-lg .navbar-nav .nav-link,
  .main-header .sub-menu .nav-item {
    padding: 0;
  }
  .main-header .nav-item {
    padding: 0px 0;
    -webkit-transition: padding 0.5s;
    transition: padding 0.5s;
  }
  .main-header .navbar-nav > .nav-item + .nav-item {
    margin-left: 35px;
  }
  .main-header .nav-item:hover > .sub-menu {
    display: block;
  }
  .main-header .sub-menu {
    margin: 0;
    min-width: 190px;
    position: absolute;
    left: 0;
    top: 100%;
    border-radius: 3px;
    z-index: 1099;
    -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #f7e8da;
    color: #4a221a;
    font-family: "Lora";
  }
  .main-header .sub-menu .nav-item .nav-link {
    display: flex;
    padding: 0.625rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    text-transform: none;
  }
  .main-header .sub-menu .nav-item .nav-link:hover {
    color: #e0a45e;
  }
  .main-header .sub-menu .sub-menu {
    top: 0;
    left: 100%;
  }
  .main-header .sub-menu span.caret {
    margin-left: auto;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .main-header .x-menu-mega {
    padding: 35px 25px 0;
    border-radius: 20px;
    right: 0;
    left: auto;
  }
  .main-header .x-menu-mega h4 {
    font-size: 14px;
    font-family: inherit;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  .main-header .x-menu-mega .nav-item {
    padding: 3px 0;
  }
  .main-header .x-menu-mega .nav-item .nav-link {
    padding: 0;
    font-size: 1rem;
  }
  .main-header .x-menu-mega.width-800 {
    min-width: 800px;
  }
  .main-header .x-menu-mega.width-900 {
    min-width: 900px;
  }
  .main-header .mega-menu-col {
    list-style: none;
    padding: 0;
    margin-bottom: 35px;
  }
  .sticky-area-wrap.sticky {
    height: 86px;
  }
  .sticky-area-wrap.sticky .navbar-nav > .nav-item {
    padding: 20px 0;
  }
  .sticky-area-wrap.sticky .navbar-brand img {
    max-height: 56px;
  }
}
@media screen and (max-width: 991px) {
  .main-header .nav-item {
    border-bottom: solid 1px #ebebeb;
  }
  .main-header .nav-link {
    padding: 12px 0;
    color: inherit;
  }
  .main-header .nav-link span.caret {
    width: 40px;
    line-height: 46px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
  }
  .main-header .navbar-collapse {
    padding-top: 10px;
  }
  .main-header .navbar-nav > .nav-item:first-child,
  .main-header .sub-menu {
    border-top: solid 1px #ebebeb;
  }
  .main-header .navbar-nav > .nav-item:last-child {
    border: 0;
  }
  .main-header .sub-menu.show {
    display: block;
  }
  .main-header .sub-menu .nav-link {
    padding-left: 20px;
    text-transform: none;
  }
  .main-header .sub-menu .sub-menu .nav-link {
    padding-left: 40px;
  }
  .main-header .sub-menu > li.nav-item:last-child {
    border-bottom: 0;
  }
  .main-header .x-menu-mega .container {
    padding: 0;
  }
  .main-header .x-menu-mega h4 {
    color: inherit;
    font-family: inherit;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.6;
    text-transform: uppercase;
    padding: 12px 20px;
    margin: 0;
    border-bottom: solid 1px #ebebeb;
  }
  .main-header .mega-menu-col {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .main-header.show-menu-dropdown {
    background-color: #fff;
  }
  .header-light.show-menu-dropdown {
    background-color: #000;
  }
  .header-light .navbar {
    color: #ccc;
  }
  .header-light .nav-item,
  .header-light .navbar-nav > .nav-item:first-child,
  .header-light .sub-menu,
  .header-light .x-menu-mega h4 {
    border-color: #373737;
  }
  .sticky-area {
    max-height: 500px;
    overflow: auto;
  }
  .sticky-area-wrap.sticky {
    height: 94px;
  }
}
.toggle-icon {
  cursor: pointer;
  display: inline-block;
  width: 32px;
  height: 30px;
  position: relative;
  vertical-align: middle;
}
.toggle-icon > span,
.toggle-icon > span:after,
.toggle-icon > span:before {
  height: 2px;
  margin: auto;
  position: absolute;
  background-color: currentColor;
}
.toggle-icon > span {
  bottom: 0;
  display: block;
  top: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  left: 2px;
  right: 8px;
  transition: all 0.3s;
}
.toggle-icon > span:after,
.toggle-icon > span:before {
  content: "";
  left: 0;
  right: -6px;
  -webkit-transition: top 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out,
    background 0.3s;
  -moz-transition: top 0.3s ease-in-out, -moz-transform 0.3s ease-in-out,
    background 0.3s;
  transition: top 0.3s ease-in-out, transform 0.3s ease-in-out, background 0.3s;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.toggle-icon > span:before {
  top: 8px;
}
.toggle-icon > span:after {
  top: -8px;
}
.toggle-icon:hover {
  color: #e4573d;
}
.toggle-icon.in > span {
  background-color: transparent !important;
}
.toggle-icon.in > span:after,
.toggle-icon.in > span:before {
  -webkit-transition: top 0.3s ease-in-out,
    -webkit-transform 0.3s 0.5s ease-in-out, background 0.3s;
  -moz-transition: top 0.3s ease-in-out, -moz-transform 0.3s 0.5s ease-in-out,
    background 0.3s;
  transition: top 0.3s ease-in-out, transform 0.3s 0.5s ease-in-out,
    background 0.3s;
  top: 0;
}
.toggle-icon.in > span:after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  -moz-transform: rotate3d(0, 0, 1, -45deg);
  -ms-transform: rotate3d(0, 0, 1, -45deg);
  -o-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}
.toggle-icon.in > span:before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  -moz-transform: rotate3d(0, 0, 1, 45deg);
  -ms-transform: rotate3d(0, 0, 1, 45deg);
  -o-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}
.btn,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  position: relative;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 3rem;
  padding: 0 1.875rem;
  font-weight: 600;
  text-transform: uppercase;
  border: solid 2px transparent;
  white-space: nowrap;
  color: #fff;
  background-color: #e0a45e;
  border-color: #e0a45e;
  border-radius: 5px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* .btn:focus,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus { */
  /* color: black; */
  /* color: #1976d2; */
  /* color: #fff; */
  /* background-color: #da923e; */
  /* border-color: rgb(2 52 102 / 50%); */
  /* border-color: #d88c33; */
/* } */

.btn:active,
.btn:hover,
button:active,
button:hover,
input[type="button"]:active,
input[type="button"]:hover,
input[type="reset"]:active,
input[type="reset"]:hover,
input[type="submit"]:active,
input[type="submit"]:hover {
  color: black;
  /* color: #1976d2; */
  /* color: #fff; */
  /* background-color: #da923e; */
  /* border-color: rgb(2 52 102 / 50%); */
  /* border-color: #d88c33; */
}
.btn.btn-link,
.btn.btn-outline,
button.btn-link,
button.btn-outline,
input[type="button"].btn-link,
input[type="button"].btn-outline,
input[type="reset"].btn-link,
input[type="reset"].btn-outline,
input[type="submit"].btn-link,
input[type="submit"].btn-outline {
  color: #e0a45e;
}
.btn.btn-primary.btn-outline:active,
.btn.btn-primary.btn-outline:focus,
.btn.btn-primary.btn-outline:hover,
.btn.btn-outline:active,
.btn.btn-outline:focus,
.btn.btn-outline:hover,
.btn.btn-primary.btn-outline:active,
.btn.btn-primary.btn-outline:focus,
.btn.btn-primary.btn-outline:hover,
button.btn-outline:active,
button.btn-outline:focus,
button.btn-outline:hover,
input[type="button"].btn-outline:active,
input[type="button"].btn-outline:focus,
input[type="button"].btn-outline:hover,
input[type="reset"].btn-outline:active,
input[type="reset"].btn-outline:focus,
input[type="reset"].btn-outline:hover,
input[type="submit"].btn-outline:active,
input[type="submit"].btn-outline:focus,
input[type="submit"].btn-outline:hover {
  background-color: #e0a45e;
  color: #fff;
  border-color: #e0a45e;
}
.btn:hover,
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  cursor: pointer;
}
.btn:focus,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn:disabled,
button:disabled,
input[type="button"]:disabled,
input[type="reset"]:disabled,
input[type="submit"]:disabled {
  opacity: 0.65;
  cursor: default;
}
.btn.btn-block,
.btn.btn-full {
  display: block;
  width: 100%;
}
.btn.btn-inline {
  display: inline-block;
  vertical-align: top;
}
.btn.btn-sm {
  font-size: 0.6875rem;
  height: 1.875rem;
  line-height: 1.75rem;
  padding: 0 1.25rem;
}
.btn.btn-md {
  font-size: 0.875rem;
  line-height: 3rem;
  padding: 0 1.875rem;
}
.btn.btn-lg {
  font-size: 1rem;
  /* height: 5.4375rem; */
  line-height: 3.3125rem;
  padding: 0 2.25rem;
}
.btn.btn-xl {
  font-size: 1.25rem;
  height: 4rem;
  line-height: 3.875rem;
  padding: 0 2.625rem;
}
.btn.btn-round {
  border-radius: 5em;
}
.btn.btn-rounded {
  border-radius: 0.25rem;
}
.btn.btn-square {
  border-radius: 0;
}
.btn.btn-primary,
.btn.btn-primary {
  color: #fff;
  background-color: #e0a45e;
  border-color: #e0a45e;
}
.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:hover {
  color: #fff;
  background-color: #da923e;
  border-color: #d88c33;
}
.btn.btn-primary.btn-link,
.btn.btn-primary.btn-outline {
  color: #e0a45e;
}
.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:hover {
  color: #fff;
  background-color: #da923e;
  border-color: #d88c33;
}
.btn.btn-primary.btn-link,
.btn.btn-primary.btn-outline {
  color: #e0a45e;
}
.btn.btn-secondary {
  color: #fff;
  background-color: #4a221a;
  border-color: #4a221a;
}
.btn.btn-secondary:active,
.btn.btn-secondary:focus,
.btn.btn-secondary:hover {
  color: #fff;
  background-color: #2e1510;
  border-color: #24110d;
}
.btn.btn-secondary.btn-link,
.btn.btn-secondary.btn-outline {
  color: #4a221a;
}
.btn.btn-secondary.btn-outline:active,
.btn.btn-secondary.btn-outline:focus,
.btn.btn-secondary.btn-outline:hover {
  background-color: #4a221a;
  color: #fff;
  border-color: #4a221a;
}
.btn.btn-light {
  color: #222;
  background-color: #fafafa;
  border-color: #fafafa;
}
.btn.btn-light:active,
.btn.btn-light:focus,
.btn.btn-light:hover {
  color: #222;
  background-color: #e7e7e7;
  border-color: #e1e1e1;
}
.btn.btn-light.btn-link,
.btn.btn-light.btn-outline {
  color: #fafafa;
}
.btn.btn-light.btn-outline:active,
.btn.btn-light.btn-outline:focus,
.btn.btn-light.btn-outline:hover {
  background-color: #fafafa;
  color: #222;
  border-color: #fafafa;
}
.btn.btn-dark {
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.btn.btn-dark:active,
.btn.btn-dark:focus,
.btn.btn-dark:hover {
  color: #fff;
  background-color: #0f0f0f;
  border-color: #090909;
}
.btn.btn-dark.btn-link,
.btn.btn-dark.btn-outline {
  color: #222;
}
.btn.btn-dark.btn-outline:active,
.btn.btn-dark.btn-outline:focus,
.btn.btn-dark.btn-outline:hover {
  background-color: #222;
  color: #fff;
  border-color: #222;
}
.btn.btn-gray {
  color: #fff;
  background-color: #a1a1a1;
  border-color: #a1a1a1;
}
.btn.btn-gray:active,
.btn.btn-gray:focus,
.btn.btn-gray:hover {
  color: #fff;
  background-color: #8e8e8e;
  border-color: #888;
}
.btn.btn-gray.btn-link,
.btn.btn-gray.btn-outline {
  color: #a1a1a1;
}
.btn.btn-gray.btn-outline:active,
.btn.btn-gray.btn-outline:focus,
.btn.btn-gray.btn-outline:hover {
  background-color: #a1a1a1;
  color: #fff;
  border-color: #a1a1a1;
}
.btn.btn-white {
  color: #222;
  background-color: #fff;
  border-color: #fff;
}
.btn.btn-white:active,
.btn.btn-white:focus,
.btn.btn-white:hover {
  color: #222;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn.btn-white.btn-link,
.btn.btn-white.btn-outline {
  color: #fff;
}
.btn.btn-white.btn-outline:active,
.btn.btn-white.btn-outline:focus,
.btn.btn-white.btn-outline:hover {
  background-color: #fff;
  color: #222;
  border-color: #fff;
}
.btn.btn-icon-left i {
  margin-right: 10px;
}
.btn.btn-icon-right i {
  margin-left: 10px;
}
.btn.btn.btn-outline {
  background-color: transparent;
}
.btn.btn-3d,
.btn.btn-3d:hover {
  border-bottom: solid 3px rgba(0, 0, 0, 0.15) !important;
}
.btn.btn-link {
  background-color: transparent !important;
  border-width: 0 !important;
  text-decoration: none;
  padding-left: 0;
  padding-right: 0;
  height: auto;
  line-height: 2;
  -webkit-box-shadow: inset 0 0 0 currentColor;
  box-shadow: inset 0 0 0 currentColor;
  border-radius: 0;
}
.btn.btn-link:active,
.btn.btn-link:focus,
.btn.btn-link:hover {
  -webkit-box-shadow: inset 0 -2px 0 currentColor;
  box-shadow: inset 0 -2px 0 currentColor;
}
.pb-8 {
  padding-top: 50px !important;
}
.pt-12 {
  padding-top: 100px !important;
}
.pb-8 {
  padding-bottom: 50px !important;
}
.pb-42 {
  padding-bottom: 42px !important;
}
.ml-35px {
  margin-left: 35px;
}
.py-8 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.pd-top-bottom-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.light-bg-color {
  background-color: #fafafa !important;
}
.link-color {
  color: #764c24;
}
.accent-color {
  color: #e0a45e;
}
.heading-color {
  color: #000;
}
.text-white {
  color: #fff !important;
}
.border-color {
  color: #ebebeb;
}
.custom-content-block {
  list-style: none;
  margin: 0;
  padding: 0;
}
.custom-content-block li {
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.875rem;
}
.has-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.border-separate {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.border-separate.border-color {
  border-bottom-color: #ebebeb;
}
.xs-text {
  font-size: 0.75rem !important;
}
.sm-text {
  font-size: 0.875rem !important;
}
.md-text {
  font-size: 1rem !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
@media screen and (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
}
@media screen and (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-70 {
    width: 70% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}
@media screen and (max-width: 575px) {
  .w-xs-auto {
    width: auto !important;
  }
  .w-xs-10 {
    width: 10% !important;
  }
  .w-xs-20 {
    width: 20% !important;
  }
  .w-xs-30 {
    width: 30% !important;
  }
  .w-xs-40 {
    width: 40% !important;
  }
  .w-xs-50 {
    width: 50% !important;
  }
  .w-xs-60 {
    width: 60% !important;
  }
  .w-xs-70 {
    width: 70% !important;
  }
  .w-xs-80 {
    width: 80% !important;
  }
  .w-xs-90 {
    width: 90% !important;
  }
  .w-xs-100 {
    width: 100% !important;
  }
}
[data-animate] {
  opacity: 0;
}
.slick-slide-center.slick-active,
[data-animate].animated {
  opacity: 1;
}
@media screen and (min-width: 992px) {
  .x-animated {
    -webkit-animation-duration: 500ms;
    -moz-animation-duration: 500ms;
    -o-animation-duration: 500ms;
    animation-duration: 500ms;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .x-fadeInUp {
    -webkit-animation-name: x-fadeInUp;
    -moz-animation-name: x-fadeInUp;
    -o-animation-name: x-fadeInUp;
    animation-name: x-fadeInUp;
  }
}
.slick-slider {
  /* margin-left: -15px;
  margin-right: -15px; */
}
.slick-slide {
  height: auto;
}
.slick-slide-center {
  opacity: 0.3;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.slick-slide img {
  width: 80px;
}
.slick-arrows,
.slick-dots > ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}
.slick-arrow {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
.slick-arrow.slick-disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.slick-arrow.slick-prev {
  left: auto;
  right: 100%;
}
.slick-arrow i {
  line-height: inherit;
}
.slick-dots {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
}
.slick-dots > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.slick-dots li {
  width: 30px;
  cursor: pointer;
  text-align: center;
  color: currentColor;
  opacity: 0.4;
}
.slick-dots span:before {
  font-family: "Font Awesome 5 Pro";
  content: "";
  font-weight: 700;
  line-height: 30px;
  width: 30px;
  height: 30px;
  font-size: 80%;
}
.slick-arrow:active,
.slick-arrow:focus,
.slick-dots li.slick-active,
.slick-dots li:active,
.slick-dots li:focus,
.slick-dots li:hover {
  opacity: 1;
}
.slick-dots li.slick-active span:before {
  content: "";
}
.slick-slider.hide-arrow .slick-arrows {
  display: none;
}
.slick-slider.slick-dot-style-01 .slick-dots li {
  opacity: 1;
  color: transparent;
}
.slick-slider.slick-dot-style-01 .slick-dots li span:before {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  background-color: #764c24;
  border: 2px solid #fff;
}
.slick-slider.slick-dot-style-01 .slick-dots li.slick-active span:before {
  width: 11px;
  height: 11px;
  background-color: transparent;
  border: 2px solid #4a221a;
  color: transparent;
}
.mfp-bg {
  z-index: 999999 !important;
}
.mfp-popup {
  background-color: #fff;
  max-width: 600px;
  margin: auto;
  padding: 20px;
}
button.mfp-close {
  font-family: "Font Awesome 5 Pro";
  font-size: 3rem;
  width: 64px;
  height: 64px;
  line-height: 64px;
}
button.mfp-close:active,
button.mfp-close:focus,
button.mfp-close:hover {
  opacity: 1;
}
button.mfp-arrow:active,
button.mfp-arrow:focus,
button.mfp-arrow:hover,
button.mfp-close:active,
button.mfp-close:focus,
button.mfp-close:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.98;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}
.mfp-newspaper .mfp-with-anim {
  -webkit-transform: scale(0) rotate(500deg);
  transform: scale(0) rotate(500deg);
}
.mfp-newspaper .mfp-with-anim,
.mfp-newspaper.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.mfp-newspaper.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
.mfp-newspaper.mfp-ready.mfp-bg {
  opacity: 0.95;
}
.mfp-newspaper.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(0) rotate(500deg);
  transform: scale(0) rotate(500deg);
  opacity: 0;
}
.mfp-newspaper.mfp-removing.mfp-bg {
  opacity: 0;
}
.mfp-move-horizontal .mfp-with-anim {
  -webkit-transform: translateX(-50px);
  transform: translateX(-50px);
}
.mfp-move-horizontal .mfp-with-anim,
.mfp-move-horizontal.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.mfp-move-horizontal.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.mfp-move-horizontal.mfp-ready.mfp-bg {
  opacity: 0.95;
}
.mfp-move-horizontal.mfp-removing .mfp-with-anim {
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
  opacity: 0;
}
.mfp-move-horizontal.mfp-removing.mfp-bg {
  opacity: 0;
}
.mfp-align-top .mfp-content {
  vertical-align: top;
}
.mfp-move-from-top .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
}
.mfp-move-from-top .mfp-close,
.mfp-move-from-top.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.mfp-move-from-top.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.mfp-move-from-top.mfp-ready.mfp-bg {
  opacity: 0.95;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content,
.mfp-move-from-top.mfp-ready.gsf-menu-popup {
  opacity: 1;
}
.mfp-move-from-top.mfp-ready .mfp-close {
  opacity: 1;
  font-weight: 400;
}
.mfp-move-from-top.mfp-removing .mfp-with-anim {
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.mfp-move-from-top.mfp-removing .mfp-close,
.mfp-move-from-top.mfp-removing.mfp-bg {
  opacity: 0;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.mfp-3d-unfold .mfp-content {
  -webkit-perspective: 2000px;
  perspective: 2000px;
}
.mfp-3d-unfold .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-60deg);
  transform: rotateY(-60deg);
}
.mfp-3d-unfold.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.mfp-3d-unfold.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.mfp-3d-unfold.mfp-ready.mfp-bg {
  opacity: 0.95;
}
.mfp-3d-unfold.mfp-removing .mfp-with-anim {
  -webkit-transform: rotateY(60deg);
  transform: rotateY(60deg);
  opacity: 0;
}
.mfp-3d-unfold.mfp-removing.mfp-bg {
  opacity: 0;
}
.mfp-zoom-out .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.mfp-zoom-out.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-zoom-out.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.mfp-zoom-out.mfp-ready.mfp-bg {
  opacity: 0.95;
}
.mfp-zoom-out.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  opacity: 0;
}
.mfp-zoom-out.mfp-removing.mfp-bg {
  opacity: 0;
}
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing,
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.order-filter-button:hover{
  color: white !important;
}